import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import rootReducer, { rootSaga } from './modules';
import logger from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import ReduxThunk from 'redux-thunk';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import './i18n'
//IE에서 사용할 수 있게 언어 기능 지원

//import './html/css/total-style_white.css';
import './html/css/modal.css';
import './html/css/popup.css';
//import './html/css/style-extends_white.css';

import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';


const customHistory = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware(); // 사가 미들웨어를 만듭니다.

const channelidx = new URLSearchParams(customHistory.location.search).get("channelidx");
/** 
  lazy + suspense는 돔 렌더링 하는 여기서만 가능..
  App.js 이하로 내려가면 무한 렌더링
 */
// const LightTheme = React.lazy(() => import('./Themes/LightTheme'));
// const DarkTheme = React.lazy(() => import('./Themes/DarkTheme'));

// //const theme_channel = process.env.REACT_APP_DARKTHEME_CHANNEL.split(",");
// const themeStyle = window.sessionStorage.getItem("theme");

// console.log('theme!!!',themeStyle,window.sessionStorage.getItem("theme"));

// // if ( isNull(channelidx) && isNotNull(window.sessionStorage.getItem ("theme"))) {
// //   theme = window.sessionStorage.getItem("theme");
// // } else {
// //   window.sessionStorage.setItem("theme",theme);
// // }

// const ThemeSelector = ({ children }) => {
//   return(
//     <>
//       <React.Suspense fallback={<></>}>
//         {themeStyle === process.env.REACT_APP_THEME_DARK?<DarkTheme/>:<LightTheme/>}
//       </React.Suspense>
//       {children}
//     </>
//   )
// }

var persistedState = {};
if (window.sessionStorage['usrtoken']) {
  //TODO 이거 필요한 코드인가? 이 코드 때문에 modLogin 에 새로운 객체 추가가 안된다. 일단 주석처리해봄.
  //persistedState = {modLogin:{logininfo:{loading:false,data:{map:{usrtoken:window.sessionStorage['usrtoken']}},error:null}}}
}

const store = createStore(
  rootReducer,
  persistedState,
  // logger 를 사용하는 경우, logger가 가장 마지막에 와야합니다.
  composeWithDevTools(
    applyMiddleware(
      ReduxThunk.withExtraArgument({ history: customHistory }),
      sagaMiddleware, // 사가 미들웨어를 적용하고
      /* redux log 를 보고 싶으면 아래의 주석을 해제하세요. */
      //logger
    )
  )
); // 여러개의 미들웨어를 적용 할 수 있습니다.

console.log('store',store.getState());

sagaMiddleware.run(rootSaga); // 루트 사가를 실행해줍니다.
// 주의: 스토어 생성이 된 다음에 위 코드를 실행해야합니다.


ReactDOM.render(
  <BrowserRouter history={customHistory}>
    <Provider store={store}>
      <App />
    {/**
      <ThemeSelector>
        <App />
      </ThemeSelector>
     */}
    </Provider>
  </BrowserRouter>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
