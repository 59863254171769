import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { isNotNull } from '../lib/wbUtils';
import { getParticipatedWebinars } from '../modules/login';

function ParticipatedWebinarsContainer(isMobile)  {

  const { data } = useSelector(state => ({data:state.modLogin.participated.data}))
  //const { participated } = useSelector(state => (state.modLogin.participated))
  //console.log('@@@participated',participated);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log('useEffect',data);

    if (isNotNull(data)) {
      if (data.result_cd===1) {
        //
      } else {
        toast.error(`${data.result_msg}`);
      }
    }
    dispatch(getParticipatedWebinars());
  },[dispatch])

  useEffect(() => {
    console.log('data',data);
  }, [data])

  return (
    <div className="login-wrap">
      <div className="webinars-box">
        <div className="webinar-title">참여한 웨비나로 이동하기 위한 임시페이지입니다.</div>
        <hr></hr>
        <ul className="webinar-list">
        {
          isNotNull(data) && isNotNull(data.map) && isNotNull(data.map.webinars) &&
          data.map.webinars.map((c, index) => {
            return (<li><Link to={'/webinars/live/'+c.webinaridx} >{c.webinarnm}</Link></li>)
          })}
        </ul>
      </div>
    </div>
  );
}

export default ParticipatedWebinarsContainer;
