import { Clear } from '@material-ui/icons';
import React from 'react';

function NoticePop({webinaridx, toggleShowNoticePopup, isMobile}) {
  const pcjsx = (
    <div className="modal" id="notice-modal">
      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <p className="modal-title">Notice</p>
            <Clear  className="modal-close material-icons cursorpointer" onClick={toggleShowNoticePopup}/>
          </div>
          <div className="modal-body">
            <div className="notice-modal-wrap">
              {/* <img src="/images/img_notice01.jpg" /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
  const mobilejsx = (
    <div className="modal" id="m-notice-modal">
      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <p className="modal-title">Notice</p>
            <Clear  className="modal-close material-icons cursorpointer" onClick={toggleShowNoticePopup}/>
          </div>
          <div className="modal-body">
            <div className="m-notice-modal-wrap">
              {/* <img src="/images/img_notice01.jpg" /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
  if (isMobile) return mobilejsx;
  else return pcjsx;
}

export default NoticePop