
function Pollitem({ pollitem, totvote, color, isMobile }) {

  const { item, votecnt, disporder } = pollitem;

  // 1명 으로 100% 를 차지할때 디자인 박스를 넘어가서 0.9 를 곱해주었습니다.
  // 0명일때도 작은 바를 보여주기 위해 0.5 %로 잡아주었습니다.
  const percentage = votecnt!==0?Math.ceil((votecnt/totvote) * 100 * 0.9 ):0.5;

  const pcjsx = (
    <>
      <p>{item}</p>
      <div className="poll-bar">
        <p className={color} style={{ width: `${percentage}%` }}
        > <span>{votecnt}명</span></p>
      </div>
    </>
  )
  const mobilejsx = (
    <>
      <p>{item}</p>
      <div className="m-poll-bar">
        <p className={color} style={{ width: `${percentage}%` }}
        > <span>{votecnt}명</span></p>
      </div>
    </>
  )
  if (isMobile) return mobilejsx;
  else return pcjsx;
}

export default Pollitem;