import { Bookmark, Favorite } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';

function TutorialPop({webinaridx, isMobile, likeIcon}) {

  const [btnText, setBtnText] = useState("Like");

  useEffect(() => {
    setBtnText((likeIcon === '020')?"Bookmark":"Like");
  });

  const pcjsx = (
    <div className="like-tutorial-wrap">
      {likeIcon === '020'?
      <div className="icon-like">
        <Bookmark className="material-icons"/>
        Bookmark
      </div>
      :
      <div className="icon-like">
        <Favorite className="material-icons"/>
        Like
      </div>
      }
      <div className="line">
      </div>
      <div className="copy">
        <p>While watching VOD,</p>
        <p>Please click the "{btnText}" button for good content!</p>
      </div>
    </div>
  )
  const mobilejsx = (
    <div className="m-like-tutorial-wrap">
      <div className="m-icon-like">
      <img src="/images/btn_like01.png" />
      </div>
      <div className="m-copy">
        <p>While watching VOD,</p>
        <p>Please click the "{btnText}" button for good content!</p>
      </div>
    </div>
  )
  if (isMobile) return mobilejsx;
  else return pcjsx;
}

export default TutorialPop