import React from 'react';
import BodyClassName from 'react-body-classname';
import { isMobile } from "react-device-detect";
import ParticipatedWebinarsContainer from '../containers/ParticipatedWebinarsContainer';
import FooterLogin from '../fragments/FooterLogin';

function LoginPage() {
  return (
    <BodyClassName className="login">
      <>
      <ParticipatedWebinarsContainer isMobile={isMobile}/>
      <FooterLogin/>
      </>
    </BodyClassName>    
  );
}

export default LoginPage;
