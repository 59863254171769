import { Clear, ErrorOutline } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import SurveyquesList from '../components/SurveyquesList';
import { isNotNull } from '../lib/wbUtils';
import { destroySession } from '../modules/login';
import { clearPostSurvey, getSurvey, postSurvey } from '../modules/survey';

function SurveyListPopContainer({ webinaridx, toggleShowSurveyPopup, isMobile, history, parenttp, vodidx, vodcnt, afterSurveyClose }) {

  const dispatch = useDispatch();
  const { data, loading, error, surveyansdone } = useSelector((state) => ({
      data:state.modSurvey.survey.data
      ,loading:state.modSurvey.survey.loading
      ,error:state.modSurvey.survey.error
      ,surveyansdone:state.modSurvey.surveyansdone
    })
  );

  const [bean, setBean] = useState(
    {
      webinaridx: webinaridx,
      surveyidx: '',
      surveyques: []
    }
  );

  const { register, handleSubmit, formState: { errors } } = useForm();

  const handleChange = (e) => {
    let surveyquesidx = e.target.getAttribute('surveyquesidx');
    let questp = e.target.getAttribute('questp');
    let quesArray = bean.surveyques.slice();
    let quesitem = e.target.value;

    const findindex = (value) => value.surveyquesidx === surveyquesidx;
    const index = quesArray.findIndex(findindex);

    let insertQuestp = questp === '030' ? '020' : '010';

      if (index === -1) {
        quesArray.push({
          surveyquesidx: surveyquesidx, quesitems: {
            quesitemidx: quesitem,
            answer: quesitem,
            questp: insertQuestp
          }
        })
        //console.log("i", quesArray);
      } else {
        quesArray.splice(index, 1, {
          surveyquesidx: surveyquesidx, quesitems: {
            quesitemidx: quesitem,
            answer: quesitem,
            questp: insertQuestp
          }
        });
        //console.log("m", quesArray);
      }

    setBean({ ...bean, surveyques: quesArray });
  }

  const handleRadioChange = (e) => {

    let surveyquesidx = e.target.getAttribute('surveyquesidx');
    let questp = e.target.getAttribute('questp');
    let quesArray = bean.surveyques.slice();
    let quesitem = e.target.value;
    
    let checked = e.target.checked;

    let findindex = (value) => value.quesitems.quesitemidx === quesitem;
    let index = quesArray.findIndex(findindex);

    let insertQuestp = questp === '030' ? '020' : '010';

    if (checked === true) {
      quesArray.push({
        surveyquesidx: surveyquesidx, quesitems: {
          quesitemidx: quesitem,
          answer: quesitem,
          questp: insertQuestp
        }
      })
      //console.log("i", quesArray);
    } else if (checked === false) {
      quesArray.splice(index, 1)
      //console.log("d", quesArray);
    }

    setBean({ ...bean, surveyques: quesArray });
  }

  const handleCreate = () => {
    console.log("bean>>", bean);
    dispatch(postSurvey(bean));
  }

  useEffect(() => {
    //console.log('@@useEffect',surveyansdone);
    if (data == null) {
      let idx = isNotNull(vodidx)?vodidx:webinaridx;
      dispatch(getSurvey(idx, parenttp));
    }

    if (data !== null && data !== undefined && data.result_cd === 1) {
      let surveyidx = data.map.surveyidx;
      setBean({ ...bean, surveyidx: surveyidx });
    }

    if(surveyansdone.data !== null && surveyansdone.data !== undefined && surveyansdone.loading === false && surveyansdone.error === null){
      if(surveyansdone.data.result_cd === 1){
        dispatch(clearPostSurvey());
        //TODO 스크립트로 오픈된 창만 닫을 수 있음... 추후 체크해야 함.
        toggleShowSurveyPopup(1);
        if (afterSurveyClose) {
          // 푸시(push)로 사후설문을 연 경우 종료하지 않음.
          if (isNotNull(vodidx) && isNotNull(vodcnt) && vodcnt>1) {
            setTimeout(()=>{history.push('/webinars/vodlist/' + webinaridx)},500);
          } else {
            try {
              dispatch(destroySession());
              if(window.opener){
                // 팝업으로 열린 창이면
                var ww = window.open(window.location, '_self'); ww.close();
              } else {
                // 팝업창이 아니면 로그인페이지로 이동
                // 로그아웃 처리.
                window.sessionStorage.clear();
                if (getDomainName(window.location.hostname) === `${process.env.REACT_APP_DOMAIN}`
                || getDomainName(window.location.hostname) === null) {
                  // 오리지날 운영 도메인이면 
                  //setTimeout(()=>{history.push('/login/' + webinaridx)},500);
                  history.push('/login/' + webinaridx);
                } else {
                  // 개별 사이트의 도메인일 경우
                  document.location.href='/';
                }
              }
            } catch (e) {
              alert(e)
            }         
          }
        }
      } else {
        // 에러인 경우
        toast.error(`Error : ${surveyansdone.data.result_msg}`);
      }
    }

  }, [dispatch, data, surveyansdone]);

  //console.log("test", data);
  const getDomainName = (hostName) => {
    return hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1);
  }

  let listArea;
  let totalItems = 0;
  // 로딩중
  if (loading && !data) listArea = <div>loading...</div>
  // 에러
  if (error) listArea = <div>errors!</div>
  // 데이타 불러왔지만 에러일때
  if (data !== null && data.result_cd === 0) listArea = <div>errors : {data.result_msg}</div>
  // 정상적으로 데이타 호출시
  if (data !== null && data.result_cd === 1) {
    totalItems = data.map.surveyques.length;
    if (totalItems === 0) {
      listArea = <div className="noData"><ErrorOutline className="material-icons" /> No Surveys.</div>

      try {
        if(window.opener){
          // 팝업으로 열린 창이면
          var ww = window.open(window.location, '_self'); ww.close();
        } else {
          // 팝업창이 아니면 로그인페이지로 이동
          // 로그아웃 처리.
          window.sessionStorage.clear();
          if (getDomainName(window.location.hostname) === `${process.env.REACT_APP_DOMAIN}`
          || getDomainName(window.location.hostname) === null) {
            // 오리지날 운영 도메인이면 
            //setTimeout(()=>{history.push('/login/' + webinaridx)},500);
            history.push('/login/' + webinaridx);
          } else {
            // 개별 사이트의 도메인일 경우
            document.location.href='/';
          }
        }
      } catch (e) {
        console.log(e)
      }


    } else {
      listArea = <SurveyquesList surveyques={data.map.surveyques} 
      handleRadioChange={handleRadioChange}
      handleChange={handleChange} register={register} errors={errors} />
    }
  }


  const pcjsx = (
    <div className="modal" id="survey-modal">
      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <p className="modal-title">Survey</p>
            <Clear className="modal-close material-icons cursorpointer" onClick={toggleShowSurveyPopup} />
          </div>
          <div className="modal-body">
            {listArea}
            <div className="btn-wrap type02">
              {totalItems > 0?
              <a href="#!" className="btn btn-submit" onClick={handleSubmit(handleCreate)}>Submit</a>
              :null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  const mobilejsx = (
    <div className="modal" id="m-survey-modal">
      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <p className="modal-title">Survey</p>
            <Clear className="modal-close material-icons cursorpointer" onClick={toggleShowSurveyPopup} />
          </div>
          <div className="modal-body">
            {listArea}
            <div className="m-btn-wrap m-type02">
              {totalItems > 0?
              <a href="#!" className="btn m-btn-submit" onClick={handleSubmit(handleCreate)}>Submit</a>
              :null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  if (isMobile) return mobilejsx;
  else return pcjsx;
}

export default SurveyListPopContainer