
function Faq({ faq, isMobile }) {

  return (
    <>
      <li>
        <a href="#!"><span>Q</span>{faq.ques}</a>
        <p>
          <span dangerouslySetInnerHTML={{__html: faq.answer}}></span></p>
      </li>
    </>
  )
}

export default Faq;