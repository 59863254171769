import React from 'react';
import LoginShortcutContainer from '../containers/LoginShortcutContainer';

/**
 * 숏컷을 통해 접근했을때 로그인 페이지로 이동시킴
 * @param {} param0 
 * @returns 
 */
function LoginShortcutPage({ match }) {

  const { shortcut } = match.params; // URL 파라미터 조회하기
  console.log('LoginShortcutPage.shortcut',shortcut);
  return (
    <>
      <LoginShortcutContainer 
        shortcut={shortcut} 
        />
      </>
  );
}

export default LoginShortcutPage;
