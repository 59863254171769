import axios from 'axios';

/**
 * 궁금해요 목록 조회
 * @param {*} param 
 */
export const getPresurveys = async (param) => {
  const response = await axios({
    method:'get',
    url:`${process.env.REACT_APP_API_SERVER}/front/presurveys/${param.webinaridx}`,
    headers: {
      Authorization: 'Bearer ' + window.sessionStorage.getItem('usrtoken')
    }
  });
  return response.data;
};

/**
 * 궁금해요 투표 등록
 * @param {*} param 
 */
export const putPresurvey = async (param) => {
  console.log('param@@',param);
  const response = await axios({
    method:'put',
    url:`${process.env.REACT_APP_API_SERVER}/front/presurveys`,
    // data 는 body 에, params 는 쿼리에..
    data: param,
    headers: {
      'Authorization': 'Bearer ' + window.sessionStorage.getItem('usrtoken')
    }
  });
  return response.data;

}