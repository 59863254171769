import axios from 'axios';

/**
 * 웨비나정보 조회
 * @param {*} param 
 */
 export const getWebinar = async (param) => {
  const response = await axios({
    method:'get',
    url:`${process.env.REACT_APP_API_SERVER}/front/ptcpnts/webinars/${param.webinaridx}`,
    // data 는 body 에, params 는 쿼리에..
    params: {'infotp':param.infotp},
  });
  return response.data;
};

/**
 * 로그인처리
 * @param {*} param 
 * @returns 
 */
export const putLogin = async (param) => {
  const response = await axios({
    method:'put',
    url:`${process.env.REACT_APP_API_SERVER}/front/ptcpnts/sign`,
    data: param
  });
  return response.data;
};

/**
 * 로그인연동
 * @param {*} param 
 * @returns 
 */
export const putLoginLink = async (param) => {
  const response = await axios({
    method:'put',
    url:`${process.env.REACT_APP_API_SERVER}/front/ptcpnts/signlink`,
    data: {'enckey':param.enckey, 'channelidx':param.channelidx}
  });
  return response.data;
};

/**
 * 로그인연동 -> 특정 채널의 경우 가입하면서 바로 로그인 처리 (메디게이트)
 * @param {*} param 
 * @returns 
 */
export const putLoginJoin = async (param) => {
  const response = await axios({
    method:'put',
    url:`${process.env.REACT_APP_API_SERVER}/front/ptcpnts/medigate`,
    data: param
  });
  return response.data;
};

/**
 * 로그인연동 -> 특정 채널의 경우 가입하면서 바로 로그인 처리 (링크지움)
 * @param {*} param 
 * @returns 
 */
 export const putLoginLinksium = async (param) => {
  const response = await axios({
    method:'put',
    url:`${process.env.REACT_APP_API_SERVER}/front/ptcpnts/linksium`,
    data: param
  });
  return response.data;
};

/**
 * 참여한 웨비나목록.
 * @param {*} param 
 * @returns 
 */
export const getParticipatedWebinars = async (param) => {
  const response = await axios({
    method:'get',
    url:`${process.env.REACT_APP_API_SERVER}/front/participated`,
    headers: {
      'Authorization': 'Bearer ' + window.sessionStorage.getItem('usrtoken')
    }
  });
  return response.data;
};

/**
 * 숏컷조회
 * @param {*} param 
 * @returns 
 */
 export const getShortcut = async (param) => {
   console.log('@@@@@@@@@@@@@@@@@',param.shortcut);
  const response = await axios({
    method:'get',
    url:`${process.env.REACT_APP_API_SERVER}/front/ptcpnts/shortcuts/${param.shortcut}`
  });
  return response.data;
};
