import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { isNotNull, printValidate } from '../lib/wbUtils';
import { clearPutPtcpntUpdate, postCheckKey, putPtcpntUpdate } from '../modules/ptcpnts';

function EmailVerifiedContainer({ history, location }) {

  const params = new URLSearchParams(location.search);
  const token = params.get('email_verification_token'); 
  const dispatch = useDispatch();
  const { checkkeydone, ptcpntmoddone } = useSelector(state => ({
    checkkeydone:state.modPtcpnt.checkkeydone
    , ptcpntmoddone:state.modPtcpnt.ptcpntmoddone
  }))
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const [webinaridx, setWebinaridx] = useState(0);

  useEffect(() => {
    dispatch(postCheckKey(token))
  },[dispatch, token])

  useEffect(()=>{
    if(isNotNull(checkkeydone.data) && isNotNull(checkkeydone.data.result_cd)) {
      if(checkkeydone.data.result_cd===1){
        if(checkkeydone.data.map.info.authorization===true) {
        setValue('ptcpntemail', checkkeydone.data.map.info.ptcpntemail);
        setValue('webinaridx', checkkeydone.data.map.info.webinaridx);
        setWebinaridx(checkkeydone.data.map.info.webinaridx);
        } else {
          toast.error("Token has been expired.")
          setTimeout(function(){history.push('/login/'+webinaridx)},500);
        }
      } else {
        toast.error(`The service is not smooth Please use it later : ${checkkeydone.data.result_msg}`);
        setTimeout(function(){history.push('/login/'+webinaridx)},500);
      }
    }

    if(ptcpntmoddone.data!==null && ptcpntmoddone.data.result_cd!==null){
      if(ptcpntmoddone.data.result_cd===1){
        dispatch(clearPutPtcpntUpdate());
        toast.success("Your password has been changed.")
        setTimeout(function(){history.push('/login/'+webinaridx)},500);
      } else {
        toast.error(`The service is not smooth Please use it later : ${ptcpntmoddone.data.result_msg}`);
      }
    }
  },[checkkeydone, ptcpntmoddone])
  
  const changePassword = (data) => {
    dispatch(putPtcpntUpdate(data));
  }

  return (
    <>
      <div className="login-wrap">
        <div className="login-box">
          <div className="content">
            <div className="login-header" style={{marginTop:10, marginBottom:50}}>
              <p>Change your password</p>
            </div>
            <ul className="input-area">
              <li>
                <b>password</b>
              </li>
              <li>
                  <input type="password" className="type05" name="ptcpntpwd"
                    {...register('ptcpntpwd',{ required: true,  minLength:{
                      value:8,
                      message:"password shoule be longer than 8 characters"
                    } })}
                  />
              </li>
              {printValidate(errors.ptcpntpwd, "Password")}
            </ul>
            <div className="btn-wrap">
              <button className="btn-register" href="#" onClick={handleSubmit(changePassword)}>Change Password</button>
          </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmailVerifiedContainer;
