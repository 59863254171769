import axios from 'axios';

/**
 * 다운로드
 */
export const downloadFile = async (param) => {
  const fileidx = param.fileidx;
  const response = await axios({
    method:'get',
    url:`${process.env.REACT_APP_API_SERVER}/front/files/${fileidx}`,
    headers: {
      'Authorization': 'Bearer ' + window.sessionStorage.getItem('usrtoken')
    },
    responseType: 'arraybuffer' // 가장 중요함
  })

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  const contentDisposition = response.headers['content-disposition']; // 파일 이름
  let fileName = "undefined";
  if (contentDisposition) {
    const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'));
    if (fileNameMatch)
      [ , fileName ] = fileNameMatch.split('"');
  }
  fileName = decodeURIComponent(fileName);
  link.href = url;
  link.setAttribute('download', `${fileName}`);
  link.style.cssText = 'display:none';
  document.body.appendChild(link);
  link.click();
  link.remove();
  return response.data;
};