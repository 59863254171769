import React from "react";
import BodyClassName from "react-body-classname";
import EmailVerifiedContainer from "../containers/EmailVerifiedContainer";

function PasswordResetPage({ match,history, location }) {
  return (
    <BodyClassName className="">
      <>
      <EmailVerifiedContainer history={history} location={location}/>
      </>
    </BodyClassName>
  );
}

export default PasswordResetPage;
