import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import BodyClassName from 'react-body-classname';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isNotNull, isNull } from '../lib/wbUtils';
import { putLoginJoin, saveLogin } from '../modules/login';

/**
 * 링크지움.
 * @param {*} param0 
 * @returns 
 */
function LoginJoinContainer({enckey, webinaridx})  {

  const { data, loading, error } = useSelector(state => (state.modLogin.logininfo))
  const { usrtoken } = useSelector(state => (state.modLogin.loginusr))
  let history = useHistory();

  const dispatch = useDispatch();
  let cnt = 0;
  const defLogin = {'loginid':'','loginpwd':''};
  const [login, setLogin] = useState(defLogin);
  const [bodycss, setBodycss] = useState();
  const [titleText, setTitleText] = useState('');

  // 로그인처리
  const handleLoginJoinProc = () => {
    if (cnt === 0) {
      cnt++;
      dispatch(putLoginJoin({'enckey':enckey,'webinaridx':webinaridx}));
    }
  }

  useEffect(() => {
    console.log('useEffect',data);

    if (error) {
      toast.error(`Login fail : ${error.message}`);
      return;
    }

    // ...
    //console.log('getDerivedStateFromProps',nextProps,prevState);
    //console.log('logininfo1',prevState.modLogin.logininfo);
    //console.log('logininfo2',nextProps.modLogin.logininfo);
    if (isNotNull(data)) {
      if (data.result_cd===1) {
        console.log('login success!!',data.map);
        const {ptcpntemail, ptcpntidx, ptcpntnm, ptcpnthp, company, dept, usrtoken} = data.map;
        window.sessionStorage.setItem('ptcpntemail', ptcpntemail);
        window.sessionStorage.setItem('ptcpntidx', ptcpntidx);
        window.sessionStorage.setItem('ptcpntnm', ptcpntnm);
        window.sessionStorage.setItem('ptcpnthp', ptcpnthp);
        window.sessionStorage.setItem('usrtoken', usrtoken);
        window.sessionStorage.setItem('company', company);
        window.sessionStorage.setItem('dept', dept);
        setLogin(defLogin)
        // 어디론가 이동...
        console.log('move~~~!!');
        setTitleText('웨비나로 이동합니다. <br/><span>잠시만 기다려 주세요.</span>');
        dispatch(saveLogin(ptcpntemail,ptcpntidx,ptcpntnm,usrtoken))
        setTimeout(function(){history.push('/webinars/live/'+webinaridx)},500);
      } else {
        toast.error(`Login fail : ${data.result_msg}`);
        console.log('login fail!!', data.result_msg);
      }
    } else if (isNull(usrtoken)){
      handleLoginJoinProc();
    }
  },[data, error])

  /**
   * 웨비나를 클릭했을때 이동
   */
  const handleGoWebinar = (e) => {
    if (isNotNull(data)) {
      if (data.result_cd===1) {
        console.log('login success!!',data.map);
        const {ptcpntemail, ptcpntidx, ptcpntnm, ptcpnthp, usrtoken, webinars, logos} = data.map;
        // 로그인정보 저장 및 로그인폼정보 초기화
        dispatch(saveLogin(ptcpntemail,ptcpntidx,ptcpntnm,usrtoken))
      }
    }
    const widx = e.currentTarget.getAttribute('webinaridx');
    setTimeout(function(){history.push('/webinars/live/'+widx)},500);
    return false;
  }

  const useStyles = makeStyles({
    bodybg: {
      background: `${bodycss} !important`
    }
  })
  const classes = useStyles();

  return (
    <BodyClassName className={`gradient-body ${bodycss!==null&&bodycss!==undefined&&classes.bodybg}`}>
    <>
    <div className="logo-list-wrap">
    {
      isNotNull(data) && isNotNull(data.map) && isNotNull(data.map.logos) &&
        data.map.logos.map((c, index) => {
          return (            
            <img src={c.fileuri} key={index}/>
          )
      })}
    </div>
    <div className="webinar-list-wrap">
        <ul className="list-box-wrap">
            <li dangerouslySetInnerHTML={ {__html: titleText} }>
            </li>
        </ul>
    </div>
    {/* <p className="list-footer">Copyright ⓒ Viatris All rights reserved.</p> */}
    </>
    </BodyClassName>
  );
}

export default withRouter(LoginJoinContainer);
