import axios from 'axios';

/**
 * 투표 목록 조회
 * @param {*} param 
 */
export const getPolls = async (param) => {
  const response = await axios({
    method:'get',
    url:`${process.env.REACT_APP_API_SERVER}/front/polls`,
    params: param,
    headers: {
      Authorization: 'Bearer ' + window.sessionStorage.getItem('usrtoken')
    }
  });
  return response.data;
};

/**
 * 투표 등록
 * @param {*} param 
 */
export const postPoll = async (param) => {
  console.log('param',param);
  const response = await axios({
    method:'post',
    url:`${process.env.REACT_APP_API_SERVER}/front/polls`,
    // data 는 body 에, params 는 쿼리에..
    data: param.data,
    headers: {
      'Authorization': 'Bearer ' + window.sessionStorage.getItem('usrtoken')
    }
  });
  return response.data;

}