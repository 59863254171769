import axios from 'axios';

/**
 * 설문 목록 조회
 * @param {*} param 
 */
export const getSurvey = async (param) => {
  const response = await axios({
    method:'get',
    url:`${process.env.REACT_APP_API_SERVER}/front/survey`,
    // data 는 body 에, params 는 쿼리에..
    params: {
      'webinaridx':param.webinaridx,
      'parenttp':param.parenttp
    },
    headers: {
      Authorization: 'Bearer ' + window.sessionStorage.getItem('usrtoken')
    }
  });
  return response.data;
};

/**
 * 설문 답변 등록
 * @param {*} param 
 */
export const postSurvey = async (param) => {
  const response = await axios({
    method:'post',
    url:`${process.env.REACT_APP_API_SERVER}/front/survey`,
    data:param.data,
    headers: {
      Authorization: 'Bearer ' + window.sessionStorage.getItem('usrtoken')
    }
  });
  return response.data;
}