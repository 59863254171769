import React from 'react';
import LoginLinksiumContainer from '../containers/LoginLinksiumContainer';
import FooterLogin from '../fragments/FooterLogin';

/**
 * 폼서밋으로 linksium,m2m,mundipharma 등에서 자동가입과 함께 로그인 시킬때 사용함.
 * @param {} param0 
 * @returns 
 */
function LoginLinksiumPage({ location }) {

  const params = new URLSearchParams(location.search);
  const enckey = params.get('enckey');
  const channelidx = params.get('channelidx');
  const webinaridx = params.get('webinaridx');
  const liveservermapidx = params.get('liveservermapidx');
  const linkinfoidx = params.get('linkinfoidx');
  // console.log('@@@webinaridx',webinaridx);
  // console.log('@@@liveservermapidx',liveservermapidx,parseInt(liveservermapidx, 0));
  return (
    <>
      <LoginLinksiumContainer 
        enckey={enckey} 
        channelidx={parseInt(channelidx, 0)}
        linkinfoidx={parseInt(linkinfoidx, 0)}
        webinaridx={parseInt(webinaridx, 0)}
        liveservermapidx={parseInt(liveservermapidx, 0)}
        />
      <FooterLogin/>
      </>
  );
}

export default LoginLinksiumPage;
