import { ErrorOutline } from '@material-ui/icons';
import Pollitem from './Pollitem';
function Poll({ poll, code, isMobile }) {

  const { ques, totvote, procsts, credt, pollitems } = poll;

  let colors = ["pink", "blue"];
  if (isMobile) colors = ["m-pink", "m-blue"];

  const pcjsx = (
    <>
      <li>
        <a href="#!"><span>Q</span>{ques}</a>
        <ul className="poll-result">
          <li>
            {
              poll.resulttp === '010'?
              <div className="noData"><ErrorOutline className="material-icons"/> Results are private.</div>
              :
              pollitems !== null && pollitems !== undefined &&
              pollitems.map((item, index) => {
                return (
                  <Pollitem 
                    key={index}
                    pollitem={item}
                    totvote={totvote}  
                    color={colors[(code%2)]}
                    isMobile={isMobile}
                  />
                )
              })
            }
          </li>
        </ul>
      </li>
    </>
  )
  const mobilejsx = (
    <>
      <li>
        <a href="#!"><span>Q</span>{ques}</a>
        <ul className="m-poll-result">
          <li>
            {
              poll.resulttp === '010'?
              <div className="noData"><ErrorOutline className="material-icons"/> Results are private.</div>
              :
              pollitems !== null && pollitems !== undefined &&
              pollitems.map((item, index) => {
                return (
                  <Pollitem 
                    key={index}
                    pollitem={item}
                    totvote={totvote}  
                    color={colors[(code%2)]}
                    isMobile={isMobile}     
                  />
                )
              })
            }
          </li>
        </ul>
      </li>
    </>
  )
  if (isMobile) return mobilejsx;
  else return pcjsx;
}

export default Poll;