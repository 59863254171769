import Poll from './Poll';

function PollList({ polls, isMobile }) {

  const pcjsx = (
    <>
      <ul className="poll-modal-wrap">
      {
        polls !== null && polls !== undefined &&
        polls.map((c, index) => {
          return <Poll
            poll={c}
            key={index}
            code={index}
            isMobile={isMobile}
          />
        })}
      </ul>
    </>
  )
  const mobilejsx = (
    <>
      <ul className="m-poll-modal-wrap">
      {
        polls !== null && polls !== undefined &&
        polls.map((c, index) => {
          return <Poll
            poll={c}
            key={index}
            code={index}
            isMobile={isMobile}
          />
        })}
      </ul>
    </>
  )
  if (isMobile) return mobilejsx;
  else return pcjsx;
}

export default PollList;