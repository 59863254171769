import Surveyques from './Surveyques';

function SurveyquesList({ surveyques, handleRadioChange, handleChange, register, errors, isMobile }) {

  return (
    <>
      <ul className={isMobile?'m-survey-modal-wrap':'survey-modal-wrap'}>
      {
        surveyques !== null && surveyques !== undefined &&
        surveyques.map((c, index) => {
          return <Surveyques
            quesitem={c}
            key={index}
            handleChange={handleChange}
            handleRadioChange={handleRadioChange}
            register={register} 
            errors={errors}
          />
        })}
      </ul>
    </>
  )
}

export default SurveyquesList;