import { makeStyles } from '@material-ui/styles';
import React from 'react';
import BodyClassName from 'react-body-classname';
import {
  isMobile
} from "react-device-detect";
import WebinarGatewayContainer from '../containers/WebinarGatewayContainer';

/**
 * 로그인 후 설정에 따라 게이트웨이 페이지를 사용할 경우 보여주는 화면
 * @param {*} param0 
 * @returns 
 */
 function WebinarGatewayPage({ match,history,bodycss }) {
  const { webinaridx } = match.params; // URL 파라미터 조회하기

  const useStyles = makeStyles({
    bodybg: {
      background: `${bodycss} !important`
    }
  })

  const classes = useStyles();
  return (
   <BodyClassName className={`${bodycss!==null&&bodycss!==undefined?classes.bodybg:''}`}>
    <>
      <WebinarGatewayContainer 
      webinaridx={parseInt(webinaridx, 10)} 
      history={history} isMobile={isMobile}/>
    </>
    </BodyClassName>   
  )
}

export default WebinarGatewayPage;
