import { makeStyles } from '@material-ui/styles';
import React from 'react';
import BodyClassName from 'react-body-classname';
import { isMobile } from "react-device-detect";
import WebinarVodListContainer from '../containers/WebinarVodListContainer';
/**
 * 웨비나 vod 목록 페이지
 * @param {*} param0 
 * @returns 
 */
function WebinarVodListPage({ match,history,bodycss }) {

  const { webinaridx } = match.params; // URL 파라미터 조회하기

  const useStyles = makeStyles({
    bodybg: {
      background: `${bodycss} !important`
    }
  })

  const classes = useStyles();
  return (
    <BodyClassName className={`${bodycss!==null&&bodycss!==undefined?classes.bodybg:''}`}>
     <>
       <WebinarVodListContainer 
       webinaridx={parseInt(webinaridx, 10)} 
       history={history} isMobile={isMobile}/>
     </>
     </BodyClassName>   
   )
}

export default WebinarVodListPage;
