import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { isNotNull, isNull, printValidate } from '../lib/wbUtils';
import { getPolls, postPoll } from '../modules/poll';
import { getWebinar } from '../modules/login';
import { withRouter } from 'react-router-dom';

function  PollQRContainer({ webinaridx, pushedpoll, handleExecuteLivePoll,history, isMobile}) {

  const dispatch = useDispatch();
  const { t }  = useTranslation([]);

  const { data, webinar } = useSelector(state => ({
    data:state.modPoll.polls.data,
    webinar:state.modLogin.webinar.data
  }));

  console.log("::::::::::::::::::", data, webinar);


  
  
  const [poll, setPoll] = useState({pollidx:-1, ques:'', totvote:0, timelimit:0, polltp:'010', procsts:'010', pollitems:[]});
  const [info, setInfo] = useState({'webinarnm':'','webinarlogo':'/images/img_logo01.png','boothyn':'N','loginuri':null,'logintp':'010','procsts':'010','regyn':'Y'});
  const [clearTimer,setClearTimer] = useState(false);
  const [limitTime, setLimitTime] = useState(0);
  const [limitTimeStr, setLimitTimeStr] = useState();
  
  // input validation 참고 https://react-hook-form.com/kr/
  const { register, handleSubmit, getValues, formState: { errors } } = useForm({
    mode: "onChange",
    defaultValues: {
      pollitemidx: []
    }
  });
  
  // getAttribute로 가져오는 방법. 고려, 훅폼 오류 원인 못 찾음.
  const handleVote = (info, event) => {
    info.webinaridx = webinaridx;
    info.pollidx = event.target.pollidx.getAttribute('value')
    console.log(event.target.pollidx.getAttribute('value'));
    dispatch(postPoll(info));
    clearCountdown();
  }

  const closePoll = () => {
    clearCountdown();
    setTimeout(function(){history.push('/loginpoll/' + webinaridx)},500);
  }

  const clearCountdown = () => {
    setClearTimer(true);
    clearInterval(countdown);
    setTimeout(()=>{setLimitTimeStr('')},1000);
  }

  let countdown;
  /**
   * 자동종료 타이머 동작 필요시.
   */
  useEffect(() => {
    if (clearTimer === true) return;
    if (poll.timelimit === 0) return;
    // 타이머... 이거 필요할까...
    countdown = setInterval(() => {
      if (limitTime <= 0) {
        clearInterval(countdown);
        setClearTimer(true);
        // 투표창 닫음
        handleExecuteLivePoll();
      }
      if (clearTimer === false) {
        //console.log('@@@@@@@@@@',(limitTime - 1));
        setLimitTime(limitTime => limitTime - 1);
        setLimitTimeStr(t("poll:remaining-time", {"limitTime": limitTime - 1}));
        //setLimitTimeStr(`투표종료까지 남은시간 : ${limitTime - 1} 초`);
      }
    }, 1000);
    return () => clearInterval(countdown);
  }, [limitTime]);


  useEffect(() => {
    console.log("webinaridx", webinaridx);
    dispatch(getPolls(webinaridx));
    setTimeout(function(){dispatch(getWebinar(webinaridx,0b00000011));},500);

    // setInfo(webinar.map.info);

    console.log("webinar", webinar);
  

    // console.log('@@@LivePollDiv.useEffect1',pushedpoll,isMobile);
    // if (!isNull(data)) {
    //   console.log('data',data);
    // }
    // if (pollidx == -1) {
    //   //dispatch(getPolls(webinaridx));
    //   setPoll(pushedpoll);
    //   setLimitTime(pushedpoll.timelimit);
    //   console.log("data::::::::::::::::", data);
    // }
 

  },[dispatch])

  useEffect(() => {
    // 투표를 하고 나면 호출되는 부분이다.
    if (!isNull(data)) {
      if (data.result_cd !== 1) {
        // 에러이거나 이미 투표한 경우
        if (data.result_cd === 222) {
          toast.info(`${data.result_msg}`); 
        } else {
          toast.error(`Error : ${data.result_msg}`);
        }
        // 이게 되네? 안되는줄 알았는데... store 의 값까지 변경된다..!
        data.result_cd = 1;
      }
      // if (!isNull(data.map) && !isNull(data.map.polls)) {
      //   for (const p of data.map.polls) {
      //     if (p.pollidx === parseInt(pollidx)) {
      //       setPoll(p);
      //     }
      //   }
      //   // poll 이 하나 이상일 경우 data를 비워주는 것이 필요하다.
      //   // poll 을 디비에서 가져오는 것이 아니라 push 로 받아오기 때문에 미리 투표여부를 알 수가 없다.
      //   // data.map = null;

      // }
      if (poll.resulttp === '010') {
        // 결과 비공개이면 투표후 투표창 바로 닫음
        handleExecuteLivePoll()
      }
    }
  },[data])

  useEffect(() => {
    // 투표를 하고 나면 호출되는 부분이다.
    if (!isNull(webinar)) {
      console.log('webinar@@@@@@',webinar)
      setInfo(webinar.map.info);
    }
  },[webinar])

  const atLeastOne = () => {
    //console.log(getValues("pollitems").length);
    //return getValues("pollitems").length ? true : "Please tell me if this is too hard.";
  }

  const pcjsx = (
    <div className="QR-chat-wrap">
      <h1 className="QR-chat-title">
        {isNotNull(info)? info.webinarnm:null}
      </h1>

      {data && data.map.polls.map((c, index) => 
      <div key={index}>
        {console.log("data", c)}
        {c.voteyn === "N" ?         
      <form onSubmit={handleSubmit(handleVote)} style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
       <div className="poll-wrap-qr">
        <h3>{c.ques}</h3>
          <input name="pollidx" value={c.pollidx} style={{display: "none"}} />
        
        
        <ul className="poll-list">

        {
        !isNull(data.map.polls) &&
        c.pollitems.map((p, index) => {
          
          if (data.map.polls.polltp === '020') {
            return (
              <div>
              <li key={index} className="rq-li">
                <input type="checkbox" name="pollitemidx" value={p.pollitemidx} {...register('pollitemidx',{
                  validate: atLeastOne
                })}/>
                  {p.item}
              </li>

              

              </div>
            )
          } else {
            return (
              <>
                <li key={index} className="rq-li">
                  <input type="radio" name="pollitemidx" value={p.pollitemidx}  {...register('pollitemidx',{ required: true})}/>
                    {p.item}
                </li>
              
              </>
            )
          }

        })}
          <li>{printValidate(errors.pollitemidx, 'Item')}</li>
        </ul>
        
        
        
        <div className="btn-wrap" style={{marginBottom:'20px', display: "flex", flexDirection: "row", justifyContent: "center"}}>
          {poll.voteyn !== 'Y' ?
          <button type="submit" className="btn btn-vote">Vote</button>
          :null}
        </div>
      </div>
      </form>
        
        :
        <div className="QR-poll-result">
          <ul className="poll-result">
            <h3 className="QR-poll-ques">{c.ques}</h3>
            {!isNull(c.pollitems) &&
            c.pollitems.map((p, index) => {
              const percentage = p.votecnt!==0?Math.ceil((p.votecnt/p.totvote) * 100 * 0.9 ):1;
              return (
                <li key={index}>
                  <p>{p.item}</p>
                  <div className="poll-bar">
                    <p className="pink" style={{width:`${percentage}%`}}><span>{p.votecnt}명</span></p>
                  </div>
                </li>
              )
            })}
            
          </ul>
        </div> 
        }
      </div>
       )}
       <button className="btn btn-close" onClick={closePoll}>Close</button>
    </div>
  );

  return pcjsx;
  
}

export default withRouter(PollQRContainer);