import { makeStyles } from '@material-ui/styles';
import React from "react";
import BodyClassName from "react-body-classname";
import {
  isMobile
} from "react-device-detect";
import PreRegistrationContainer from "../containers/PreRegistrationContainer";
import FooterLogin from '../fragments/FooterLogin';

function PreRegisterPage({ match,history,bodycss, lcopyright }) {
  //const [bodycss, setBodycss] = useState(null);
  //console.log("test"+bodycss);

  const useStyles = makeStyles({
    bodybg: {
      background: `${bodycss} !important`
    }
  })

  const classes = useStyles();

  return (
    <BodyClassName className={`gradient-body ${/*bodycss!==null&&bodycss!==undefined?classes.bodybg:*/''}`}>
      <>
      <PreRegistrationContainer history={history} isMobile={isMobile}/>
      <FooterLogin lcopyright={lcopyright}/>
      </>
    </BodyClassName>
  );
}

export default PreRegisterPage;
