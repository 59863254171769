import axios from 'axios';

/**
 * FAQ 조회
 */
export const getFaqs = async (param) => {
  console.log('@@@param',param);
  const response = await axios({
    method:'get',
    url:`${process.env.REACT_APP_API_SERVER}/front/faqs`,
    params: {
      'webinaridx':param.webinaridx
    },
    headers: {
      Authorization: 'Bearer ' + window.sessionStorage.getItem('usrtoken')
    }
  });
  return response.data;
};