import axios from 'axios';

/**
 * 설문 목록 조회
 * @param {*} param 
 */
export const getPtcpntItems = async (param) => {
  const response = await axios({
    method:'get',
    url:`${process.env.REACT_APP_API_SERVER}/front/ptcpnts/prereg`,
    params: {
        'webinaridx':param
      }
  });
  return response.data;
};

/**
 * 참가자 등록
 * @param {*} param 
 */
export const putPtcpnt = async (param) => {
  const response = await axios({
    method:'put',
    url:`${process.env.REACT_APP_API_SERVER}/front/ptcpnts/prereg`,
    // data 는 body 에, params 는 쿼리에..
    data: param.data
  });
  return response.data;

}

export const postSendCode = async (param) => {
  const response = await axios({
    method:'post',
    url:`${process.env.REACT_APP_API_SERVER}/front/ptcpnts/auth/send_code`,
    data:param.data
  });
  return response.data;
}

export const postCheckKey = async (param) => {
  const response = await axios({
    method:'post',
    url:`${process.env.REACT_APP_API_SERVER}/front/ptcpnts/auth/verify_email`,
    data:param
  });
  return response.data;
}

export const putPtcpntUpdate = async (param) => {
  const response = await axios({
    method:'put',
    url:`${process.env.REACT_APP_API_SERVER}/front/ptcpnts/auth/reset_password`,
    data:param.data
  });
  return response.data;
}