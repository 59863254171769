import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
import modFaq, { faqSaga } from './faq';
import modFile, { fileSaga } from './file';
import modFirstCall, { firstCallSaga } from './firstcall';
import modLives, { livesSaga } from './lives';
import modLogin, { DESTROY_SESSION, loginSaga } from './login';
import modPoll, { pollSaga } from './poll';
import modPresurvey, { presurveySaga } from './presurvey';
import modPtcpnt, { ptcpntsSaga } from './ptcpnts';
import modSurvey, { surveySaga } from './survey';

// 아래의 channels 는 위의 import 하는 이름으로, modules/channels 안의 initialState 의 key 이름과 같아야함.
const appReducer = combineReducers({ modLogin, modLives, modSurvey, modFaq, modPoll
  , modFile, modPtcpnt, modFirstCall, modPresurvey });

const rootReducer = (state, action) => {
  // 로그아웃시 스토어 초기화.
  if (action.type === DESTROY_SESSION) {
    state = undefined
  }
  console.log('state@@',state);
  return appReducer(state, action)
}

export function* rootSaga() {
  yield all([ loginSaga(), livesSaga(), surveySaga(), faqSaga(), pollSaga()
    , fileSaga(), ptcpntsSaga(), firstCallSaga(), presurveySaga() ]); // all 은 배열 안의 여러 사가를 동시에 실행시켜줍니다.
}

export default rootReducer;
