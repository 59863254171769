import axios from 'axios';


/**
 * firstcall 등록
 * @param {*} param 
 */
export const postFirstCall = async (param) => {
  console.log('param',param);
  const response = await axios({
    method:'post',
    url:`${process.env.REACT_APP_API_SERVER}/front/firstcalls`,
    // data 는 body 에, params 는 쿼리에..
    data: param.data,
    headers: {
      'Authorization': 'Bearer ' + window.sessionStorage.getItem('usrtoken')
    }
  });
  return response.data;
};
