import { call, put, takeEvery } from 'redux-saga/effects';
import * as PtcpntsAPI from '../api/ptcpnts'; // api/ptcpnts 안의 함수 모두 불러오기
import {
  handleAsyncActions, reducerUtils
} from '../lib/asyncUtils';


// 항목 조회하기
const GET_PTCPNT_ITEM = 'GET_PTCPNT_ITEM';                    // 요청 시작
const GET_PTCPNT_ITEM_SUCCESS = 'GET_PTCPNT_ITEM_SUCCESS';    // 요청 성공
const GET_PTCPNT_ITEM_ERROR = 'GET_PTCPNT_ITEM_ERROR';        // 요청 실패

// 참가자 등록하기
const PUT_PTCPNT = 'PUT_PTCPNT';
const PUT_PTCPNT_SUCCESS = 'PUT_PTCPNT_SUCCESS';
const PUT_PTCPNT_ERROR = 'PUT_PTCPNT_ERROR';

// 참가자 등록하기
const POST_SEND_CODE = 'POST_SEND_CODE';
const POST_SEND_CODE_SUCCESS = 'POST_SEND_CODE_SUCCESS';
const POST_SEND_CODE_ERROR = 'POST_SEND_CODE_ERROR';

// 참가자 등록하기
const POST_CHECK_KEY = 'POST_CHECK_KEY';
const POST_CHECK_KEY_SUCCESS = 'POST_CHECK_KEY_SUCCESS';
const POST_CHECK_KEY_ERROR = 'POST_CHECK_KEY_ERROR';

// 참가자 등록하기
const PUT_PTCPNT_UPDATE = 'PUT_PTCPNT_UPDATE';
const PUT_PTCPNT_UPDATE_SUCCESS = 'PUT_PTCPNT_UPDATE_SUCCESS';
const PUT_PTCPNT_UPDATE_ERROR = 'PUT_PTCPNT_UPDATE_ERROR';

// 참가자 등록후 초기화
const CLEAR_PUT_PTCPNT = "CLEAR_PUT_PTCPNT";
const CLEAR_POST_SEND_CODE = "CLEAR_POST_SEND_CODE";
const CLEAR_POST_CHECK_KEY = "CLEAR_POST_CHECK_KEY";
const CLEAR_PUT_PTCPNT_UPDATE = "CLEAR_PUT_PTCPNT_UPDATE";

export const getPtcpntItem = (webinaridx) => ({type: GET_PTCPNT_ITEM, param: webinaridx});
export const putPtcpnt = (data) => ({ type: PUT_PTCPNT, param: { data } });
export const postSendCode = (data) => ({type: POST_SEND_CODE, param: { data }})
export const postCheckKey = (pwdauthkey) => ({ type:POST_CHECK_KEY, param: { 'pwdauthkey':pwdauthkey }})
export const putPtcpntUpdate = (data) => ({ type:PUT_PTCPNT_UPDATE, param: { data }})

export const clearPutPtcpnt = () => ({ type: CLEAR_PUT_PTCPNT });
export const clearpostSendCode = () => ({ type: CLEAR_POST_SEND_CODE });
export const clearpostCheckKey = () => ({ type: CLEAR_POST_CHECK_KEY });
export const clearPutPtcpntUpdate = () => ({ type: CLEAR_PUT_PTCPNT_UPDATE });

function* getPtcpntItemSaga(action) {
  const param = action.param;
  try {
    const items = yield call(PtcpntsAPI.getPtcpntItems, param); // call 을 사용하면 특정 함수를 호출하고, 결과물이 반환 될 때까지 기다려줄 수 있습니다.
    yield put({
      type: GET_PTCPNT_ITEM_SUCCESS,
      payload: items
    }); // 성공 액션 디스패치
  } catch (e) {
    yield put({
      type: GET_PTCPNT_ITEM_ERROR,
      error: true,
      payload: e
    }); // 실패 액션 디스패치
  }
}

// 참가자등록 사가~
function* putPtcpntSaga(action) {
  const param = action.param;
  try {
    const PTCPNT = yield call(PtcpntsAPI.putPtcpnt, param); // API 함수에 넣어주고 싶은 인자는 call 함수의 두번째 인자부터 순서대로 넣어주면 됩니다.
    yield put({
      type: PUT_PTCPNT_SUCCESS,
      payload: PTCPNT
    });
  } catch (e) {
    yield put({
      type: PUT_PTCPNT_ERROR,
      error: true,
      payload: e
    });
  }
}

function* postSendCodeSaga(action) {
  const param = action.param;
  try {
    const PTCPNT = yield call(PtcpntsAPI.postSendCode, param); // API 함수에 넣어주고 싶은 인자는 call 함수의 두번째 인자부터 순서대로 넣어주면 됩니다.
    yield put({
      type: POST_SEND_CODE_SUCCESS,
      payload: PTCPNT
    });
  } catch (e) {
    yield put({
      type: POST_SEND_CODE_ERROR,
      error: true,
      payload: e
    });
  }
}
function* postCheckKeySaga(action) {
  const param = action.param;
  try {
    const key = yield call(PtcpntsAPI.postCheckKey, param); // API 함수에 넣어주고 싶은 인자는 call 함수의 두번째 인자부터 순서대로 넣어주면 됩니다.
    yield put({
      type: POST_CHECK_KEY_SUCCESS,
      payload: key
    });
  } catch (e) {
    yield put({
      type: POST_CHECK_KEY_ERROR,
      error: true,
      payload: e
    });
  }
}
function* putPtcpntUpdateSaga(action) {
  const param = action.param;
  try {
    const PTCPNT = yield call(PtcpntsAPI.putPtcpntUpdate, param); // API 함수에 넣어주고 싶은 인자는 call 함수의 두번째 인자부터 순서대로 넣어주면 됩니다.
    yield put({
      type: PUT_PTCPNT_UPDATE_SUCCESS,
      payload: PTCPNT
    });
  } catch (e) {
    yield put({
      type: PUT_PTCPNT_UPDATE_ERROR,
      error: true,
      payload: e
    });
  }
}

// 사가들을 합치기
export function* ptcpntsSaga() {
  yield takeEvery(GET_PTCPNT_ITEM, getPtcpntItemSaga);
  yield takeEvery(PUT_PTCPNT, putPtcpntSaga);
  yield takeEvery(POST_SEND_CODE, postSendCodeSaga);
  yield takeEvery(POST_CHECK_KEY, postCheckKeySaga);
  yield takeEvery(PUT_PTCPNT_UPDATE, putPtcpntUpdateSaga);
}

const initialState = {
  ptcpntregdone: reducerUtils.initial(),
  items: reducerUtils.initial(),
  sendcodedone:reducerUtils.initial(),
  checkkeydone:reducerUtils.initial(),
  ptcpntmoddone:reducerUtils.initial()
};

export default function ptcpnts(state = initialState, action) {
  switch (action.type) {
    case GET_PTCPNT_ITEM:
    case GET_PTCPNT_ITEM_SUCCESS:
    case GET_PTCPNT_ITEM_ERROR:
      return handleAsyncActions(GET_PTCPNT_ITEM, 'items', true)(state, action);
    case PUT_PTCPNT:
    case PUT_PTCPNT_SUCCESS:
    case PUT_PTCPNT_ERROR:
      return handleAsyncActions(PUT_PTCPNT, 'ptcpntregdone', true)(state, action);
    case POST_SEND_CODE:
    case POST_SEND_CODE_SUCCESS:
    case POST_SEND_CODE_ERROR:
      return handleAsyncActions(POST_SEND_CODE, 'sendcodedone', true)(state, action);
    case POST_CHECK_KEY:
    case POST_CHECK_KEY_SUCCESS:
    case POST_CHECK_KEY_ERROR:
      return handleAsyncActions(POST_CHECK_KEY, 'checkkeydone', true)(state, action);
    case PUT_PTCPNT_UPDATE:
    case PUT_PTCPNT_UPDATE_SUCCESS:
    case PUT_PTCPNT_UPDATE_ERROR:
      return handleAsyncActions(PUT_PTCPNT_UPDATE, 'ptcpntmoddone', true)(state, action);
    case CLEAR_PUT_PTCPNT:
      return { ...state, ptcpnts: { ...state.ptcpntregdone }, ptcpntregdone: reducerUtils.initial() };
    case CLEAR_POST_SEND_CODE:
      return { ...state, ptcpnts: { ...state.sendcodedone }, sendcodedone: reducerUtils.initial() };
    case CLEAR_POST_CHECK_KEY:
      return { ...state, ptcpnts: { ...state.checkkeydone }, checkkeydone: reducerUtils.initial() };
    case CLEAR_PUT_PTCPNT_UPDATE:
      return { ...state, ptcpnts: { ...state.ptcpntmoddone }, ptcpntmoddone: reducerUtils.initial() };
    default:
      return state;
  }
}