import { call, put, takeEvery } from 'redux-saga/effects';
import * as loginAPI from '../api/login'; // api/login 안의 함수 모두 불러오기
import {
  handleAsyncActions, reducerUtils
} from '../lib/asyncUtils';

/* 액션 타입 */

// 웨비나정보 조회하기
const GET_WEBINAR = 'GET_WEBINAR';                    // 요청 시작
const GET_WEBINAR_SUCCESS = 'GET_WEBINAR_SUCCESS';    // 요청 성공
const GET_WEBINAR_ERROR = 'GET_WEBINAR_ERROR';        // 요청 실패

// 로그인처리
const PUT_LOGIN = 'PUT_LOGIN';                    // 요청 시작
const PUT_LOGIN_SUCCESS = 'PUT_LOGIN_SUCCESS';    // 요청 성공
const PUT_LOGIN_ERROR = 'PUT_LOGIN_ERROR';        // 요청 실패

// 로그인연동처리
const PUT_LOGINLINK = 'PUT_LOGINLINK';                    // 요청 시작
const PUT_LOGINLINK_SUCCESS = 'PUT_LOGINLINK_SUCCESS';    // 요청 성공
const PUT_LOGINLINK_ERROR = 'PUT_LOGINLINK_ERROR';        // 요청 실패

// 로그인연동처리 => 특정채널의 경우 가입시키고 로그인 시킴
const PUT_LOGINJOIN = 'PUT_LOGINJOIN';                    // 요청 시작
const PUT_LOGINJOIN_SUCCESS = 'PUT_LOGINJOIN_SUCCESS';    // 요청 성공
const PUT_LOGINJOIN_ERROR = 'PUT_LOGINJOIN_ERROR';        // 요청 실패

// 로그인연동처리 => 특정채널의 경우 가입시키고 로그인 시킴 (링크지움)
const PUT_LOGINLINKSIUM = 'PUT_LOGINLINKSIUM';                    // 요청 시작
const PUT_LOGINLINKSIUM_SUCCESS = 'PUT_LOGINLINKSIUM_SUCCESS';    // 요청 성공
const PUT_LOGINLINKSIUM_ERROR = 'PUT_LOGINLINKSIUM_ERROR';        // 요청 실패

// 로그인저장.
const SAVE_LOGIN = 'SAVE_LOGIN';                    // 요청 시작
// const SAVE_LOGIN_SUCCESS = 'SAVE_LOGIN_SUCCESS';    // 요청 성공
// const SAVE_LOGIN_ERROR = 'SAVE_LOGIN_ERROR';        // 요청 실패

// 참여한 웨비나목록
const GET_PARTICIPATEDWEBINARS = 'GET_PARTICIPATEDWEBINARS';
const GET_PARTICIPATEDWEBINARS_SUCCESS = 'GET_PARTICIPATEDWEBINARS_SUCCESS';
const GET_PARTICIPATEDWEBINARS_ERROR = 'GET_PARTICIPATEDWEBINARS_ERROR';

// 숏컷 조회하기
const GET_SHORTCUT = 'GET_SHORTCUT';                    // 요청 시작
const GET_SHORTCUT_SUCCESS = 'GET_SHORTCUT_SUCCESS';    // 요청 성공
const GET_SHORTCUT_ERROR = 'GET_SHORTCUT_ERROR';        // 요청 실패


export const DESTROY_SESSION = "DESTROY_SESSION";

export const getWebinar = (webinaridx,infotp) => ({ type: GET_WEBINAR, param:{webinaridx,infotp} });
export const putLogin = (params) => ({ type: PUT_LOGIN, param:params});
export const putLoginLink = (enckey, channelidx) => ({ type: PUT_LOGINLINK, param:{enckey:enckey, channelidx:channelidx}});
export const putLoginJoin = (params) => ({ type: PUT_LOGINJOIN, param:params});
export const putLoginLinksium = (params) => ({ type: PUT_LOGINLINKSIUM, param:params});
export const saveLogin = (ptcpntemail, ptcpntidx, ptcpntnm, usrtoken) => ({ type: SAVE_LOGIN, payload:{ptcpntemail, ptcpntidx, ptcpntnm, usrtoken}});
export const destroySession = () =>({type:DESTROY_SESSION});
export const getParticipatedWebinars = () => ({type:GET_PARTICIPATEDWEBINARS})
export const getLoginShortcut = (shortcut) => ({ type: GET_SHORTCUT, param:{shortcut:shortcut}});

function* getWebinarSaga(action) {
  const param = action.param;
  try {
    const live = yield call(loginAPI.getWebinar, param); // call 을 사용하면 특정 함수를 호출하고, 결과물이 반환 될 때까지 기다려줄 수 있습니다.
    yield put({
      type: GET_WEBINAR_SUCCESS,
      payload: live
    }); // 성공 액션 디스패치
  } catch (e) {
    yield put({
      type: GET_WEBINAR_ERROR,
      error: true,
      payload: e
    }); // 실패 액션 디스패치
  }
}

function* putLoginSaga(action) {
  const param = action.param;
  try {
    const logininfo = yield call(loginAPI.putLogin, param); // call 을 사용하면 특정 함수를 호출하고, 결과물이 반환 될 때까지 기다려줄 수 있습니다.
    yield put({
      type: PUT_LOGIN_SUCCESS,
      payload: logininfo
    }); // 성공 액션 디스패치
  } catch (e) {
    yield put({
      type: PUT_LOGIN_ERROR,
      error: true,
      payload: e
    }); // 실패 액션 디스패치
  }
}

function* putLoginLinkSaga(action) {
  console.log('@@@loginlinksaga');
  const param = action.param;
  try {
    const loginlinkinfo = yield call(loginAPI.putLoginLink, param); // call 을 사용하면 특정 함수를 호출하고, 결과물이 반환 될 때까지 기다려줄 수 있습니다.
    yield put({
      type: PUT_LOGINLINK_SUCCESS,
      payload: loginlinkinfo
    }); // 성공 액션 디스패치
  } catch (e) {
    yield put({
      type: PUT_LOGINLINK_ERROR,
      error: true,
      payload: e
    }); // 실패 액션 디스패치
  }
}

function* putLoginJoinSaga(action) {
  const param = action.param;
  try {
    const loginjoininfo = yield call(loginAPI.putLoginJoin, param); // call 을 사용하면 특정 함수를 호출하고, 결과물이 반환 될 때까지 기다려줄 수 있습니다.
    yield put({
      type: PUT_LOGINJOIN_SUCCESS,
      payload: loginjoininfo
    }); // 성공 액션 디스패치
  } catch (e) {
    yield put({
      type: PUT_LOGINJOIN_ERROR,
      error: true,
      payload: e
    }); // 실패 액션 디스패치
  }
}

function* putLoginLinksiumSaga(action) {
  const param = action.param;
  try {
    const loginjoininfo = yield call(loginAPI.putLoginLinksium, param); // call 을 사용하면 특정 함수를 호출하고, 결과물이 반환 될 때까지 기다려줄 수 있습니다.
    yield put({
      type: PUT_LOGINLINKSIUM_SUCCESS,
      payload: loginjoininfo
    }); // 성공 액션 디스패치
  } catch (e) {
    yield put({
      type: PUT_LOGINLINKSIUM_ERROR,
      error: true,
      payload: e
    }); // 실패 액션 디스패치
  }
}

function* getParticipatedWebinarsSaga(action) {
  console.log('getParticipatedWebinarsSaga');
  const param = action.param;
  try {
    const webinars = yield call(loginAPI.getParticipatedWebinars, param); // call 을 사용하면 특정 함수를 호출하고, 결과물이 반환 될 때까지 기다려줄 수 있습니다.
    yield put({
      type: GET_PARTICIPATEDWEBINARS_SUCCESS,
      payload: webinars
    }); // 성공 액션 디스패치
  } catch (e) {
    yield put({
      type: GET_PARTICIPATEDWEBINARS_ERROR,
      error: true,
      payload: e
    }); // 실패 액션 디스패치
  }
}

function* getLoginShortcutSaga(action) {
  console.log('getLoginShortcutSaga', action.param);
  const param = action.param;
  try {
    const webinars = yield call(loginAPI.getShortcut, param); // call 을 사용하면 특정 함수를 호출하고, 결과물이 반환 될 때까지 기다려줄 수 있습니다.
    yield put({
      type: GET_SHORTCUT_SUCCESS,
      payload: webinars
    }); // 성공 액션 디스패치
  } catch (e) {
    yield put({
      type: GET_SHORTCUT_ERROR,
      error: true,
      payload: e
    }); // 실패 액션 디스패치
  }
}

// 사가들을 합치기
export function* loginSaga() {
  yield takeEvery(GET_WEBINAR, getWebinarSaga);
  yield takeEvery(PUT_LOGIN, putLoginSaga);
  yield takeEvery(PUT_LOGINLINK, putLoginLinkSaga);
  yield takeEvery(PUT_LOGINJOIN, putLoginJoinSaga);
  yield takeEvery(PUT_LOGINLINKSIUM, putLoginLinksiumSaga);
  yield takeEvery(GET_PARTICIPATEDWEBINARS, getParticipatedWebinarsSaga);
  yield takeEvery(GET_SHORTCUT, getLoginShortcutSaga);
}

// 3번째 인자를 사용하면 withExtraArgument 에서 넣어준 값들을 사용 할 수 있습니다.
export const goToChannelHome = () => (dispatch, getState, { history }) => {
  history.push('/channels');
};

// initialState 쪽도 반복되는 코드를 initial() 함수를 사용해서 리팩토링 했습니다.
const initialState = {
  webinar: reducerUtils.initial(),
  shortcut: reducerUtils.initial(),
  logininfo: reducerUtils.initial(),
  participated: reducerUtils.initial(),
  loginusr: {usrtoken:null}
};

export default function login(state = initialState, action) {
  console.log('state!!',state);
  switch (action.type) {
    case GET_WEBINAR:
    case GET_WEBINAR_SUCCESS:
    case GET_WEBINAR_ERROR:
      return handleAsyncActions(GET_WEBINAR, 'webinar', true)(state, action);
    case GET_PARTICIPATEDWEBINARS:
    case GET_PARTICIPATEDWEBINARS_SUCCESS:
    case GET_PARTICIPATEDWEBINARS_ERROR:
      return handleAsyncActions(GET_PARTICIPATEDWEBINARS, 'participated', true)(state, action);
    case PUT_LOGIN:
    case PUT_LOGIN_SUCCESS:
    case PUT_LOGIN_ERROR:
      return handleAsyncActions(PUT_LOGIN, 'logininfo', true)(state, action);
    case PUT_LOGINLINK:
    case PUT_LOGINLINK_SUCCESS:
    case PUT_LOGINLINK_ERROR:
      return handleAsyncActions(PUT_LOGINLINK, 'logininfo', true)(state, action);
    case PUT_LOGINJOIN:
    case PUT_LOGINJOIN_SUCCESS:
    case PUT_LOGINJOIN_ERROR:
      return handleAsyncActions(PUT_LOGINJOIN, 'logininfo', true)(state, action);
    case PUT_LOGINLINKSIUM:
    case PUT_LOGINLINKSIUM_SUCCESS:
    case PUT_LOGINLINKSIUM_ERROR:
      return handleAsyncActions(PUT_LOGINLINKSIUM, 'logininfo', true)(state, action);
    case GET_SHORTCUT:
    case GET_SHORTCUT_SUCCESS:
    case GET_SHORTCUT_ERROR:
      return handleAsyncActions(GET_SHORTCUT, 'shortcut', true)(state, action);
    case SAVE_LOGIN:
      return {...state, logininfo:reducerUtils.initial(), loginusr:action.payload}
    // case DESTROY_SESSION:
    //   state = undefined
    //   return state;


    default:
      return state;
  }
}