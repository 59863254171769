import React from 'react';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';

function NotFound({ match, history }) {
  const { webinaridx } = match.params; // URL 파라미터 조회하기

  const goWebinar = () => {
    window.location.href='/login/'+webinaridx;
  }
  return (
    <div>
      <div className="main-wrap">
        <div className="error-wrap">
          <SentimentDissatisfiedIcon className="material-icons" />
          <h3>방송중이 아닙니다.
          </h3>
          <div>
          {/* <button type="button" href="#!" className="btn-login" style={{'width':'180px'}} onClick={goWebinar}>Go to login page</button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
