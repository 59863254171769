import Popup from 'react-popup';
/*
* 날짜포맷 yyyy-MM-dd 변환
*/
export const getFormatDate = (date) => {
  var year = date.getFullYear();
  var month = (1 + date.getMonth());
  month = month >= 10 ? month : '0' + month;
  var day = date.getDate();
  day = day >= 10 ? day : '0' + day;
  return year + '-' + month + '-' + day;
}

/**
 * null or undefined 체크
 * @param {*} val 
 */
export const isNull = (val) => {
  if (val === null || val === undefined) return true;
  else return false;
}

/**
 * null or undefined 체크
 * @param {*} val 
 */
export const isNotNull = (val) => {
  if (val === null || val === undefined) return false;
  else return true;
}

/**
 * null or undefined or '' 체크
 * @param {*} val 
 */
 export const isNotEmpty = (val) => {
  if (val === null || val === undefined || val === '') return false;
  else return true;
}

export const printValidate = (e, name) => {
  //console.log('printValidate',e)
  if (e) {
    if (e.type == 'required') 
      return <p className="valid">{name} is required</p>
    else if (e.type === 'pattern')
      return <p className="valid">{name} is not valid</p>
    else 
      return <p className="valid">{e.message}</p>
  }
}

export const webinarPluginCode = {
  LIVECHAT_NONE: 0b00000000,
  LIVECHAT_GROUP: 0b00000001,
  LIVECHAT_OPEN: 0b00000010,
  LIKEFUNC: 0b00000100,
  REALTIMEPOLL: 0b00001000,
  POSTSURVEY: 0b00010000,
  NOTICE_POPUP: 0b00100000,
  CALL_RESERVATION: 0b01000000,
  QABOARD: 0b10000000
};

/**
 * 
 * @param {*} item 체크하고싶은 항목 (webinarPluginCode.REALTIMEPOLL)
 * @param {*} code 체크기준이 되는 숫자값 (디비에서 가져온 plugins value)
 */
export const checkWebinarPlugin = (item, code) => {
  if ((item & code) == item) return true;
  return false;
}

/**
 * 초를 시:분:초 로 반환
 * @param {*} seconds 
 */
export const getTimeFromSeconds = (seconds) => {
	var hour, min, sec
	hour = parseInt(seconds/3600);
	min = parseInt((seconds%3600)/60);
	sec = seconds%60;

	if (hour.toString().length==1) hour = "0" + hour;
	if (min.toString().length==1) min = "0" + min;
	if (sec.toString().length==1) sec = "0" + sec;

	return hour + ":" + min + ":" + sec;
}

/**
 * 기본적인 confirm popup
 * @param {*} question 
 * @param {*} canceltxt 
 * @param {*} oktxt 
 * @param {*} okfunc 
 */
export const confirmPopup = (question, canceltxt, oktxt, okfunc) => {
  Popup.create({
    title: null,
    content: question,
    buttons: {
      left: [{
        text: canceltxt,
        className: 'danger',
        action: function () {
          /** Close this popup. Close will always close the current visible one, if one is visible */
          Popup.close();
        }
      }],
      right: [{
        text: oktxt,
        className: 'success',
        action: function () {
          okfunc();
          /** Close this popup. Close will always close the current visible one, if one is visible */
          Popup.close();
        }
      }]
    }
  });
}