import React, { useState } from 'react';
import ReactPlayer from 'react-player';

function WebinarVodTestPage({ match,location }) {

  const ref = React.createRef()
  const [streamUri, setStreamUri] = useState();
  const [muted, setMuted] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [playstate, setPlaystate] = useState({
    'loaded': 0,
    'loadedSeconds': 0,
    'playedSeconds':0,
    'played': 0,
  })

  // play 시작
  const handlePlay = () => {
    setPlaying(true);
  }
  
  // 진행상태를 state 에 업데이트
  const handleProgress = (state) => {
    setPlaystate(state);
  }

  // duration 셋팅
  const handleDuration = (dur) => {
    setDuration(dur);
  }
  const handleOnError = (e) => {
    console.log('handleOnError!',e);
  }

  const handleOnReady = (e) => {
    console.log('eeee',e);
  }

  const handleLogInfo = () => {
    console.log('ref.current',ref.current);
    console.log('ref.current.props',ref.current.player.player.player);
    console.log(document.getElementsByTagName('video').audioTracks);
  }

  return (
    <div>
    <ReactPlayer 
    id="vplayer"
    ref={ref}
    url='https://www.w3schools.com/tags/mov_bbb.mp4'
    width="1100px" height="619px"
    playing={playing}
    controls
    muted={muted}
    onReady={handleOnReady}
    onProgress={handleProgress}
    onDuration={handleDuration}              
    onError={handleOnError}
    // config={{
    //   file: {
    //     forceHLS: true,
    //   }
    // }}
    />
    <button onClick={handleLogInfo}>getinfo</button>
    </div>
  )
}

export default WebinarVodTestPage;
