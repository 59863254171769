import { makeStyles } from '@material-ui/styles';
import React from 'react';
import BodyClassName from 'react-body-classname';
import {
  isMobile
} from "react-device-detect";
import LoginContainer from '../containers/LoginContainer';
import FooterLogin from '../fragments/FooterLogin';
;

function LoginPage({ match,history,bodycss, lcopyright, loginto, isQr }) {
  console.log("loginto...."+loginto)
  const { webinaridx } = match.params; // URL 파라미터 조회하기
  //const [bodycss, setBodycss] = useState(null);
  //console.log("test"+bodycss);

  const useStyles = makeStyles({
    bodybg: {
      background: `${bodycss} !important`
    }
  })

  const classes = useStyles();

  return (
    <BodyClassName className={`${bodycss!==null&&bodycss!==undefined?classes.bodybg:''}`}>
      <>
      {/*<WebinarStyleHead webinaridx={parseInt(webinaridx, 10)} setBodycss={setBodycss} history={history}/>*/}
      <LoginContainer webinaridx={parseInt(webinaridx, 10)} history={history} isMobile={isMobile} isQr={isQr}/>
      <FooterLogin lcopyright={lcopyright}/>
      </>
    </BodyClassName>    
  );
}

export default LoginPage;
