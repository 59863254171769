import React from 'react';
import LoginJoinContainer from '../containers/LoginJoinContainer';
import FooterLogin from '../fragments/FooterLogin';

/**
 * 링크지움
 * @param {} param0 
 * @returns 
 */
function LoginJoinPage({ location }) {

  const params = new URLSearchParams(location.search);
  const enckey = params.get('enckey');
  const webinaridx = params.get('webinaridx');
  return (
    <>
      <LoginJoinContainer 
        enckey={enckey} 
        webinaridx={parseInt(webinaridx, 0)}
        />
      <FooterLogin/>
      </>
  );
}

export default LoginJoinPage;
