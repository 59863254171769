import { Clear } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import Popup from 'react-popup';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isNotNull, isNull } from '../lib/wbUtils';
import WebinarPlayer from '../lib/WebinarPlayer';
import { getLive, postAccess } from '../modules/lives';
import { clearGetSurvey } from '../modules/survey';
import SurveyListPop from './SurveyListPopContainer';

function WebinarVodContainer({webinaridx, liveservermapidx, history, isMobile})  {

  const { data } = useSelector(state => ({
    data:state.modLives.live.data
  }));

  const dispatch = useDispatch();

  const [info,setInfo] = useState();
  const [vodinfo,setVodinfo] = useState();
  const [streamUri, setStreamUri] = useState();
  const [vodcnt, setVodcnt] = useState(1);
  const [muted, setMuted] = useState(true);
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [playstate, setPlaystate] = useState({
    'loaded': 0,
    'loadedSeconds': 0,
    'playedSeconds':0,
    'played': 0,
  })
  const [playerHeight, setPlayerHeight] = useState('100%');
  const [playerWidth, setPlayerWidth] = useState('200px');
  const [useSurvey,setUseSurvey] = useState(false);
  const [surveycnt, setSurveycnt] = useState(0);
  const [showSurveyPopup,setShowSurveyPopup] = useState(false);
  let parenttp = '020';

  useEffect(() => {
    if (isNull(data)) {
      dispatch(getLive(webinaridx,0b00001111))
    } else {
      setInfo(data.map.info);
      data.map.liveservermaps.map((c, index)=>{
        if (c.liveservermapidx == liveservermapidx) {
          setVodcnt(data.map.liveservermaps.length);
          setVodinfo(c);
          setStreamUri(c.streamuri);
          console.log('@@POST_ACCESS!!');
          dispatch(postAccess({webinaridx, liveservermapidx, 'logtp':'010'}));
          setPlaying(true);
          setSurveycnt(c.vodsurveycnt)
          // 설문 사용 여부
          if(c.surveyidx !== null && c.surveyidx !== undefined){
            setUseSurvey(!useSurvey);
          }
        }
      }
      )
    }
    window.addEventListener('beforeunload', unloadfunc)
    return () => {
      unloadfunc();
      window.removeEventListener('beforeunload', unloadfunc)
    }
  },[dispatch, data]);

  const unloadfunc = (event) => {
    console.log('>>unloadfunc');
    if (isNotNull(event)) {
      event.preventDefault();
      event.returnValue = "";
    }
    dispatch(postAccess({webinaridx, liveservermapidx, 'logtp':'020'}));
    return "";
  }

  const toggleShowSurveyPopup = (cnt) => {
    console.log('showSurveyPopup',showSurveyPopup,cnt);
    if (isNotNull(cnt) && cnt > 0) {
      setSurveycnt(cnt);
    }
    setShowSurveyPopup(!showSurveyPopup);
    dispatch(clearGetSurvey());
  }

  // play 시작
  const handlePlay = () => {
    setPlaying(true);
  }
  
  // 진행상태를 state 에 업데이트
  const handleProgress = (state) => {
    setPlaystate(state);
  }

  // duration 셋팅
  const handleDuration = (dur) => {
    setDuration(dur);
  }

  // 플레이어 에러 발생시
  const handleOnError = (e, data) => {
    console.log('handleOnError!',e, data);
  }

  // 플레이종료시
  const handleOnEnded = (e, data) => {
    console.log('handleOnEnded!',e, data);
    if (useSurvey && surveycnt === 0) {
      setShowSurveyPopup(!showSurveyPopup);
    }
  }

  const handleReload = () => {
    window.parent.location.reload();
  }

  const handleCloseLive = () => {
    if (useSurvey && surveycnt === 0) {
      setShowSurveyPopup(!showSurveyPopup);
    } else{
      if (vodcnt > 1) {
        setTimeout(()=>{history.push('/webinars/vodlist/' + webinaridx)},500);
      } else {
        Popup.create({
          title: null,
          content: 'Vod will be closed.',
          buttons: {
            left: [{
              text: 'Cancel',
              className: 'danger',
              action: function () {
                /** Close this popup. Close will always close the current visible one, if one is visible */
                Popup.close();
              }
            }],
            right: [{
              text: 'Ok',
              className: 'success',
              action: function () {
                /** Close this popup. Close will always close the current visible one, if one is visible */
                Popup.close();
                try {
                  if(window.opener){
                    // 팝업으로 열린 창이면
                    var ww = window.open(window.location, '_self'); ww.close();
                  } else {
                    // 팝업창이 아니면 로그인페이지로 이동
                    // 로그아웃 처리.
                    window.sessionStorage.clear();
                    setTimeout(()=>{history.push('/login/' + webinaridx)},500);
                  }
                } catch (e) {
                  alert(e)
                }
              }
            }]
          }
        });
      }
    }
  }

  /**
  * 사운드켜기
  */
  const handleSoundOn = () => {
    setMuted(false);
  }
  
  return (
    <>
      <div className="vod-viewer-wrap">
        <div className="vod-viewer-box">
          <div className="title">
            {isNotNull(info) && (
              <img
                src={
                  isNull(info.webinarlivelogo)
                    ? "/images/img_logo01.png"
                    : info.webinarlivelogo
                }
              />
            )}
            <a href="#" onClick={handleCloseLive}>
              Close <Clear className="material-icons" />
            </a>
            {/**
            <div className="btn-group">
              <a
                href="#"
                className="btn btn-webinar-refresh"
                onClick={handleReload}
              >
                Refresh
                <Refresh className="material-icons" />
              </a>
              <a
                href="#!"
                className="btn btn-webinar-close survey-modal"
                onClick={handleCloseLive}
              >
                Close
                <Clear className="material-icons" />
              </a>
            </div>
             */}
          </div>
          <div className="content-wrap modify-scroll">
            <div className="content">
            {muted == true?
            <div className="sound-wrap"><img src="/images/icon-sound-on-eng01.png" className="m-soundon" onClick={handleSoundOn}/></div>
            :null}

              <WebinarPlayer
                url={streamUri}
                width={isMobile?'100%':'1200px'}
                height={isMobile?'160px':'675px'}
                className="player"
                playing={playing}
                controls
                muted={muted}
                onProgress={handleProgress}
                onDuration={handleDuration}
                onError={handleOnError}
                onEnded={handleOnEnded}
                config={{
                  file: {
                    //forceHLS: true,
                    attributes: {
                      preload: "auto",
                    },
                  },
                }}
              />
              <h3>{isNotNull(vodinfo) ? vodinfo.liveservermapnm : ""}</h3>
              <p>{isNotNull(vodinfo) ? vodinfo.instructor : ""}</p>
            </div>
            {isNotNull(vodinfo) ?<div className="vod-copyright-wrap" dangerouslySetInnerHTML={{__html: vodinfo.copyright}}>
            </div>: ""}
          </div>
        </div>
      </div>
      {showSurveyPopup === true?
        <SurveyListPop
          webinaridx={webinaridx}
          vodidx={vodinfo.liveservermapidx}
          parenttp={parenttp}
          toggleShowSurveyPopup={toggleShowSurveyPopup}
          isMobile={isMobile}
          history={history}
          vodcnt={vodcnt}
        />
      :null}
    </>
  );
}

export default withRouter(WebinarVodContainer);
