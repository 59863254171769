import { makeStyles } from '@material-ui/styles';
import React from 'react';
import BodyClassName from 'react-body-classname';
import { isMobile, useMobileOrientation } from "react-device-detect";
import WebinarLiveContainer from '../containers/WebinarLiveContainer';

function WebinarLivePage({ match,history,bodycss }) {
  const { webinaridx } = match.params; // URL 파라미터 조회하기
  //const [bodycss, setBodycss] = useState(null);
  //const [theme, setTheme] = useState(null);
  //console.log("test"+bodycss);
  
  console.log('useMobileOrientation',useMobileOrientation);
  const { isLandscape } = useMobileOrientation();

  const useStyles = makeStyles({
    bodybg: {
      background: `${bodycss} !important`
    }
  })

  const classes = useStyles();
  // if (isMobile) 
  //   return <WebinarLiveMobileContainer webinaridx={parseInt(webinaridx, 10)} history={history}/>;
  // else 
  return (
   <BodyClassName className={`${bodycss!==null&&bodycss!==undefined?classes.bodybg:''}`}>
    <>
     {/*<WebinarStyleHead webinaridx={parseInt(webinaridx, 10)} setBodycss={setBodycss} history={history} setTheme={setTheme}/>*/}
      <WebinarLiveContainer 
      webinaridx={parseInt(webinaridx, 10)} 
      history={history} isMobile={isMobile} isLandscape={isLandscape}/>
    </>
    </BodyClassName>   
  )
}

export default WebinarLivePage;
