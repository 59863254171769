import { call, put, takeEvery } from 'redux-saga/effects';
import * as presurveyAPI from '../api/presurvey'; // api/presurvey 안의 함수 모두 불러오기
import {
  handleAsyncActions, reducerUtils
} from '../lib/asyncUtils';

/* 액션 타입 */

// 투표 조회하기
const GET_PRESURVEYS = 'GET_PRESURVEYS';                    // 요청 시작
const GET_PRESURVEYS_SUCCESS = 'GET_PRESURVEYS_SUCCESS';    // 요청 성공
const GET_PRESURVEYS_ERROR = 'GET_PRESURVEYS_ERROR';        // 요청 실패

// 투표하기
const PUT_PRESURVEY = 'PUT_PRESURVEY';
const PUT_PRESURVEY_SUCCESS = 'PUT_PRESURVEY_SUCCESS';
const PUT_PRESURVEY_ERROR = 'PUT_PRESURVEY_ERROR';


export const getPresurveys = (webinaridx) => ({ type: GET_PRESURVEYS, param:{webinaridx} });
export const putPresurvey = (presurveyidx, webinaridx) => ({ type: PUT_PRESURVEY, param:{presurveyidx,webinaridx} })

// 궁금해요 목록 조회 사가~
function* getPresurveySaga(action) {
  console.log('@@@@@getPresurveySaga');
  const param = action.param;
  try {
    const presurveys = yield call(presurveyAPI.getPresurveys, param); // call 을 사용하면 특정 함수를 호출하고, 결과물이 반환 될 때까지 기다려줄 수 있습니다.
    yield put({
      type: GET_PRESURVEYS_SUCCESS,
      payload: presurveys
    }); // 성공 액션 디스패치
  } catch (e) {
    yield put({
      type: GET_PRESURVEYS_ERROR,
      error: true,
      payload: e
    }); // 실패 액션 디스패치
  }
}

// 궁금해요 투표 사가~
function* putPresurveySaga(action) {
  const param = action.param;
  try {
    const presurveys = yield call(presurveyAPI.putPresurvey, param); // API 함수에 넣어주고 싶은 인자는 call 함수의 두번째 인자부터 순서대로 넣어주면 됩니다.
    yield put({
      type: PUT_PRESURVEY_SUCCESS,
      payload: presurveys
    });
  } catch (e) {
    yield put({
      type: PUT_PRESURVEY_ERROR,
      error: true,
      payload: e
    });
  }
}

// 사가들을 합치기
export function* presurveySaga() {
  yield takeEvery(GET_PRESURVEYS, getPresurveySaga);
  yield takeEvery(PUT_PRESURVEY, putPresurveySaga);
}

// initialState 쪽도 반복되는 코드를 initial() 함수를 사용해서 리팩토링 했습니다.
const initialState = {
  presurveys: reducerUtils.initial()
};

export default function presurvey(state = initialState, action) {
  switch (action.type) {
    case GET_PRESURVEYS:
    case GET_PRESURVEYS_SUCCESS:
    case GET_PRESURVEYS_ERROR:
      return handleAsyncActions(GET_PRESURVEYS, 'presurveys', true)(state, action);
    case PUT_PRESURVEY:
    case PUT_PRESURVEY_SUCCESS:
    case PUT_PRESURVEY_ERROR:
      return handleAsyncActions(PUT_PRESURVEY, 'presurveys', true)(state, action);
    default:
      return state;
  }
}