import { call, put, takeEvery } from 'redux-saga/effects';
import * as faqAPI from '../api/faq'; // api/ques 안의 함수 모두 불러오기
import {
  handleAsyncActions, reducerUtils
} from '../lib/asyncUtils';

/* 액션 타입 */

// 설문질문 조회하기
const GET_FAQS = 'GET_FAQS';                    // 요청 시작
const GET_FAQS_SUCCESS = 'GET_FAQS_SUCCESS';    // 요청 성공
const GET_FAQS_ERROR = 'GET_FAQS_ERROR';        // 요청 실패

export const getFaqs = (webinaridx) => ({ type: GET_FAQS, param:{webinaridx} });

function* getFaqsSaga(action) {
  const param = action.param;
  try {
    const faqs = yield call(faqAPI.getFaqs, param); // call 을 사용하면 특정 함수를 호출하고, 결과물이 반환 될 때까지 기다려줄 수 있습니다.
    yield put({
      type: GET_FAQS_SUCCESS,
      payload: faqs
    }); // 성공 액션 디스패치
  } catch (e) {
    yield put({
      type: GET_FAQS_ERROR,
      error: true,
      payload: e
    }); // 실패 액션 디스패치
  }
}

// 사가들을 합치기
export function* faqSaga() {
  yield takeEvery(GET_FAQS, getFaqsSaga);
}

// initialState 쪽도 반복되는 코드를 initial() 함수를 사용해서 리팩토링 했습니다.
const initialState = {
  faqs: reducerUtils.initial()
};

export default function faq(state = initialState, action) {
  switch (action.type) {
    case GET_FAQS:
    case GET_FAQS_SUCCESS:
    case GET_FAQS_ERROR:
      return handleAsyncActions(GET_FAQS, 'faqs', true)(state, action);
    default:
      return state;
  }
}