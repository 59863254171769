import PlayerAPI from 'ibm-video-streaming-web-player-api';
import React, { useEffect } from 'react';
import ReactPlayer from 'react-player';
import { isNotNull, isNull } from './wbUtils';

function WebinarPlayer({corp, url, width, height, playing, muted, onProgress, onDuration, onError, onEnded}){

  let ibmViewer = null;
  let timeMillis = new Date().getTime();

  const getIbmViewer = () => {
    if (ibmViewer === null) {
      ibmViewer = PlayerAPI('UstreamIframe');
    }
    return ibmViewer;
  }

  useEffect(() => {
    //console.log('url@@',url);
    if (checkVideoUri(url) == 'ibm') {

      ibmViewer = getIbmViewer();
      ibmViewer.callMethod('play');
      if (isNotNull(onProgress)) {
        ibmViewer.addListener('quality', handleIbmProgress);
      }
      if (isNotNull(onDuration)) {
        ibmViewer.getProperty('duration', function (duration) {
          onDuration(duration);
        });
      }
      if (isNotNull(onError)) {
        ibmViewer.addListener('error', function(event) {
          //TODO 에러 포맷에 대해서는 확인해 봐야 함.
          onError(event);
          // switch (event.name) {
          //     case 'autoplayRejected':
          //         // TODO: display fallback button
          //         console.log(event.message);
          //         break;
          //     // no default
          // }
        });
      }
    }
  },[url])
  useEffect(() => {
    console.log('playing@@',playing);
    if (checkVideoUri(url) == 'ibm') {
      ibmViewer = getIbmViewer();
      if (playing === true) {
        ibmViewer.callMethod('play');
      } else {
        ibmViewer.callMethod('pause');
      }
    } else if (checkVideoUri(url) == 'ant') {
      try {
        const fr = document.getElementById('AntIframe').contentWindow;
        fr.playWebRTCVideo();
      } catch (e) {
        console.log(e);
      }
    }
  },[playing])
  useEffect(() => {
    //console.log('muted@@!',muted);
    if (checkVideoUri(url) == 'ibm') {
      ibmViewer = getIbmViewer();
      console.log('ibmViewer',ibmViewer);
      if (muted === false) {
        ibmViewer.callMethod('volume', 50);
      } else {
        ibmViewer.callMethod('volume', 0);
      }
    } else if (checkVideoUri(url) == 'ant') {
      try {
        const fr = document.getElementById('AntIframe').contentWindow;
        fr.changeVideoMuteStatus();
      } catch (e) {
        console.log(e);
      }
    }
  },[muted])

  const checkVideoUri = (uri) => {
    if (isNotNull(corp) && corp === 'USTREAM') {
      return 'ibm';
    } else if (isNotNull(url) && (url.indexOf('ustream.tv') > 0 || url.indexOf('video.ibm.com') > 0 )) {
      return 'ibm';
    } else if (isNotNull(corp) && corp === 'ANT') {
      return 'ant';
    } else if (isNotNull(url) && (url.indexOf('ant') > 0)) {
      return 'ant';
    } else {
      return 'others';
    }
  }

  /**
   * ibm 인 경우 progress
   * @param {*} state 
   * @param {*} data 
   */
  const handleIbmProgress = (state,data) => {
    console.log('handleProgress!!', state, data);
    ibmViewer.getProperty('duration', function (duration) {
      console.log('duration!!',duration);
    });
    const tm = new Date().getTime() - timeMillis;
    const val = {'playedSeconds': tm, 'played': tm, 'loadedSeconds': 0, 'loaded': 0 }
    onProgress(val);
  }

  /**
   * ibm ustream 의 경우 자동 play 를 위하여. uri 에 autoplay=true 추가
   * @param {*} uri 
   * @returns 
   */
  const checkAutoPlay = (uri) => {
    if (isNull(uri)) return null;
    if (uri.indexOf("?")>0) {
      uri = uri+'&';
    } else {
      uri = uri+'?';
    }
    //return uri+'autoplay=true&volume='+(muted?0:50);
    return uri+'autoplay=true&volume=0';
  }

  const checkAntUri = (uri) => {
    let res = "/antmedia/playant.html?autoplay=true&mute=true";
    let newu = uri.replace("https://","").replace("http://","");
    let arr = newu.split("?");
    let params;
    if (arr.length > 0) {
      newu = arr[0];
      params = arr[1];
    }
    let param = "";
    const queryParams = new URLSearchParams(params);
    const name = queryParams.get('name');
    const id = queryParams.get('id');
    const playOrder = queryParams.get('playOrder');
    console.log('queryParams',name);
    if (isNotNull(name)) res+="&name="+name;
    if (isNotNull(id)) res+="&id="+id;
    if (isNotNull(playOrder)) {res+="&playOrder="+playOrder;} else {res+="&playOrder=webrtc";}
    if (uri.startsWith("https")) res+="&protocol=https"; else res+="&protocol=http";
    res+="&uri="+newu;
    return res;
  }

  return (
    <>
      {
      checkVideoUri(url) === 'ant'?
      <div
        id="Container"
        className="ustreamStyle"
        >
        <iframe
          id="AntIframe"
          className="m-AntIframe"
          src={checkAntUri(url)}
          width={width} height={height}
          autoPlay={playing}
          allowFullScreen={true}
          webkitallowfullscreen="true"
          frameBorder="0"
          referrerPolicy="no-referrer-when-downgrade"
          volume="0"
          playsInline={true}
          muted={muted}
          allow="autoplay"
        >
        </iframe>
      </div>
      :
      checkVideoUri(url) === 'ibm'?
      <div
        id="Container"
        className="ustreamStyle"
        >
        <iframe
          id="UstreamIframe"
          className="m-UstreamIframe"
          src={checkAutoPlay(url)}
          width={width} height={height}
          autoPlay={playing}
          allowFullScreen={true}
          webkitallowfullscreen="true"
          frameBorder="0"
          referrerPolicy="no-referrer-when-downgrade"
          volume="0"
          playsInline={true}
          muted={muted}
          allow="autoplay"
        >
        </iframe>
      </div>
      :
      <ReactPlayer 
          url={url}
          width={width} height={height}
          playing={playing}
          controls
          muted={muted}
          volume={muted==true?0:1}
          playsInline
          onProgress={onProgress}
          onDuration={onDuration}              
          onError={onError}
          onEnded={onEnded}
          config={{
            file: {
              //forceHLS: true,
              attributes: { 
                preload: 'auto' 
              }                 
            }
          }}
          />   
      }

    </>
  );
}

export default WebinarPlayer;