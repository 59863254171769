import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { isNotNull, isNull, printValidate } from '../lib/wbUtils';
import { postPoll } from '../modules/poll';
import BodyClassName from 'react-body-classname';
import PollQRContainer from '../containers/PollQRContainer';

function  PollQRPage({match, info, pollidx, pushedpoll, handleExecuteLivePoll, isMobile}) {

  const { webinaridx } = match.params;

  return (
    <BodyClassName className={`gradient-body ${/*bodycss!==null&&bodycss!==undefined?classes.bodybg:*/''}`}>
      <>
        <PollQRContainer webinaridx={webinaridx}/>
      </>
    </BodyClassName>
  );
}

export default PollQRPage;