import React from "react";
import { printValidate } from '../lib/wbUtils'

function PtcpntAddedItems({ ptcpntitems, handleChangeItem, register, errors }) {

  // console.log("error", new Map(Object.entries(errors)));

  return (
    <>
      {
        ptcpntitems!==null && ptcpntitems!==undefined && ptcpntitems.length>0 &&
        ptcpntitems.map((i, index) => {
          let itemnm, itemarr;
          if(i.itemtp==='020') {
            itemnm = i.itemnm.split('@')[0];
            itemarr = i.itemnm.split('@')[1].split('|');
          }else{
            itemnm = i.itemnm
          }
          return (
            <tr key={`i${i.disporder}`}>
              <td>{i.reqyn==='Y'?'*':''}{itemnm}</td>
              <td>
                {i.itemtp ==='010'
                ?<>
                  <input type="text" onChange={handleChangeItem} 
                  {...register(i.disporder<10?`added.item0${i.disporder}`:`added.item${i.disporder}`,{ required: i.reqyn==='Y'?true:false })}
                  name={i.disporder<10?`added.item0${i.disporder}`:`added.item${i.disporder}`}/>
                </>
                :<>
                  <select name={i.disporder<10?`added.item0${i.disporder}`:`added.item${i.disporder}`}
                   {...register(i.disporder<10?`added.item0${i.disporder}`:`added.item${i.disporder}`,{ required: i.reqyn==='Y'?true:false })}
                  onChange={handleChangeItem}>
                  {itemarr.map((element) => {
                    return (
                    <option value={element}>{element}</option>
                    )
                  })}
                  </select>
                </>}
                 {errors.added!==undefined &&
                 printValidate(new Map(Object.entries(errors.added)).get(i.disporder<10?`item0${i.disporder}`:`item${i.disporder}`), itemnm)}
              </td>
            </tr>
          )
        })
      }
    </>
  );
}

export default PtcpntAddedItems;
