import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { isNotNull, printValidate } from '../lib/wbUtils';
import { postFirstCall } from '../modules/firstcall';

function FirstCallDiv({webinaridx, isMobile, showFirstCallPopup, setShowFirstCallPopup, closeWebinar, setFirstCallReg, isPop}) {

  const dispatch = useDispatch();

  const { data } = useSelector(state => ({
    data:state.modFirstCall.firstcall.data
  }));

  const [showFullText, setShowFullText] = useState(false);
  const [firstCallCenter, setFirstCallCenter] = useState(false);
  const [diseaseError, setDiseaseError] = useState();
  //고객사 요청으로 배경 이미지등을 분기함. 강의요약이 있는 버전과 없는(구형)
  // 강의요약이 있는 버전
  //const todaySummary = {'isTodaySummary':true,'imgpopuptitle':'/images/firstcall/popup_title.png','imgtitle':'/images/firstcall/title.png','imgbg':'/images/firstcall/m2mlink_bg.png'};
  // 강의요약이 없는 버전
  const todaySummary = {'isTodaySummary':false,'imgpopuptitle':'/images/firstcall/title_old.png','imgtitle':'/images/firstcall/title_old.png','imgbg':'/images/firstcall/m2mlink_bg_old.png'};

  useEffect(() => {
    if (isNotNull(data)) {
      if (data.result_cd === 1) {
        alert('M2MLINK 신청완료되었습니다.');
        setShowFirstCallPopup(false);
        setFirstCallCenter(false);
        setFirstCallReg(true);
        if (firstCallCenter) {
          closeWebinar();
        }
      } else {
        toast.error(`M2MLINK 신청에 문제가 발생하였습니다. : ${data.result_msg}`);
      }
    }
  },[data])

  // 전문보기 또는 숨기기
  const showHideFullText = (e) => {
    e.preventDefault();
    setShowFullText(!showFullText);
  }
  // firstcall 등록화면을 센터에 띄움
  const showFirstCallCenter = () => {
    console.log('setShowFirstCallPopup');
    setShowFirstCallPopup(false);
    setFirstCallCenter(true);
  }
  const handleClose = () => {
    closeWebinar();
  }
  const {register, handleSubmit, formState: { errors }, reset, getValues } = useForm({
    defaultValues: {
      company: window.sessionStorage.getItem('company'),
      dept: window.sessionStorage.getItem('dept')==='null'?'':window.sessionStorage.getItem('dept'),
      ptcpntemail: window.sessionStorage.getItem('ptcpntemail')
    }
  });
  const onSubmit = (data,e) => {
    data.webinaridx = webinaridx;
    data.bookingtm = data.bookinghh+data.bookingmm;
    data.interestdscr = data.chk.toString();

    if(getValues("chk").length || isPop) {
      dispatch(postFirstCall(data));
      e.target.reset();
    } else {
      alert("관심 질환을 하나 이상 선택 해주세요.")
    }
  }
  const atLeastOne = () => {
    console.log('chk',getValues("chk").length);
    if (getValues("chk").length === 0) {
      setDiseaseError("관심질환을 하나 이상 선택해주세요.");
    } else {
      setDiseaseError("");
    }
  }
  const pcjsx = (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
      <div className={firstCallCenter?'call-wrap-ctr':'call-wrap'}>
        <div className="call">
          <div className="exit">
            <img
              src="/images/firstcall/btn_exit.png"
              alt=""
              onClick={handleClose}
            />
          </div>
          {isPop ? 
          <h1>
          <img src={`${todaySummary.imgpopuptitle}`} />
        </h1>
          :
          <h1>
            <img src={`${todaySummary.imgtitle}`} />
          </h1>

        }
          <div className="input-wrap">
            <div className="row company">
              <label>병원명</label>
              <input
                type="text"
                name="company"
                className="company"
                placeholder="소속병원을 입력해주세요 ex.비아트리스 병원"
                {...register('company',{ required: true,  maxLength: 100 })}
                // {...register("병원명", {required:true, maxLength:30})}
              />
            {printValidate(errors.company, '병원명')}
            </div>
            <div className="row position">
              <label>진료과</label>
              <input
                type="text"
                name="dept"
                className="position"
                placeholder="진료과목을 입력해주세요 ex.신경과"
                {...register('dept',{ required: true,  maxLength: 100 })}
              />
              {printValidate(errors.dept, '진료과')}
            </div>
            <div className="row email">
              <label>이메일</label>
              <input
                type="text"
                name="ptcpntemail"
                className="email"
                placeholder="이메일을 입력해주세요 ex.abc@abc.com"
                {...register('ptcpntemail',{ required: true,  maxLength: 255,
                  pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}
              />
              {printValidate(errors.email, '이메일')}
            </div>
            <div className="row">
              <label>첫 콜 예약 요일/시간</label>
              <select name="dayofweek" className="call-day" {...register('dayofweek',{ required: true })}>
                <option value="">요일선택</option>
                <option value="MON">월요일</option>
                <option value="TUE">화요일</option>
                <option value="WED">수요일</option>
                <option value="THU">목요일</option>
                <option value="FRI">금요일</option>
              </select>
              {printValidate(errors.dayofweek, '요일')}
              <select name="bookinghh" className="call-hour" {...register('bookinghh',{ required: true })}>
                <option value="">시간선택</option>
                <option value="10">10시</option>
                <option value="11">11시</option>
                <option value="12">12시</option>
                <option value="13">13시</option>
                <option value="14">14시</option>
                <option value="15">15시</option>
                <option value="16">16시</option>
                <option value="17">17시</option>
                <option value="18">18시</option>
              </select>
              {printValidate(errors.bookinghh, '시간')}
              <select name="bookingmm" className="call-min" {...register('bookingmm',{ required: true })}>
                <option value="">분선택</option>
                <option value="00">00분</option>
                <option value="10">10분</option>
                <option value="20">20분</option>
                <option value="30">30분</option>
                <option value="40">40분</option>
                <option value="50">50분</option>
              </select>
              {printValidate(errors.bookingmm, '분')}
              <span className="guide">
                *월~금, 오전10시~오후6시까지 예약 가능합니다.
              </span>
            </div>
            {!isPop ?       
            <div className="row">
              <label>첫 콜 관심 질환 </label>
              <div className="check-wrap">
                <span className="">
                  <label htmlFor="COVID" className="">
                    <span>
                      <input
                        id="COVID"
                        type="checkbox"
                        name="chk"
                        value="COVID-19"
                        {...register('chk',{
                          validate: atLeastOne
                        })}
                      />
                    </span>
                    COVID-19
                  </label>
                </span>
                <span className="">
                  <label htmlFor="HTN" className="">
                    <span>
                      <input id="HTN" type="checkbox" name="chk" value="HTN" {...register('chk',{validate: atLeastOne})}/>
                    </span>
                    HTN
                  </label>
                </span>
                <span className="">
                  <label htmlFor="DM" className="">
                    <span>
                      <input id="DM" type="checkbox" name="chk" value="DM" {...register('chk',{validate: atLeastOne})} />
                    </span>
                    DM
                  </label>
                </span>
                <span className="">
                  <label htmlFor="OARA" className="">
                    <span>
                      <input
                        id="OARA"
                        type="checkbox"
                        name="chk"
                        value="OA/RA"
                        {...register('chk',{validate: atLeastOne})}
                      />
                    </span>
                    OA/RA
                  </label>
                </span>
                <span className="">
                  <label htmlFor="NeP" className="">
                    <span>
                      <input id="NeP" type="checkbox" name="chk" value="NeP" {...register('chk',{validate: atLeastOne})} />
                    </span>
                    NeP
                  </label>
                </span>
                <br />
                <span className="">
                  <label htmlFor="checkup" className="">
                    <span>
                      <input
                        id="checkup"
                        type="checkbox"
                        name="chk"
                        value="국가건강검진/국가예방접종 가이드"
                        {...register('chk',{validate: atLeastOne})}
                      />
                    </span>
                    국가건강검진/국가예방접종 가이드
                  </label>
                </span>
                {todaySummary.isTodaySummary?
                <span className="">
                  <label htmlFor="checkup" className="">
                    <span>
                      <input
                        id="checkup"
                        type="checkbox"
                        name="chk"
                        value="오늘 강의 요약"
                        defaultChecked={true}
                        {...register('chk',{validate: atLeastOne})}
                      />
                    </span>
                    오늘 강의 요약
                  </label>
                </span>
                :null}
                <span className="guide">(중복선택 가능)</span>
                <p className='valid'>{diseaseError}</p>
              </div>
            </div>
              : 
              <>
                {todaySummary.isTodaySummary?
                <label style={{display: "none"}}>
                    <span>
                      <input
                        id="checkup"
                        type="checkbox"
                        name="chk"
                        value="오늘 강의 요약"
                        checked={true}
                        {...register('chk',{validate: atLeastOne})}
                      />
                    </span>
                    오늘 강의 요약
                </label>
                :null}
              </>  
          }
          </div>
          <input
            type="checkbox"
            id="agree"
            className="call-agree"
            name="agree"
            value="Y"
            {...register('agree',{ required: true })}
          />
          <label htmlFor="agree">개인정보동의</label>
          <button id="btn-popup2" onClick={showHideFullText}>
            {showFullText === true ? "전문보기▲" : "전문보기▼"}
          </button>
          {printValidate(errors.agree, '개인정보동의')}
          <div className={showFullText === true ? "cont active" : "cont"}>
            <p>
            비아트리스코리아 주식회사(이하 “비아트리스”)가 제공하는 의료전문가를 위한 서비스를 
            제공받기 위해서는 아래의 동의서를 읽어 보시고 동의하여 주시기 바랍니다.
            </p>
            <br />
            <h3>[개인정보 수집 및 이용 동의]</h3>
            <table>
              <tbody>
                <tr>
                  <th>수집항목</th>
                  <th>이용목적</th>
                  <th>보유기간</th>
                </tr>
                <tr>
                  <td>
                    성명, 휴대폰번호, 이메일주소, 병원명, 진료과, 병원주소
                  </td>
                  <td>
                    엠투엠링크를 통한 1:1 화상디테일링 서비스 제공, 예약 안내 및 민원 처리, 관련 증빙
                  </td>
                  <td>
                    관계 법령의 규정에 따라 귀하의 개인정보를 보존할 의무가 있는 경우가 아닌 한, 
                    화상디테일링 서비스 제공 완료 시까지
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              귀하는 언제든지 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.<br/>
              귀하는 동의를 거부할 수 있으며 동의 거부 시 비아트리스의 엠투엠링크 서비스를 제공받을 수 없습니다.
            </p>
            {/* 2023-03-27 요청에 의해 변경
            <p>
              비아트리스 코리아 주식회사(이하 “비아트리스” 또는 ”회사”)는
              보건의료전문가를 대상으로 메디닥링크, 엠투엠링크, 링크지움,
              비아링크 서비스를 제공하고 있습니다. 메디닥링크, 엠투엠링크,
              링크지움, 비아링크 회원께는 회사가 제공하는 다양한 제품 및
              서비스에 대한안내, 의∙약학적 정보가 제공됩니다.
            </p>
            <br />
            <h3>1. 개인정보의 수집 및 이용에 관한 동의 </h3>
            <table>
              <tbody>
                <tr>
                  <th>수집항목</th>
                  <th>수집 및 이용 목적</th>
                  <th>이용 및 보유기간</th>
                </tr>
                <tr>
                  <td>
                    <li>
                      ● 성명, 직종, 병원명, 병원주소, 진료과목, 전화번호(근무지,
                      휴대폰), 전자우편주소
                    </li>
                    <li>
                      ● ­웹사이트 이용 내역(로그인 시간, 웹사이트 내 방문 페이지
                      및 각 페이지별 체류 시간, 웹사이트로의 유입 채널,
                      웹사이트에서 귀하가 선택 및 열람하신 주제 및 컨텐츠,
                      검색어 등 귀하의 웹사이트 상 활동 및 웹사이트 이용 내역),
                      (자동수집항목) 접속로그, 접속 IP 정보, 마지막 로그인 일시
                    </li>
                  </td>
                  <td>
                    웹사이트 회원가입 및 관리, 회원제 서비스 이용에 따른 본인
                    여부 및 가입자격 확인, 중복가입∙부정이용∙비인가사용 등의
                    방지, 웹사이트를 통한 서비스 제공, 웹사이트 이용에 따른
                    민원사항의 처리 및 고지사항의 전달, 문의, 민원 및 답변 처리,
                    분쟁 대응, 제품에 대한 불만처리 기록 보관 및 부작용 보고,
                    회원의 서비스 이용현황 분석, 웹사이트 개선 및 사이트 사용
                    추세 파악
                  </td>
                  <td>
                    관계 법령의 규정에 따라 귀하의 개인정보를 보존할 의무가 있는
                    경우가 아닌 한, 회원탈퇴시까지
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              귀하는 위와 같은 개인정보의 수집 및 이용에 대한 동의를 거부할 수
              있으나, 필수적 수집 및 이용에 동의하지 않을 경우 회원가입이
              불가능합니다.
            </p>
            <p>
              또한 회사는 다양한 채널의 운영을 위해 개인정보 처리업무를 다음과
              같이 위탁하고 있습니다
            </p>
            <table>
              <tbody>
                <tr>
                  <th>수탁자</th>
                  <th>위탁하는 업무의 내용</th>
                </tr>
                <tr>
                  <td>Marketo (Adobe Company)</td>
                  <td>이메일 발송 시스템 개발</td>
                </tr>
                <tr>
                  <td>제일약품 주식회사</td>
                  <td>다양한 채널의 안내 및 그에 따른 개인정보 처리업무</td>
                </tr>
                <tr>
                  <td>주식회사 카카오</td>
                  <td>
                    카카오 플러스친구 서비스 제공 목적의 개인정보 처리업무
                  </td>
                </tr>
              </tbody>
            </table>
            */}
          </div>

          <div className="btn-area">
              <input type="image" src="/images/firstcall/btn-call.png" />
          </div>
        </div>
      </div>
      </form>
      <div
        className="survey-parent"
        style={{ display: showFirstCallPopup === true ? "flex" : "none" }}
      >
        <div
          className="survey end"
          style={{ listStyle: "none",zIndex:'99999999' }}
          aria-hidden="true"
        >

          <div className="survey-wrap" style={{border:'unset'}}>

          <div className="exit-wait">
            <img
              src="/images/firstcall/btn_exit.png"
              alt=""
              onClick={handleClose}
            />
          </div>

            <div className="p-con mCustomScrollbar _mCS_3 mCS-autoHide mCS_no_scrollbar">
              <div
                id="mCSB_3"
                className="mCustomScrollBox mCS-dark-thin mCSB_vertical mCSB_inside"
                style={{ maxHeight: "none" }}
                tabIndex="0"
              >
                <div
                  id="mCSB_3_container"
                  className="mCSB_container mCS_y_hidden mCS_no_scrollbar_y"
                  style={{ position: "relative", left: "0px", top: "0px" }}
                  dir="ltr"
                >
                  <img
                    src={`${todaySummary.imgbg}`}
                    style={{ width: "100%" }}
                    className="mCS_img_loaded"
                  />
                  <div className="apply-wrap">
                    <a
                      id="btn-popup1"
                      className="btn-apply"
                      onClick={showFirstCallCenter}
                    >
                      <img
                        src="/images/firstcall/btn_apply.png"
                        className="mCS_img_loaded"
                      />
                    </a>
                  </div>
                  <a
                    className="btn-pdf"
                    href="/images/firstcall/m2m_pdf.pdf"
                    target="(name)"
                  >
                    <img
                      src="/images/firstcall/btn_pdf.png"
                      className="mCS_img_loaded"
                    />
                  </a>
                  <a
                    className="btn-m2mlink"
                    href="https://www.linktoviatris.co.kr/m2mlink"
                    target="(name)"
                  >
                    <img
                      src="/images/firstcall/to_m2mlink.png"
                      className="mCS_img_loaded"
                    />
                  </a>
                </div>
                <div
                  id="mCSB_3_scrollbar_vertical"
                  className="mCSB_scrollTools mCSB_3_scrollbar mCS-dark-thin mCSB_scrollTools_vertical"
                  style={{ display: "none" }}
                >
                  <div className="mCSB_draggerContainer">
                    <div
                      id="mCSB_3_dragger_vertical"
                      className="mCSB_dragger"
                      style={{
                        position: "absolute",
                        minHeight: "30px",
                        height: "0px",
                        top: "0px",
                      }}
                    >
                      <div
                        className="mCSB_dragger_bar"
                        style={{ lineHeight: "30px" }}
                      ></div>
                    </div>
                    <div className="mCSB_draggerRail"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  const mobilejsx = (
    <div className="m-chat-wrap">
    </div>
  );  
  //return isMobile ? mobilejsx : pcjsx;
  return pcjsx;
}

export default FirstCallDiv;