import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { putLoginLink, saveLogin } from '../modules/login';

/**
 * 회원가입이 이미 되어있다는 가정 하에, 특정 웨비나로 자동 이동할때 사용함(이브아르,갈더마등 부스에서 웨비나 이동시)
 * @param {*} param0 
 * @returns 
 */
function LoginLinkContainer({enckey, webinaridx, channelidx})  {

  const { data, loading, error } = useSelector(state => (state.modLogin.logininfo))
  let history = useHistory();

  const dispatch = useDispatch();
  const defLogin = {'loginid':'','loginpwd':''};
  const [login, setLogin] = useState(defLogin);
  const [saveid, setSaveid] = useState(false);
  let cnt = 0;

  // 로그인처리
  const handleLoginLinkProc = (fdata) => {
    if (cnt === 0) {
      dispatch(putLoginLink(fdata.enckey, fdata.channelidx));
    }
    cnt++;
  }

  useEffect(() => {
    console.log('useEffect',data, enckey, webinaridx, channelidx);

    if (error) {
      toast.error(`Login fail : ${error.message}`);
      return;
    }

    // ...
    //console.log('getDerivedStateFromProps',nextProps,prevState);
    //console.log('logininfo1',prevState.modLogin.logininfo);
    //console.log('logininfo2',nextProps.modLogin.logininfo);
    if (data !== null && data !== undefined ) {
      if (data.result_cd===1) {
        console.log('login success!!',data.map);
        const {ptcpntemail, ptcpntidx, ptcpntnm, ptcpnthp, company, dept, usrtoken} = data.map;
        window.sessionStorage.setItem('ptcpntemail', ptcpntemail);
        window.sessionStorage.setItem('ptcpntidx', ptcpntidx);
        window.sessionStorage.setItem('ptcpntnm', ptcpntnm);
        window.sessionStorage.setItem('ptcpnthp', ptcpnthp);
        window.sessionStorage.setItem('usrtoken', usrtoken);
        window.sessionStorage.setItem('company', company);
        window.sessionStorage.setItem('dept', dept);
        // 로그인정보 저장 및 로그인폼정보 초기화
        dispatch(saveLogin(ptcpntemail,ptcpntidx,ptcpntnm,usrtoken))
        setLogin(defLogin)
        // 어디론가 이동...
        console.log('move~~~!!');
        setTimeout(function(){history.push('/webinars/live/'+webinaridx)},500);
      } else {
        toast.error(`Login fail : ${data.result_msg}`);
        console.log('login fail!!', data.result_msg);
      }
    } else {
      handleLoginLinkProc({enckey, channelidx});
    }
  },[dispatch, data, error])

  return (
    <div>
    </div>
  );
}

export default withRouter(LoginLinkContainer);
