import React from 'react';
import LoginLinkContainer from '../containers/LoginLinkContainer';
import FooterLogin from '../fragments/FooterLogin';

/**
 * 회원가입이 이미 되어있다는 가정 하에, 특정 웨비나로 자동 이동할때 사용함(이브아르,갈더마등 부스에서 웨비나 이동시)
 * @param {*} param0 
 * @returns 
 */
function LoginLinkPage({ location }) {

  const params = new URLSearchParams(location.search);
  const webinaridx = parseInt(params.get('webinaridx'));
  const channelidx = parseInt(params.get('channelidx'));
  const enckey = params.get('enckey');
  console.log('params::',enckey);
  return (
      <>
      <LoginLinkContainer 
        enckey={enckey} 
        webinaridx={webinaridx} 
        channelidx={channelidx}
        />
      <FooterLogin/>
      </>
  );
}

export default LoginLinkPage;
