import { Clear } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import Popup from 'react-popup';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isNotNull, isNull } from '../lib/wbUtils';
import { getLive } from '../modules/lives';

function WebinarVodListContainer({webinaridx, history, isMobile})  {

  const { data } = useSelector(state => ({
    data:state.modLives.live.data
  }));

  const dispatch = useDispatch();

  const [info,setInfo] = useState();
  const [liveservers,setLiveservers] = useState([]);

  useEffect(() => {

    // 컴포넌트 마운트 후 포스트 목록 요청
    if (data === null || data === undefined) {
      dispatch(getLive(webinaridx,0b00001111))
    } else {
      if (data.result_cd !== 1) {
        // 에러인 경우
        toast.error(`Error : ${data.result_msg}`);
        if (data.result_msg === 'Unauthorized') {
          //TODO 로그인 페이지로 이동.
          history.push('/login/'+webinaridx);
        } else {
          // 어디론가 이동시켜야 함.
        }
      } else if (isNotNull(data.map)) {
        // 데이타를 가져오고 난 후
        if (isNotNull(data.map.info)) {
          setInfo(data.map.info);
        }
        // vod 주소 셋팅
        if (isNotNull(data.map.liveservermaps)) {
          if (data.map.liveservermaps.length === 1) {
            // vod가 1개이면 vod로 이동시킴
            setTimeout(function(){history.push('/webinars/vod/'+webinaridx+'/'+data.map.liveservermaps[0].liveservermapidx)},500);
          } else {
            setLiveservers(data.map.liveservermaps);
          }
        }
      }
    }
  },[dispatch, data]);

  /**
   * VOD를 클릭했을때 이동
   */
  const handleGoVod = (e) => {
    const midx = e.currentTarget.getAttribute('liveservermapidx');
    setTimeout(function(){history.push('/webinars/vod/'+webinaridx+'/'+midx)},500);
    return false;
  }

  const handleLogout = () => {
    Popup.create({
      title: null,
      content: 'Webinar will be closed.',
      buttons: {
        left: [{
          text: 'Cancel',
          className: 'danger',
          action: function () {
            /** Close this popup. Close will always close the current visible one, if one is visible */
            Popup.close();
          }
        }],
        right: [{
          text: 'Ok',
          className: 'success',
          action: function () {
            /** Close this popup. Close will always close the current visible one, if one is visible */
            Popup.close();
            try {
              if(window.opener){
                // 팝업으로 열린 창이면
                var ww = window.open(window.location, '_self'); ww.close();
              } else {
                // 팝업창이 아니면 로그인페이지로 이동
                // 로그아웃 처리.
                window.sessionStorage.clear();
                setTimeout(()=>{history.push('/login/' + webinaridx)},500);
              }
            } catch (e) {
              alert(e)
            }
          }
        }]
      }
    });    
  }

  return (
    <>
      <div className="vod-list-wrap">
        <div className="vod-list-box">
          <div className="title">
            {isNotNull(info) && (
              <img
                src={
                  isNull(info.webinarlogo)
                    ? "/images/img_logo01.png"
                    : info.webinarlogo
                }
              />
            )}
            <a href="#" onClick={handleLogout}>
            Close <Clear className="material-icons" />
            </a>
          </div>
          <div className="content-wrap modify-scroll">
            <div className="content">
              <div className="title">
                <h2>VOD List</h2>
                <p>Total : {liveservers.length}</p>
              </div>
              <ul className="vod-list">
                {isNotNull(liveservers) &&
                  liveservers.map((c, index) => {
                    return (
                      <li key={index}>
                        <a
                          href="#"
                          onClick={handleGoVod}
                          liveservermapidx={c.liveservermapidx}
                        >
                          <img src={isNotNull(c.banneruri)?c.banneruri:'https://via.placeholder.com/210x120.png?text=vod'}/>
                        </a>
                        <h4>{c.liveservermapnm}</h4>
                        <p>
                          {c.sttm}~{c.entm}
                        </p>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(WebinarVodListContainer);
