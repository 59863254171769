import { ChromeReaderMode, Clear, Favorite, Feedback, Home, InsertChart, Menu, Refresh } from '@material-ui/icons';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import React, { useEffect, useRef, useState } from 'react';
import { findDOMNode } from 'react-dom';
import Popup from 'react-popup';
import { useDispatch, useSelector } from 'react-redux';
import Script from 'react-script-tag';
import { toast } from 'react-toastify';
import screenfull from 'screenfull';
import FirstCall from '../components/FirstCallDiv';
import LiveChatDiv from '../components/LiveChatDiv';
import LivePollDiv from '../components/LivePollDiv';
import NoticePop from '../components/NoticePop';
import TutorialPop from '../components/TutorialPop';
import { checkWebinarPlugin, isNotNull, isNull, webinarPluginCode } from '../lib/wbUtils';
import WebinarPlayer from '../lib/WebinarPlayer';
import { getLive, postLike, postLiveQna, saveOnAirSts } from '../modules/lives';
import { destroySession } from '../modules/login';
import FaqListPop from './FaqListPopContainer';
import PollListPop from './PollListPopContainer';
import SurveyListPop from './SurveyListPopContainer';

function WebinarLiveContainer({webinaridx, history, isMobile, isLandscape}) {

  const { data, loading, error, polls, likes, webinar } = useSelector(state => ({
    data:state.modLives.live.data
    ,loading:state.modLives.live.loading
    ,error:state.modLives.live.error
    ,polls:state.modPoll.polls
    ,likes:state.modPoll.likes
    ,webinar:state.modLogin.webinar.data
  }));

  const dispatch = useDispatch();

  const [showFaqPopup,setShowFaqPopup] = useState(false);
  const [showPollPopup,setShowPollPopup] = useState(false);
  const [showTutorialPopup,setShowTutorialPopup] = useState(false);
  const [showNoticePopup,setShowNoticePopup] = useState(false);
  const [showSurveyPopup,setShowSurveyPopup] = useState(false);
  const [showFirstCallPopup,setShowFirstCallPopup] = useState(false);
  const [firstCallReg,setFirstCallReg] = useState(false);
  const [hasBro,setHasBro] = useState(false);
  const [usePoll,setUsePoll] = useState(false);
  const [useSurvey,setUseSurvey] = useState(false);
  const [useFirstCall,setUseFirstCall] = useState(false);
  const [useLike,setUseLike] = useState(false);
  const [likeIcon,setLikeIcon] = useState('010');
  const [qnas,setQnas] = useState([]);
  const [info,setInfo] = useState();
  const [bannerImg, setBannerImg] = useState();
  const [bannerUri, setBannerUri] = useState();
  const [execPollidx, setExecPollidx] = useState(-1);
  const [execPoll, setExecPoll] = useState();
  const [likecnt, setLikecnt] = useState(0);
  const [surveycnt, setSurveycnt] = useState(0);
  const [mobilePlayerHeight, setMobilePlayerHeight] = useState('200px');
  const [webinartopbtnimg, setWebinartopbtnimg] = useState();
  const [webinarlivelogo, setWebinarlivelogo] = useState();
  const [livebronm, setLivebronm] = useState('Brochure');
  const [copyright, setCopyright] = useState();
  const [copyrightm, setCopyrightm] = useState();
  const [afterSurveyClose, setAfterSurveyClose] = useState(true);

  const [liveservers,setLiveservers] = useState([]);

  const refplayerbox = React.createRef()
  const [streamUri, setStreamUri] = useState();
  const [corp, setCorp] = useState();
  const [selStream, setSelStream] = useState({'liveservermapidx':0});
  const [muted, setMuted] = useState(true);
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [playstate, setPlaystate] = useState({
    'loaded': 0,
    'loadedSeconds': 0,
    'playedSeconds':0,
    'played': 0,
  })
  const [menuOpen, setMenuOpen] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [pushedPresurvey, setPushedPresurvey] = useState();  // push된 궁금해요(QABoard)
  const [newChatMessage, setNewChatMessage] = useState(false);
  const [isPop, setIsPop] = useState(false);
  //survey parent type
  let parenttp = '010';

  // const [playstate, setPlaystate] = useState({
  //   url: null,
  //   pip: false,
  //   playing: true,
  //   controls: false,
  //   light: false,
  //   volume: 0.8,
  //   muted: false,
  //   played: 0,
  //   loaded: 0,
  //   duration: 0,
  //   playbackRate: 1.0,
  //   loop: false    
  // });

  // FAQ 팝업
  const toggleShowFaqPopup = () => {
    setShowFaqPopup(!showFaqPopup);
  }

  // 투표 팝업
  const toggleShowPollPopup = () => {
    setShowPollPopup(!showPollPopup);
  }

  // 튜토리얼 팝업
  const toggleShowTutorialPopup = () => {
    setShowTutorialPopup(!showTutorialPopup);
  }

  // 웨비나설명(공지) 팝업
  const toggleShowNoticePopup = () => {
    setShowNoticePopup(!showNoticePopup);
  }

  // 설문
  const toggleShowSurveyPopup = (cnt) => {
    console.log('showSurveyPopup',showSurveyPopup,cnt);
    if (isNotNull(cnt) && cnt > 0) {
      setSurveycnt(cnt);
    }
    setShowSurveyPopup(!showSurveyPopup);
  }
  
  // 닫기
  const handleCloseLive = () => {
    console.log('postsurvey',useSurvey)
    if (isMobile) setMenuOpen(false);
    console.log('useFirstCall',useFirstCall);
    if (useFirstCall && !firstCallReg) {
      // firstcall
      setShowFirstCallPopup(true);
      setIsPop(!isPop);
    } else if (useSurvey && surveycnt === 0) {
      // 설문
      setShowSurveyPopup(!showSurveyPopup);
    } else {
      closeWebinar();
    }
  }

  const closeWebinar = () => {
    Popup.create({
      title: null,
      content: 'Webinar will be closed.',
      buttons: {
        left: [{
          text: 'Cancel',
          className: 'danger',
          action: function () {
            /** Close this popup. Close will always close the current visible one, if one is visible */
            Popup.close();
          }
        }],
        right: [{
          text: 'Ok',
          className: 'success',
          action: function () {
            /** Close this popup. Close will always close the current visible one, if one is visible */
            Popup.close();
            try {
              dispatch(destroySession());
              if(window.opener){
                // 팝업으로 열린 창이면
                var ww = window.open(window.location, '_self'); ww.close();
              } else {
                // 팝업창이 아니면 로그인페이지로 이동
                // 로그아웃 처리.
                window.sessionStorage.clear();
                console.log('>>>>',getDomainName(window.location.hostname));
                console.log('>>>>',`${process.env.REACT_APP_DOMAIN}`);
                if (getDomainName(window.location.hostname) === `${process.env.REACT_APP_DOMAIN}`
                    || getDomainName(window.location.hostname) === null) {
                  // 오리지날 운영 도메인이면 
                  //setTimeout(()=>{history.push('/login/' + webinaridx)},500);
                  history.push('/login/' + webinaridx);
                } else {
                  // 개별 사이트의 도메인일 경우
                  document.location.href='/';
                }
              }
            } catch (e) {
              alert(e)
            }
          }
        }]
      }
    });
  }

  function getDomainName(hostName) {
    var a = hostName.match(/^(?:.*?\.)?([a-zA-Z0-9\-_]{3,}\.(?:\w{2,8}|\w{2,4}\.\w{2,4}))$/);
    if (a == null) {
      return null;
    } else {
      return a[1];
    }
  }

  const handleReload = () => {
    window.parent.location.reload();
  }

  const handleLobby = () => {
    if (isNotNull(info) && info.boothyn === 'Y' && isNotNull(info.lobbyuri) && info.lobbyuri !== '') {
      window.parent.location.href = info.lobbyuri;
    }
  }

  const handleTopbtn = () => {
    if (isNotNull(info) && isNotNull(info.topbtnuri) && info.topbtnuri !== '') {
      window.parent.location.href = info.topbtnuri;
    }
  }


  // 브로셔 다운로드
  const handleDownBro = () => {
    window.open(data.map.info.webinarbro[0].fileuri);
    //dispatch(downloadFile(data.map.info.webinarbro[0].fileidx));
  }

  // QNA 글작성
  const handleWriteQna = (params) => {
    dispatch(postLiveQna(params));
  }

  // 푸시서버 접속
  const joinPush = () => {
    window.joinRoom("" + webinaridx
      , "ptcpntidx_" + window.sessionStorage.getItem('ptcpntidx')
      , window.sessionStorage.getItem('ptcpntnm')
      , (tp) => {
      switch (tp.commandId) {
        case "newmessage":
          // 채팅에 새로운 메시지가 들어왔을때. (true 만 보내면 변화를 인지 못하기 때문에.. 두번보냄)
          setNewChatMessage(true)
          setNewChatMessage(false)
          break;
        case "presurveyvote":
          // 궁금해요에 투표가 발생했을때.
          setPushedPresurvey(tp)
          break;
        case "presurveyadded":
          // 관리자에서 궁금해요를 추가했을때
          setPushedPresurvey(tp)
          break;
        case "history":
          setChatHistory(tp.data);
          break;
        case "start_broadcast":
          // 방송 시작 수신    	  
          /*
          {
              "commandId": "start_broadcast",
              "liveservers": {...},
          }
          */
          //setInfo({...info,'onairsts':'020'});
          dispatch(saveOnAirSts('020'));
          setLiveStream(JSON.parse(tp.liveservers), selStream.liveservermapidx, "020");
          break;
        case "switch_broadcast":
          // 방송 서버 교체
          /*
          {
              "commandId": "switch_broadcast",
              "liveservers": {...},
              "selectedidx": 1
          }
          */
          //setInfo(...info, {'onairsts':'020'});
          dispatch(saveOnAirSts('020'));
          setLiveStream(JSON.parse(tp.liveservers), tp.selectedidx, "020");
          break;
        case "stop_broadcast":
          //TODO 방송 중지 수신   	  
          /*
          {
              "commandId": "stop_broadcast",
              "stopImage": "https://www.bbb.com/aaaa.gif"
          }
           */
          dispatch(saveOnAirSts('010'));
          letAllUsersOut();
          break;
        case "send_poll":
          //TODO 실시간 투표 수신    	  
          handleExecuteLivePoll(tp);
          /*
          {
          "commandId": "send_poll",
          "pollIdx": 3,
          "ques": "다음 중 가장 유용했던 정보는?",
          "listItem": [
          {
            pollItemIdx : 1,
            item : "A세션정보"   
          },
          {
            pollItemIdx : 2,
            item : "B세션정보"   
          },
          {
            pollItemIdx : 3,
            item : "C세션정보"   
          },
          {
            pollItemIdx : 4,
            item : "D세션정보"   
          }
          ]
          }
          */
          break;
        case "send_postsurvey":
          console.log('send_postsurvey!!!!',useSurvey,surveycnt);
          setPushedPostSurvey();
          break;
        case "send_banner":
          // 배너 정보 수신  
          setBanner(tp.fileUri, tp.uri);
          /*
          {
          "commandId": "send_banner",
          "bannerIdx": 3,
          "fileUri": "https://www.bbb.com/aaa/bbb.png",
          "uri": "https://www.naver.com",
          }
          */
          break;
        case "out_allusers":
          // 웨비나종료로 모든 사용자 out
          letAllUsersOut();
          break;
        }
    });
  }

  // push 된 postsurvey 처리
  const setPushedPostSurvey = () => {
    console.log('setPushedPostSurvey',useSurvey)
    //if (useSurvey && surveycnt === 0) {
      // 푸시로 열린 경우에는 설문후 로그아웃 하지 않고 창만 닫아준다.
      setAfterSurveyClose(false);
      setShowSurveyPopup(!showSurveyPopup);
    //}
  }
  
  // 배너를 클릭했을때 이동
  const moveToBanner = () => {
    console.log('bannerUri',bannerUri);
    if (isNotNull(bannerUri) && bannerUri !== '') {
      window.open(bannerUri, '_blank');
    }
  }

  // 배너를 셋팅
  const setBanner = (imguri, linkuri) => {
    setBannerImg(imguri);
    setBannerUri(linkuri);
  }

  // 라이브폴 실행
  const handleExecuteLivePoll = (poll) => {
    if (isNull(poll)) {
      setExecPoll(null);
      setExecPollidx(-1);
    } else {
      setExecPoll(poll);
      setExecPollidx(poll.pollidx);
      toggleFullScreen();
    }
  }

  // 좋아요 클릭
  const handleClickLike = () => {
    //TODO 진행시간을 가져와 셋팅해야 함.
    const param = {'webinaridx':webinaridx,'duration':playstate.playedSeconds};
    dispatch(postLike(param));
    if (likeIcon === '020') {
      initBookmark();
    } else {
      initHeart();
    }
  }

  // 모든사용자를 내보내는 메시지 수신(웨비나종료)
  const letAllUsersOut = () => {
    // 채팅서버와의 연결 종료.
    window.disconnect();
    // 로그아웃 처리.
    window.sessionStorage.clear();
    //dispatch(destroySession());
    // 방송중이 아닙니다 화면으로 보냄
    setTimeout(()=>{history.push('/notonair/'+webinaridx)},1000);
  }

  // 좋아요 애니메이션 처리.
  var heartNum = 1;
  function setHeartAni(){
    if(heartNum < 6){
        var clsnm = '';
        var obj = document.getElementsByClassName('webinar-wrap')[0];
        if (isMobile) {
          clsnm = 'm';
          obj = document.getElementsByClassName('m-content-wrap')[0];
        }
        //var div = $('<div className=material-icons>favorite</div>');
        var div = document.createElement('div');
        var divtxt = document.createTextNode('favorite');
        div.className = 'material-icons favorite';
        div.appendChild(divtxt);
        if( Math.ceil( Math.random()*2 ) == 1){
          if (isMobile) {
            if (isLandscape) {
              div.classList.remove('ani2','m-land');
              div.classList.add('ani1','m-land')
            } else {
              div.classList.remove('ani2','m');
              div.classList.add('ani1','m')
            }
          } else {
            div.classList.remove('ani2')
            div.classList.add('ani1')
          }
        } else {
          if (isMobile) {
            if (isLandscape) {
              div.classList.remove('ani1','m-land')
              div.classList.add('ani2','m-land')
            } else {
              div.classList.remove('ani1','m')
              div.classList.add('ani2','m')
            }
          } else {
            div.classList.remove('ani1')
            div.classList.add('ani2')
          }
        }
        if (heartNum < 2) {
          // 첫번째 하트는 favorite 이라고 글씨로 보이는 경우가 있어서... 
          div.style.visibility = 'hidden';
        } else {
          div.style.visibility = 'vidible';
        }
        obj.appendChild(div);
        //$('.container').append(div);
        var t = setTimeout(function(){ setHeartAni() }, 400);
        heartNum++;
      }
  }

  // 좋아요 클릭시 동작
  function initHeart(){
    //var obj = document.getElementsByClassName('.vod-wrap');
    //obj.parentNode.removeChild(obj);

      //$(".container *").remove();
      //eartTotal = 4;
      heartNum = 1;
      setHeartAni();
  }
  // 좋아요 클릭시 동작 (북마크 디자인인 경우)
  const initBookmark = () => {
    const obj = document.getElementsByClassName('btn-like')[0];
    const mobj = document.getElementsByClassName('m-btn-like')[0];
    if (isNotNull(obj)) {
      obj.classList.add('btn-like-noani');
      obj.classList.remove('btn-like');
      obj.childNodes[0].classList.add('stamp1');
      setTimeout(()=>{
        obj.childNodes[0].classList.remove('stamp1');
        obj.classList.add('btn-like');
        obj.classList.remove('btn-like-noani');
      },500);
    } else {
      mobj.childNodes[0].classList.add('stamp1');
      setTimeout(()=>{
        mobj.childNodes[0].classList.remove('stamp1');
      },500);
    }
  }

  // 라이브서버 셋팅 및 stream uri 선택
  const setLiveStream = (servers, selectedidx, onairsts) => {
    // console.log('streamUri.servers',servers);
    // console.log('streamUri.selectedidx',selectedidx);
    // 일반적으로 서버는 하나다. 두가지 언어 등으로 송출할때 두개이상일 수 있다. 
    setLiveservers(servers);

    let suri = '';
    let corp = '';
    servers.map((c, index) => {
      if (selectedidx === 0) selectedidx = c.liveservermapidx;
      if (parseInt(c.liveservermapidx) === parseInt(selectedidx)) {
        // 선택된 스트림, 없으면 기본적으로 0인거
        setSelStream(c);
        if (c.selsts === '010') {
          // default
          if (onairsts === '020') {
            // 동작중이면
            suri = c.streamuri;  
            corp = c.corp;
            setPlaying(true);
          }  
        } else {
          // backup
          if (onairsts === '020') {
            // 동작중이면
            suri = c.bstreamuri;
            corp = c.bcorp;
            setPlaying(true);
          }  
        }
      }
    }) 
    setStreamUri(suri);
    setCorp(corp);
    //console.log('streamUri',suri);
    // 스크립트로 mute 시키면 중지됨. (크롬정책)
    //ref.current.
    //setTimeout(()=>{console.log('@@');setMuted(false); setPlaying(false); },1000)
  }

  // play 시작
  const handlePlay = () => {
    setPlaying(true);
  }
  
  // 진행상태를 state 에 업데이트
  const handleProgress = (state) => {
    setPlaystate(state);
  }

  // duration 셋팅
  const handleDuration = (dur) => {
    setDuration(dur);
  }

  // 플레이어 에러 발생시
  const handleOnError = (e, data) => {
    console.log('handleOnError!',e, data);
  }

  // 플레이종료시
  const handleOnEnded = (e, data) => {
    console.log('handleOnEnded!',e, data);
    if (useSurvey && surveycnt === 0) {
      setShowSurveyPopup(!showSurveyPopup);
    }
  }

  // 메뉴 오픈,클로즈.
  const handleMenuOpen = () => {
    setMenuOpen(!menuOpen);
  }

  const handleRefresh = () => {
    window.parent.location.reload();
    /*
    setMenuOpen(false);
    handleDispatch();
    */
  }

  /**
   * 사운드켜기
   */
  const handleSoundOn = () => {
    setMuted(false);
  }

  // 60 초마다 데이타 갱신 => push 로 변경!
  // useInterval(() => {
  //   handleDispatch()
  // }, 1000*60)
  
  useEffect(() => {
    // mobile 일 경우 width 에 대한 일정한 비율로 player 의 height 를 잡아준다.
    if (isMobile) {
      const w = refplayerbox && refplayerbox.current && refplayerbox.current.offsetWidth;
      setMobilePlayerHeight(w*0.56);
    }

    // 컴포넌트 마운트 후 포스트 목록 요청
    if (data === null || data === undefined) {
      
    } else {
      if (data.result_cd !== 1) {
        // 에러인 경우
        toast.error(`Error : ${data.result_msg}`);
        if (data.result_msg === 'Unauthorized') {
          //TODO 로그인 페이지로 이동.
          history.push('/login/'+webinaridx);
        } else {
          // 어디론가 이동시켜야 함.
        }
      } else if (isNotNull(data.map)) {
        // 데이타를 가져오고 난 후
        setLikecnt(data.map.likecnt);
        setSurveycnt(data.map.surveycnt)
        if (isNotNull(data.map.info)) {
          setInfo(data.map.info);
          setWebinartopbtnimg(data.map.info.webinartopbtnimg);
          setWebinarlivelogo(data.map.info.webinarlivelogo);
          setLikeIcon(data.map.info.likeicon);

          //브로슈어 명칭 변경값
          if(isNotNull(data.map.info.livebronm) && data.map.info.livebronm !== '') setLivebronm(data.map.info.livebronm);
          
          // notice popup open
          if (checkWebinarPlugin(webinarPluginCode.NOTICE_POPUP,data.map.info.plugins)) {
            if (window.sessionStorage.getItem('showNoticePopup') !== 'true') {
              setShowNoticePopup(true);
              window.sessionStorage.setItem('showNoticePopup','true')
            }
          }
          // 브로셔가 있는지의 여부
          if (isNotNull(data.map.info.webinarbro)) {
            setHasBro(true);
          } else {
            setHasBro(false);
          }
          // POLL 사용 여부
          if (checkWebinarPlugin(webinarPluginCode.REALTIMEPOLL,data.map.info.plugins)) {
            setUsePoll(true);
          } else { 
            setUsePoll(false);
          }
          // 설문 사용 여부
          console.log('usesurvey1!!', checkWebinarPlugin(webinarPluginCode.POSTSURVEY,data.map.info.plugins));
          if (checkWebinarPlugin(webinarPluginCode.POSTSURVEY,data.map.info.plugins)) {
            setUseSurvey(true);
          } else { 
            setUseSurvey(false);
          }
          // first Call Reservation 사용여부(M2M)
          if (checkWebinarPlugin(webinarPluginCode.CALL_RESERVATION,data.map.info.plugins)) {
            setUseFirstCall(true);
          } else { 
            setUseFirstCall(false);
          }
          // Like 사용 여부
          if (checkWebinarPlugin(webinarPluginCode.LIKEFUNC,data.map.info.plugins)) {
            setUseLike(true);
          } else { 
            setUseLike(false);
          }
          window.openChat = checkWebinarPlugin(webinarPluginCode.LIVECHAT_OPEN,data.map.info.plugins);
          //하단 copyright 
          if(isNotNull(data.map.info.copyright)){
            setCopyright(data.map.info.copyright);
          }
          if(isNotNull(data.map.info.copyrightm)){
            setCopyrightm(data.map.info.copyrightm);
          }
        }
        // stream 주소 셋팅
        if (isNotNull(data.map.liveservermaps)) {
          setLiveStream(data.map.liveservermaps, selStream.liveservermapidx, data.map.info.onairsts);
        }
        // BANNER
        if (isNotNull(data.map.banner)) {
          setBanner(data.map.banner.bannerimg,data.map.banner.uri);
        }
      }
    }
  },[data]);

  useEffect(() => {
    let delayForData = 0;
    if (isNotNull(webinar) && isNotNull(webinar.map) && isNotNull(webinar.map.info)) {
      if (((webinar.map.info.plugins&webinarPluginCode.LIKEFUNC)===webinarPluginCode.LIKEFUNC)
        && window.sessionStorage.getItem('showTutorialPopup') !== 'true') {
        delayForData = 0;
        setShowTutorialPopup(true);
        //TODO 테스트 종료 후 아래 주석 풀어줄것.
        window.sessionStorage.setItem('showTutorialPopup','true')
      }
    }
    setTimeout(() => dispatch(getLive(webinaridx,0b00001111)), delayForData);
    return () => {
      //푸시서버 접속종료.
      if (typeof window.disconnect === 'function') {
        window.disconnect();
      }
    }
  },[dispatch]);

  // handleChangeStream
  const handleChangeStream = (e) => {
    setMenuOpen(false)
    const idx = parseInt(e.target.getAttribute('data-idx'));
    setLiveStream(liveservers, idx, data.map.info.onairsts);
  }

  useEffect(() => {
    if (error) {
      if (String(error).indexOf('status code 401')>0) {
        window.sessionStorage.clear();
        dispatch(destroySession());
        history.push('/login/' + webinaridx);
        return;
      }
      toast.error(`The service is not smooth Please use it later : ${error}`);
    }
  },[error]);
  /**
   * fullscreen 으로 전환
   */
  const handleFullScreen = () => {
    console.log('handleFullScreen');
    //var el = document.getElementsByClassName('m-player');
    //const el = document.getElementsByTagName('video');
    console.log('handleFullScreen',refplayerbox);
    //screenfull.request(findDOMNode(refplayerbox.current))

    const el = findDOMNode(refplayerbox.current)

    if (el.requestFullscreen) {
      el.requestFullscreen();
    } else if (el.msRequestFullscreen) {
      el.msRequestFullscreen();
    } else if (el.mozRequestFullScreen) {
      el.mozRequestFullScreen();
    } else if (el.webkitRequestFullscreen) {
      el.webkitRequestFullscreen();
    }
  }

  /**
   * fullscreen 에서 빠져나오기
   */
  const toggleFullScreen = () => {
    var el = document.getElementsByTagName('video');
    console.log('toggleFullScreen',el);
    if (screenfull.isFullscreen) {
      document.exitFullscreen();
    }
    var livePollForm = document.getElementsByName('livePollForm');
    console.log('@@',livePollForm);
    window.location.hash = '#livePollForm';

    //livePollForm.scrollIntoView();
    //refpoll.current.focus();
    // if (el.requestFullscreen) {
    //   el.requestFullscreen();
    // } else if (el.msRequestFullscreen) {
    //   el.msRequestFullscreen();
    // } else if (el.mozRequestFullScreen) {
    //   el.mozRequestFullScreen();
    // } else if (el.webkitRequestFullscreen) {
    //   el.webkitRequestFullscreen();
    // }
  };

  const pcjsx = (
    <>
      <div className="wblogo-wrap">
        {isNotNull(info) && <span><img src={webinarlivelogo} className="wblivelogoimg"/></span>}
        {isNotNull(info) && <span><a href={info.topbtnuri} target="_blank"><img src={webinartopbtnimg} className="wbtopbtnimg"/></a></span>}
      </div>
    <div className="webinar-wrap">
      <div className="stream-div">
      {
        isNotNull(liveservers) && liveservers.map((c,index) => {
          return <button key={index} data-idx={c.liveservermapidx} onClick={handleChangeStream} 
            className={c.liveservermapidx === selStream.liveservermapidx?'streamid on':'streamid'}>{c.liveservermapnm}</button>
        })
      }
      </div>
      {
      (isNotNull(info) && info.boothyn === 'Y' && isNotNull(info.lobbyuri) && info.lobbyuri !== '')?
      <>
      <a href="#" className="btn btn-webinar-lobby" onClick={handleLobby}>
        Lobby
          <Home className="material-icons"/>
      </a>
      <a href="#" className="btn btn-webinar-refresh btn-webinar-refresh-seperator" onClick={handleReload}>
      Refresh
        <Refresh className="material-icons"/>
      </a>
      </>
      :
      <a href="#" className="btn btn-webinar-refresh" onClick={handleReload}>
        Refresh
          <Refresh className="material-icons"/>
      </a>
      }
      <a href="#!" className="btn btn-webinar-close survey-modal" onClick={handleCloseLive}>
        Close
            <Clear className="material-icons"/>
      </a>
      <ul>
        <li>
          <div className="vod-wrap" ref={refplayerbox}>
            {useLike === true ? likeIcon === '020' ?
            <div className="like-wrap">
              <p>{likecnt}</p>
              <BookmarkAddedIcon className="material-icons"/>
            </div>
            :
            <div className="like-wrap">
              <p>{likecnt}</p>
              <Favorite className="material-icons"/>
            </div>
            :null}
            {(isNull(streamUri) || streamUri === '')?<div className="wait-wrap"><p>This is before the start of the broadcast</p></div>
            // :playstate.playedSeconds === 0?
            // <div className="wait-wrap"><p>Broadcasting has started.</p><button onClick={handlePlay}>Watch</button></div> 
            :null
            }
            {muted == true?
            <div className="sound-wrap"><img src="/images/icon-sound-on-eng01.png" className="soundon" onClick={handleSoundOn}/></div>
            :null}
            <WebinarPlayer 
              corp={corp}
              url={streamUri}
              width="1100px" height="619px"
              className='player'
              playing={playing}
              controls
              muted={muted}
              onProgress={handleProgress}
              onDuration={handleDuration}              
              onError={handleOnError}
              onEnded={handleOnEnded}
              config={{
                file: {
                  //forceHLS: true,
                  attributes: { 
                    preload: 'auto' 
                  }                 
                }
              }}
              />
            {/* <button onClick={handlePlay}>play</button> */}
            {/* <iframe width="1100" height="619" src="https://www.youtube.com/embed/lrEffB8jX3I?controls=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
          </div>
          <LiveChatDiv webinaridx={webinaridx} info={info} isMobile={isMobile} 
            execPollidx={execPollidx} chatHistory={chatHistory} pushedPresurvey={pushedPresurvey} newChatMessage={newChatMessage}
            />
          { execPollidx > -1 ?
          <LivePollDiv webinaridx={webinaridx} info={info} pollidx={execPollidx} 
            pushedpoll={execPoll} handleExecuteLivePoll={handleExecuteLivePoll} isMobile={isMobile}/>
          :null }
        </li>
        <li>
          <div className={(isNotNull(bannerUri) && bannerUri !== '')?'banner-wrap':'banner-wrap off'}>
            <img src={bannerImg} onClick={moveToBanner}/>
          </div>
          <ul className="menu-wrap">
            <li>
              {hasBro === true ?
              <a href="#!" className="btn-brochure" onClick={handleDownBro}>
                <ChromeReaderMode  className="material-icons"/> {livebronm}</a>
              :null}
              {usePoll === true ?
              <a href="#!" className="btn-poll poll-modal" onClick={toggleShowPollPopup}>
                <InsertChart  className="material-icons"/> Live Poll</a>
              :null}
              <a href="#!" className="btn-faq faq-modal" onClick={toggleShowFaqPopup}>
                <Feedback  className="material-icons"/> FAQ</a>
            </li>
            <li>
              {useLike === true ? likeIcon === '020' ?
              <a href="#!" className="btn btn-like like-modal" onClick={handleClickLike}>
                <BookmarkAddedIcon  className="material-icons"/> 
                <div className="liketxt">Bookmark</div></a>
              :
              <a href="#!" className="btn btn-like like-modal" onClick={handleClickLike}>
                <Favorite  className="material-icons"/> 
                <div className="liketxt">Like</div></a>
              :null}
            </li>
          </ul>
        </li>
      </ul>
      {showFaqPopup === true?
        <FaqListPop
        webinaridx={webinaridx}
        toggleShowFaqPopup={toggleShowFaqPopup}
        isMobile={isMobile}
        />
        :null}
      {showPollPopup === true?
        <PollListPop
        webinaridx={webinaridx}
        toggleShowPollPopup={toggleShowPollPopup}
        isMobile={isMobile}
        />
        :null}
      {showSurveyPopup === true?
        <SurveyListPop
        webinaridx={webinaridx}
        parenttp={parenttp}
        toggleShowSurveyPopup={toggleShowSurveyPopup}
        isMobile={isMobile}
        history={history}
        afterSurveyClose={afterSurveyClose}
        />
        :null}
      {showNoticePopup === true?
        <NoticePop
        webinaridx={webinaridx}
        toggleShowNoticePopup={toggleShowNoticePopup}
        isMobile={isMobile}
        />
        :null}
      {showTutorialPopup === true?
        <TutorialPop 
        webinaridx={webinaridx}
        toggleShowTutorialPopup={toggleShowTutorialPopup}
        isMobile={isMobile}
        likeIcon={likeIcon}
        />
        :null}
    </div>
    {useFirstCall === true?
      <FirstCall
      webinaridx={webinaridx}
      isMobile={isMobile}
      showFirstCallPopup={showFirstCallPopup}
      setShowFirstCallPopup={setShowFirstCallPopup}
      closeWebinar={closeWebinar}
      setFirstCallReg={setFirstCallReg}
      isPop={isPop}
      />
      :null
    }
    <div className="copyright-wrap" dangerouslySetInnerHTML={{__html: copyright}}>
    </div>
    <Script type="text/javascript" src="/js/push.js" onLoad={joinPush} />  
    </>
  );
  const mobilejsx = (
    <>
    <div id="m-vertical-mode">
      <div className={menuOpen===true?'m-navi-wrap  m-open':'m-navi-wrap'}>
        <div className={menuOpen===true?'m-logo-wrap opacity0':'m-logo-wrap'} >
          <img src={webinarlivelogo}/>
        </div>
        <ul className={menuOpen===true?'m-navi-bar m-open':'m-navi-bar'}>
          {hasBro === true ?
          <li>
            <a href="#!" className="m-on" onClick={handleDownBro}>{livebronm}</a>
          </li>
          :null}
          {usePoll === true ?
          <li>
            <a href="#!" className="m-poll-modal" onClick={toggleShowPollPopup}>Live Poll</a>
          </li>
          :null}
          <li>
            <a href="#!" className="m-faq-modal" onClick={toggleShowFaqPopup}>FAQ</a>
          </li>
          {
            isNotNull(liveservers) && liveservers.map((c,index) => {
              return <li key={index}><button data-idx={c.liveservermapidx} onClick={handleChangeStream} 
                className={c.liveservermapidx === selStream.liveservermapidx?'streamid on':'streamid'}>{c.liveservermapnm}</button></li>
            })
          }
          {(isNotNull(info) && info.boothyn === 'Y' && isNotNull(info.lobbyuri) && info.lobbyuri !== '')?
          <li>
            <a href="#!" onClick={handleLobby}>Lobby</a>
          </li>
          :null}
          {(isNotNull(info)&& isNotNull(info.topbtnnm) && info.topbtnnm!=='')?
            <li>
              <a href="#!" className="m-on" onClick={handleTopbtn}>{info.topbtnnm}</a>
            </li>
            :null
          }
          <li>
            <a href="#!" onClick={handleRefresh}>Page Refresh</a>
          </li>
          <li>
            <a href="#!" className="m-survey-modal" onClick={handleCloseLive}>VOD Close</a>
          </li>
        </ul>
        <Menu  className="material-icons" onClick={handleMenuOpen}/>
      </div>    
        <div className="m-content-wrap">
          <div className="m-vod-wrap" ref={refplayerbox}>
            {/*useLike === true ? likeIcon === '020' ?
              <div className="m-like-wrap">
                <p>{likecnt}</p>
                <BookmarkAddedIcon className="material-icons" />
              </div>
              :
              <div className="m-like-wrap">
                <p>{likecnt}</p>
                <Favorite className="material-icons" />
              </div>
              : null*/}
            {(isNull(streamUri) || streamUri === '')?<div className="m-wait-wrap"><p>This is before the start of the broadcast</p></div>
            // :playstate.playedSeconds === 0?
            // <div className="m-wait-wrap">
            //   <div>
            //   <p>Broadcasting has started.</p><button onClick={handlePlay}>Watch</button>
            //   </div>
            // </div>
            :null
            }              
            {muted == true?
            <div className="m-sound-wrap"><img src="/images/icon-sound-on-eng01.png" className="m-soundon" onClick={handleSoundOn}/></div>
            :null}
            <WebinarPlayer
              corp={corp}
              url={streamUri}
              width="100%" height={mobilePlayerHeight}
              className='m-player'
              playing={playing}
              controls
              muted={muted}
              onProgress={handleProgress}
              onDuration={handleDuration}
              onError={handleOnError}
              onEnded={handleOnEnded}
              config={{
                file: {
                  //forceHLS: true,
                  attributes: { 
                    preload: 'auto' 
                  }                 
                }
              }}
            />
            {/* <div className="player-overlay" onClick={handleFullScreen}>Live Poll is ready!</div> */}
          </div>
          <ul className="m-banner-wrap">
            <li>
              <img src={bannerImg} onClick={moveToBanner}/>
            </li>
            {useLike === true?
            <li>
            <div className="m-likewrap">
              {likeIcon === '020' ?
                <a href="#!" className="m-btn-like" onClick={handleClickLike}>
                  <BookmarkAddedIcon  className="material-icons"/>
                    <p className="likecount">{likecnt}</p>
                <div className="liketxt">Bookmark</div>
                  </a>
                :
                <a href="#!" className="m-btn-like" onClick={handleClickLike}>
                  <Favorite  className="material-icons"/>
                  <p className="likecount">{likecnt}</p>
                <div className="liketxt">Like
                </div></a>
                }
              </div>
            </li>
            :null
            }
          </ul>
            <LiveChatDiv webinaridx={webinaridx} info={info} isMobile={isMobile} execPollidx={execPollidx}
             chatHistory={chatHistory} pushedPresurvey={pushedPresurvey} newChatMessage={newChatMessage}/>
          {execPollidx > -1 ?
            <LivePollDiv webinaridx={webinaridx} info={info} pollidx={execPollidx} 
              pushedpoll={execPoll} handleExecuteLivePoll={handleExecuteLivePoll} isMobile={isMobile}/>
            : null}

        </div>
        {useFirstCall === true?
          <FirstCall
          webinaridx={webinaridx}
          isMobile={isMobile}
          showFirstCallPopup={showFirstCallPopup}
          setShowFirstCallPopup={setShowFirstCallPopup}
          closeWebinar={closeWebinar}
          setFirstCallReg={setFirstCallReg}
          isPop={isPop}
          />
          :null
        }
        <div className="copyright-wrap" dangerouslySetInnerHTML={{__html: copyrightm}}>
        </div>
        {/**
        <div className="m-footer-wrap">
        </div>
         */}

        {showFaqPopup === true ?
          <FaqListPop
            webinaridx={webinaridx}
            toggleShowFaqPopup={toggleShowFaqPopup}
            isMobile={isMobile}
          />
          : null}
        {showPollPopup === true ?
          <PollListPop
            webinaridx={webinaridx}
            toggleShowPollPopup={toggleShowPollPopup}
            isMobile={isMobile}
          />
          : null}
        {showSurveyPopup === true ?
          <SurveyListPop
            webinaridx={webinaridx}
            parenttp={parenttp}
            toggleShowSurveyPopup={toggleShowSurveyPopup}
            isMobile={isMobile}
          />
          : null}
        {showNoticePopup === true ?
          <NoticePop
            webinaridx={webinaridx}
            toggleShowNoticePopup={toggleShowNoticePopup} 
            isMobile={isMobile}
            />
          : null}
        {showTutorialPopup === true ?
          <TutorialPop
            webinaridx={webinaridx}
            toggleShowTutorialPopup={toggleShowTutorialPopup} 
            isMobile={isMobile}
            />
          : null}
        <Script type="text/javascript" src="/js/push.js" onLoad={joinPush} />
    </div>
    </>
  );
  if (isMobile) return mobilejsx;
  else return pcjsx;
}

/**
 * setInterval 의 대체함수.
 * @param {*} callback 
 * @param {*} delay 
 */
const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}



export default WebinarLiveContainer;