import React, { useState } from 'react';
import Script from 'react-script-tag';

function PrompterPage({ match,history }) {
  
  const { webinaridx } = match.params; // URL 파라미터 조회하기
  console.log('webinaridx',webinaridx);
  
  const [prompterText,setPrompterText] = useState();
  
  let orgPrompterText = '';
  // 푸시서버 접속
  const joinPush = () => {
    window.joinRoom("" + webinaridx, "speaker__"+webinaridx + "%" 
    + new Date().getTime(), 'speaker', (tp) => {
      console.log('@@@',tp);
      switch (tp.commandId) {
        case "send_prompt":
          const txt = tp.chattext.replaceAll('\n', '<br/>');
          console.log('txt',prompterText);
          if (orgPrompterText === '') {
            setPrompterText(txt);
            orgPrompterText = txt;
          } else {
            setPrompterText(orgPrompterText + '<br/>' + txt);
            orgPrompterText = orgPrompterText + '<br/>' + txt;
          }
          break;
        case "clear_prompt":
          setPrompterText(null);  // TODO 여기가 안되요~~~
          orgPrompterText = '';
          break;
      }
    });
  }
  

  return (
    <div>
      <div className="prompter-wrap"
        dangerouslySetInnerHTML={ {__html: prompterText} } >
      </div>
      <Script type="text/javascript" src="/js/push.js" onLoad={joinPush} />  
    </div>
  );
}

export default PrompterPage;


