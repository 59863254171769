import axios from 'axios';

/**
 * 라이브정보 조회
 * @param {*} param 
 */
export const getLive = async (param) => {
  console.log('param',param, param.webinaridx);
  const response = await axios({
    method:'get',
    url:`${process.env.REACT_APP_API_SERVER}/front/webinars/${param.webinaridx}`,
    // data 는 body 에, params 는 쿼리에..
    params: {'infotp':param.infotp},
    headers: {
      Authorization: 'Bearer ' + window.sessionStorage.getItem('usrtoken')
    }
  });
  return response.data;
};

/**
 * QNA 목록조회
 * @param {*} param 
 */
export const getLiveQnas = async (param) => {
  console.log('param',param);
  const response = await axios({
    method:'get',
    url:`${process.env.REACT_APP_API_SERVER}/front/qnas`,
    params: {
        'webinaridx':param.webinaridx
      },
    headers: {
      Authorization: 'Bearer ' + window.sessionStorage.getItem('usrtoken')
    }
  });
  return response.data;
};

/**
 * QNA 답변등록
 * @param {*} param 
 */
export const postLiveQna = async (param) => {
  console.log('param',param);
  const response = await axios({
    method:'post',
    url:`${process.env.REACT_APP_API_SERVER}/front/qnas`,
    data: param.data,
    headers: {
      Authorization: 'Bearer ' + window.sessionStorage.getItem('usrtoken')
    }
  });
  return response.data;
};

/**
 * 좋아요 등록
 * @param {*} param 
 */
export const postLike = async (param) => {
  console.log('param',param);
  const response = await axios({
    method:'post',
    url:`${process.env.REACT_APP_API_SERVER}/front/likes`,
    // data 는 body 에, params 는 쿼리에..
    data: param.data,
    headers: {
      'Authorization': 'Bearer ' + window.sessionStorage.getItem('usrtoken')
    }
  });
  return response.data;
};

/**
 * vod접속로그 등록
 * @param {*} param 
 */
 export const postAccess = async (param) => {
  console.log('param',param);
  const response = await axios({
    method:'post',
    url:`${process.env.REACT_APP_API_SERVER}/front/ptcpntlogs`,
    // data 는 body 에, params 는 쿼리에..
    data: param.data,
    headers: {
      'Authorization': 'Bearer ' + window.sessionStorage.getItem('usrtoken')
    }
  });
  return response.data;
};