import Faq from './Faq'

function FaqList({ faqs, isMobile }) {

  return (
    <>
      <ul className="faq-modal-wrap">
      {
        faqs !== null && faqs !== undefined &&
        faqs.map((c, index) => {
          return <Faq
            faq={c}
            key={index}
            isMobile={isMobile}
          />
        })}
      </ul>
    </>
  )
}

export default FaqList;