import { call, put, takeEvery } from 'redux-saga/effects';
import * as pollAPI from '../api/poll'; // api/ques 안의 함수 모두 불러오기
import {
  handleAsyncActions, reducerUtils
} from '../lib/asyncUtils';

/* 액션 타입 */

// 투표 조회하기
const GET_POLLS = 'GET_POLLS';                    // 요청 시작
const GET_POLLS_SUCCESS = 'GET_POLLS_SUCCESS';    // 요청 성공
const GET_POLLS_ERROR = 'GET_POLLS_ERROR';        // 요청 실패

// 투표하기
const POST_POLL = 'POST_POLL';
const POST_POLL_SUCCESS = 'POST_POLL_SUCCESS';
const POST_POLL_ERROR = 'POST_POLL_ERROR';


export const getPolls = (webinaridx) => ({ type: GET_POLLS, param:{webinaridx} });
export const postPoll = (data) => ({ type: POST_POLL, param:{data} })

function* getPollSaga(action) {
  const param = action.param;
  try {
    const polls = yield call(pollAPI.getPolls, param); // call 을 사용하면 특정 함수를 호출하고, 결과물이 반환 될 때까지 기다려줄 수 있습니다.
    yield put({
      type: GET_POLLS_SUCCESS,
      payload: polls
    }); // 성공 액션 디스패치
  } catch (e) {
    yield put({
      type: GET_POLLS_ERROR,
      error: true,
      payload: e
    }); // 실패 액션 디스패치
  }
}

// 투표질문등록 사가~
function* postPollSaga(action) {
  const param = action.param;
  try {
    const Poll = yield call(pollAPI.postPoll, param); // API 함수에 넣어주고 싶은 인자는 call 함수의 두번째 인자부터 순서대로 넣어주면 됩니다.
    yield put({
      type: POST_POLL_SUCCESS,
      payload: Poll
    });
  } catch (e) {
    yield put({
      type: POST_POLL_ERROR,
      error: true,
      payload: e
    });
  }
}

// 사가들을 합치기
export function* pollSaga() {
  yield takeEvery(GET_POLLS, getPollSaga);
  yield takeEvery(POST_POLL, postPollSaga);
}

// initialState 쪽도 반복되는 코드를 initial() 함수를 사용해서 리팩토링 했습니다.
const initialState = {
  polls: reducerUtils.initial()
};

export default function poll(state = initialState, action) {
  switch (action.type) {
    case GET_POLLS:
    case GET_POLLS_SUCCESS:
    case GET_POLLS_ERROR:
      return handleAsyncActions(GET_POLLS, 'polls', true)(state, action);
    case POST_POLL:
    case POST_POLL_SUCCESS:
    case POST_POLL_ERROR:
      return handleAsyncActions(POST_POLL, 'polls', true)(state, action);
    default:
      return state;
  }
}