import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import PtcpntAddedItems from '../components/PtcpntAddedItems';
import PtcpntAddedTerms from '../components/PtcpntAddedTerms';
import { isNotEmpty, isNull, printValidate } from '../lib/wbUtils';
import { clearPutPtcpnt, getPtcpntItem, putPtcpnt } from '../modules/ptcpnts';

function PreRegistrationContainer({history, isMobile}) {

  const { t }  = useTranslation(['prereg']);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();
  let webinaridx = query.get("webinaridx");

  const { data, loading, error, ptcpntregdone } = useSelector(state => ({
    data:state.modPtcpnt.items.data
    ,loading:state.modPtcpnt.items.loading
    ,error:state.modPtcpnt.items.error
    ,ptcpntregdone:state.modPtcpnt.ptcpntregdone.data
  }))

  const dispatch = useDispatch();
  const { register, handleSubmit, formState: { errors }, getValues, setValue } = useForm();
  const [info, setInfo] = useState({'logintp':'','webinarlogo':'/images/img_logo01.png','belongnm':null,'jheader':''}); // webinar info
  const [bean, setBean] = useState(
    {
    ptcpntemail: '', ptcpntnm: '', ptcpnthp: '', webinaridx: webinaridx, logintp: '010', company: '',
    location:window.location.origin,
    added:{}
    }
  );

  const [disable, setDisable] = useState(false);
  const [belong, setBelong] = useState({'title':t('prereg:belong'),'pholder':t('prereg:belong-pholder')})
  const [checkAll, setCheckAll] = useState(true);
  const [btncss, setBtncss] = useState({'registerAgreeAllText':null,'registerAgreeAllStyle':null,'registerCancelText':null,'registerCancelStyle':null,'registerRegisterText':null,'registerRegisterStyle':null});

  const handleChange = (e) => {
    setBean({ ...bean, [e.target.name]: e.target.value });
  }

  const handleChangeItem = (e) => {
    setBean({ ...bean, added:{...bean.added, [e.target.name]: e.target.value}});
  }

  const handleCheckItem = (e) => {
    let ans ='N';
		if(e.target.checked) ans = 'Y';
    setBean({ ...bean, added:{...bean.added, [e.target.name]: ans}});
  }

  const handleCheckAll = (e) => {
    let input = document.getElementsByClassName('terms-chk');
    Array.from(input).forEach(el => {
      el.checked = checkAll;
      setValue(el.name, checkAll);
    });
    setCheckAll(!checkAll);
    // let added = "{";
    // if(data !== null && data.map !== null){
    //  data.map.ptcpntterms.forEach((e, index) => {
    //   let name = `added.termsagr0${e.disporder}`;
    //   let ans = checkAll?'Y':'N';
    //   added += `"${name}":"${ans}"`
    //   if(index<data.map.ptcpntterms.length-1) added += ','
    //  });
    // }
    // added += "}";
    // let jsonAdded = JSON.parse(added);
    // setBean({ ...bean, added:jsonAdded});
    // setCheckAll(!checkAll);
  }

  // console.log("test", bean.added);

  useEffect(()=>{
    dispatch(getPtcpntItem(webinaridx));
  },[dispatch]);
  useEffect(()=>{
    if (ptcpntregdone !== null && ptcpntregdone.result_cd !== null) {
      if (ptcpntregdone.result_cd===1) {
        dispatch(clearPutPtcpnt());
        alert('Successfully registered' );
        // 이동~
        setTimeout(function(){history.push('/login/'+webinaridx)},500);
      } else {
        alert(`The service is not smooth Please use it later : ${ptcpntregdone.result_msg}`);
        setDisable(false);
      }
    }
  },[ptcpntregdone]);

  useEffect(()=>{
    if (data !== null && data.result_cd === 1 ) {
      if (isNull(data.map.info)) {
        // 정보를 가져왔는데 웨비나 정보가 존재하지 않을때.. (삭제되는 등..)
        history.push('/notfound/Webinar');
      } else {
        setInfo(data.map.info);
        if (data.map.info.btncss !== null) {
          try {
            setBtncss(JSON.parse(data.map.info.btncss));
          } catch (e) {
            console.warn(e.message);
          }
        }        
        setBean({...bean, 'logintp':data.map.info.logintp})
        if (isNotEmpty(data.map.info.belongnm)) {
          let holder = belong.pholder.replace(belong.title, data.map.info.belongnm);
          setBelong({'title':data.map.info.belongnm,'pholder':holder})
        }
      }
    }
  },[data]);
  let itemReqArea;
  let itemArea;
  let termArea;
  // 로딩중
  if (loading && !data){
    itemArea = <tr><td colSpan='2'>loading...</td></tr>
    termArea = <div>loading...</div>
  } 
  // 에러
  if (error){
    itemArea = <tr><td colSpan='2'>The service is not smooth<br/>Please use it later</td></tr>
    termArea = <div>The service is not smooth<br/>Please use it later</div>
  } 
  // 데이타 불러왔지만 에러일때
  if (data !== null && data.result_cd === 0){
    itemArea = <tr><td colSpan='2'>The service is not smooth<br/>Please use it later : {data.result_msg}</td></tr>
    termArea = <div>The service is not smooth<br/>Please use it later : {data.result_msg}</div>
  } 
  // 정상적으로 데이타 호출시
  if (data !== null && data.result_cd === 1) {
    let reqitems = [];
    let noritems = [];
    data.map.ptcpntitems.map((val, index)=>{
      if (val.reqyn === 'Y') {
        reqitems.push(val);
      } else {
        noritems.push(val);
      }
    })
    if (reqitems.length > 0) {
      itemReqArea = <PtcpntAddedItems ptcpntitems={reqitems} handleChangeItem={handleChangeItem} register={register} errors={errors}/>
    }
    if (noritems.length > 0) {
      itemArea = <PtcpntAddedItems ptcpntitems={noritems} handleChangeItem={handleChangeItem} register={register} errors={errors}/>
    }
    termArea = <PtcpntAddedTerms ptcpntterms={data.map.ptcpntterms} added = {bean.added} getValues={getValues}
    handleCheckItem={handleCheckItem} register={register} errors={errors}/>
  }

  const handleCreate = (data) => {
    console.log("...",data);
    if(bean.added){
      const added = new Map(Object.entries(bean.added));
      added.forEach((val, key)=>{
        let keyName = key.split('.')[1];
        if(keyName.startsWith('termsagr')){
          if(val === 'Y'){
            data.added[keyName] = true;
          }else{
            data.added[keyName] = false;
          }
        }
      })
    }
    data.webinaridx = webinaridx;
    data.logintp = bean.logintp;
    data.location = window.location.origin;
    //if(!disable){
       dispatch(putPtcpnt(data));
      //setDisable(true);
    //}else{
    //  toast.error(`${bean.ptcpntemail} has been registered already`);
    //}
  }
  // console.log("T E S T", getValues(), errors);

  const handleCancel = () => {
    history.push('/login/'+webinaridx)
  }

  return (
    <>
      <div className="register-wrap">
        <div className="register-box modify-scroll">
          <div className="content">
            <div className="header">
              <p>{t('prereg:pre-regist')}</p>
              <div className="register-logo">
                <img src={info.webinarlogo} />
              </div>
            </div>
            <div className="belowHeader" dangerouslySetInnerHTML={{__html:info.jheader}}></div>
            <p className="copy01">{t('prereg:star-required')}</p>

            <table>
              <colgroup>
                <col width="30%" />
                <col width="*" />
              </colgroup>
              <tbody>
                <tr>
                  <td>*{t('prereg:name')}</td>
                  <td>
                    <input type="text" onChange={handleChange} 
                      name="ptcpntnm" 
                      {...register('ptcpntnm',{ required: true, maxLength:255 })}
                      placeholder={t('prereg:name-pholder')}
                      />
                    {printValidate(errors.ptcpntnm, 'Name')}
                  </td>
                </tr>
                {info.lang ==='en'?
                  <tr>
                  <td>*{t('prereg:last-name')}</td>
                  <td>
                    <input type="text" onChange={handleChange} 
                      name="ptcpntlastnm" 
                      {...register('ptcpntlastnm',{ required: true, maxLength:255 })}
                      placeholder={t('prereg:name-pholder')}
                      />
                    {printValidate(errors.ptcpntlastnm, 'Last name')}
                  </td>
                  </tr>
                  :null
                }
                <tr>
                  <td>*{t('prereg:email')}</td>
                  <td>
                    <input type="text" 
                    onChange={handleChange}
                      {...register('ptcpntemail',{ required: true, pattern: /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i })}
                      name="ptcpntemail"
                      placeholder={t('prereg:email-pholder')} />
                      {printValidate(errors.ptcpntemail, t('prereg:email'))}
                  </td>
                </tr>
                {info.lang === 'ko'?
                  <tr>
                    <td>*{t('prereg:phone')}</td>
                    <td>
                      <input
                        type="text"
                        onChange={handleChange}
                        {...register('ptcpnthp',{ required: true, pattern: /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/g })}
                        name="ptcpnthp"
                        placeholder={t('prereg:phone-pholder')}
                      />
                      {printValidate(errors.ptcpnthp, t('prereg:phone'))}
                    </td>
                  </tr>
                  :null
                }
                {info.logintp === '010'?
                <tr>
                  <td>*{t('prereg:pwd')}</td>
                  <td>
                    <input
                      type="password"
                      onChange={handleChange}
                      {...register('ptcpntpwd',{ required: true,  minLength:{
                        value:8,
                        message:"password shoule be longer than 8 characters"
                      } })}
                      name="ptcpntpwd"
                      placeholder={t('prereg:pwd-pholder')}
                    />
                    {printValidate(errors.ptcpntpwd, t('prereg:pwd'))}
                  </td>
                </tr>
                :null}
              {info.logintp === '010'?
                <tr>
                  <td>*{t('prereg:pwd-confirm')}</td>
                  <td>
                    <input
                      type="password"
                      onChange={handleChange}
                      {...register('ptcpntpwdConfirm',{ required: true, validate:{
                        matchPreviousPassword: (value) => {
                          const { ptcpntpwd } = getValues();
                          return ptcpntpwd === value || "Password and confirmation of password fields should match";
                        }
                      } })}
                      name="ptcpntpwdConfirm"
                      placeholder={t('prereg:pwd-confirm-pholder')}
                    />
                    {printValidate(errors.ptcpntpwdConfirm, t('prereg:pwd-confirm'))}
                  </td>
                </tr>
                :null}
                {itemReqArea}
                <tr>
                  <td>*{belong.title}</td>
                  <td>
                    <input
                      type="text"
                      onChange={handleChange}
                      {...register('company',{ required: true })}
                      name="company"
                      placeholder={belong.pholder}
                    />
                    {printValidate(errors.company, belong.title)}
                  </td>
                </tr>
                {itemArea}
              </tbody>
            </table>
            {info.logintp === '020'?
            <p className="copy01">※ {t('prereg:last4-pwd')}</p>
            :null}
            <div className="agree-wrap">
              {termArea}
            </div>
            <div style={{textAlign:'center'}}>
              {data!==null&&data.map!==null&&data.map.ptcpntterms.length>0?
                <button className="btn-register ml-10" style={btncss.registerAgreeAllStyle} onClick={handleCheckAll}>{btncss.registerAgreeAllText!=null?btncss.registerAgreeAllText:t('prereg:agree-all')}</button>
                :null
              }
              </div>
            <div className="btn-wrap">
              <button className="btn-login" style={btncss.registerCancelStyle} onClick={handleCancel}>{btncss.registerCancelText!=null?btncss.registerCancelText:t('prereg:btn-cancel')}</button>
              <button className="btn-register ml-10" style={btncss.registerRegisterStyle} onClick={handleSubmit(handleCreate)}>{btncss.registerRegisterText!=null?btncss.registerRegisterText:t('prereg:btn-regist')}</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PreRegistrationContainer;
