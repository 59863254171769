import React from 'react';

function FooterLogin({ lcopyright }) {
  return (
    <>
      <p className="login-footer" dangerouslySetInnerHTML={{__html:lcopyright}}></p>
      {/*
      <a href="#!" className="btn btn-line service-modal">Terms of Services</a>
      <a href="#!" className="btn btn-line privacy-modal">Privacy Policy</a>
      <a href="#!" className="btn btn-line cookie-modal">Cookie Policy</a>
      */}
    </>           
  );
}

export default FooterLogin