import { useTranslation } from 'react-i18next';
import { printValidate } from '../lib/wbUtils';

function Surveyques({ quesitem, handleRadioChange, handleChange, register, errors, isMobile }) {
 
  const { t }  = useTranslation([]);

  const { surveyquesidx, credt, disporder, ques, quesitems, questp, reqtp } = quesitem;

  return (
    <>
      <li key={surveyquesidx}>
        <p><span>Q</span>{ques} {questp !== null && questp !== undefined && questp === "020" ? "( 복수 선택 가능 )" : ""}</p>
        
        {errors.singleChoice!==null && errors.singleChoice!==undefined && Object.keys(errors).length>0 && printValidate(errors?.singleChoice[surveyquesidx], "field value")}
        {errors.essay!==null && errors.essay!==undefined &&Object.keys(errors).length>0 && printValidate(errors?.essay[surveyquesidx], "field value")}
        {errors.multiChoice!==null && errors.multiChoice!==undefined &&Object.keys(errors).length>0 && printValidate(errors?.multiChoice[surveyquesidx], "field value")}
        
        {questp !== null && questp !== undefined && questp === "010" ?
          <>
            <ul className={isMobile?'m-survey-multi':'survey-multi'}>
              {
                quesitems !== null && quesitems !== undefined &&
                quesitems.map((quesitem, index) => {
                  return (
                      <li key={index}><input type="radio" key={quesitem.quesitemidx} name={`singleChoice.${surveyquesidx}`} questp={questp}
                        value={quesitem.quesitemidx} surveyquesidx={surveyquesidx}
                        {...register(`singleChoice.${surveyquesidx}`,{ required: reqtp==='010'?true:false })}
                        onChange={handleChange} 
                      /> {quesitem.item}</li>
                  )
                })
              }
            </ul>
          </>
          : null}
        {questp !== null && questp !== undefined && questp === "020" ?
          <>
            <ul className={isMobile?'m-survey-multi':'survey-multi'}>
              {
                quesitems !== null && quesitems !== undefined &&
                quesitems.map((quesitem, index) => {
                  return (
                      <li key={index}><input type="checkbox" key={quesitem.quesitemidx} name={`multiChoice.${surveyquesidx}`} questp={questp}
                        value={quesitem.quesitemidx} surveyquesidx={surveyquesidx}
                        {...register(`multiChoice.${surveyquesidx}`,{ required: reqtp==='010'?true:false })} 
                        onChange={handleRadioChange} 
                        /> {quesitem.item}</li>
                  )
                })
              }
            </ul>
          </>
          : null}
        {questp !== null && questp !== undefined && questp === "030" ?
          <>
            <div className={isMobile?'m-survey-single':'survey-single'}><textarea name={`essay.${surveyquesidx}`} 
            questp={questp} placeholder={t('survey:essay-answer')} style={{ resize: "none" }} surveyquesidx={surveyquesidx}
            {...register(`essay.${surveyquesidx}`,{ required: reqtp==='010'?true:false })} 
            onChange={handleChange} 
            ></textarea>
            </div>
          </>
          : null}
      </li>
    </>
  )
  
}

export default Surveyques;