import { call, put, takeEvery } from 'redux-saga/effects';
import * as LivesAPI from '../api/lives'; // api/lives 안의 함수 모두 불러오기
import {
  handleAsyncActions, reducerUtils
} from '../lib/asyncUtils';

/* 액션 타입 */

// 라이브정보 조회하기
const GET_LIVE = 'GET_LIVE';                    // 요청 시작
const GET_LIVE_SUCCESS = 'GET_LIVE_SUCCESS';    // 요청 성공
const GET_LIVE_ERROR = 'GET_LIVE_ERROR';        // 요청 실패

// QNA목록
const GET_LIVEQNAS = 'GET_LIVEQNAS';
const GET_LIVEQNAS_SUCCESS = 'GET_LIVEQNAS_SUCCESS';
const GET_LIVEQNAS_ERROR = 'GET_LIVEQNAS_ERROR';

// QNA등록
const POST_LIVEQNA = 'POST_LIVEQNA';
const POST_LIVEQNA_SUCCESS = 'POST_LIVEQNA_SUCCESS';
const POST_LIVEQNA_ERROR = 'POST_LIVEQNA_ERROR';

// 좋아요하기
const POST_LIKE = 'POST_LIKE';
const POST_LIKE_SUCCESS = 'POST_LIKE_SUCCESS';
const POST_LIKE_ERROR = 'POST_LIKE_ERROR';

// vod access log 기록하기
const POST_ACCESS = 'POST_ACCESS';
const POST_ACCESS_SUCCESS = 'POST_ACCESS_SUCCESS';
const POST_ACCESS_ERROR = 'POST_ACCESS_ERROR';

const SAVE_ONAIRSTS = "SAVE_ONAIRSTS";

export const getLive = (webinaridx,infotp) => ({ type: GET_LIVE, param:{webinaridx,infotp} });
export const getLiveQnas = (webinaridx) =>  ({ type: GET_LIVEQNAS, param:{webinaridx} });
export const postLiveQna = (data) => ({ type: POST_LIVEQNA, param:{data} });
export const postLike = (data) => ({ type: POST_LIKE, param:{data} })
export const postAccess = (data) => ({ type: POST_ACCESS, param:{data} });
export const saveOnAirSts = (onairsts) => ({ type: SAVE_ONAIRSTS, payload:{onairsts}});

function* getLiveSaga(action) {
  const param = action.param;
  try {
    const live = yield call(LivesAPI.getLive, param); // call 을 사용하면 특정 함수를 호출하고, 결과물이 반환 될 때까지 기다려줄 수 있습니다.
    yield put({
      type: GET_LIVE_SUCCESS,
      payload: live
    }); // 성공 액션 디스패치
  } catch (e) {
    yield put({
      type: GET_LIVE_ERROR,
      error: true,
      payload: e
    }); // 실패 액션 디스패치
  }
}

function* getLiveQnasSaga(action) {
  const param = action.param;
  try {
    const liveqnas = yield call(LivesAPI.getLiveQnas, param); 
    yield put({
      type: GET_LIVEQNAS_SUCCESS,
      payload: liveqnas
    }); // 성공 액션 디스패치
  } catch (e) {
    yield put({
      type: GET_LIVEQNAS_ERROR,
      error: true,
      payload: e
    }); // 실패 액션 디스패치
  }
}

// 채널등록 사가~
function* postLiveQnaSaga(action) {
  const param = action.param;
  try {
    const liveqna = yield call(LivesAPI.postLiveQna, param); 
    yield put({
      type: POST_LIVEQNA_SUCCESS,
      payload: liveqna
    });
  } catch (e) {
    yield put({
      type: POST_LIVEQNA_ERROR,
      error: true,
      payload: e
    });
  }
}

// 좋아요등록 사가
function* postLikeSaga(action) {
  console.log('@@postLikeSaga', action);
  const param = action.param;
  try {
    const Poll = yield call(LivesAPI.postLike, param); // API 함수에 넣어주고 싶은 인자는 call 함수의 두번째 인자부터 순서대로 넣어주면 됩니다.
    yield put({
      type: POST_LIKE_SUCCESS,
      payload: Poll
    });
  } catch (e) {
    yield put({
      type: POST_LIKE_ERROR,
      error: true,
      payload: e
    });
  }
}

// 접근로그 사가
function* postAccessSaga(action) {
  console.log('@@postAccessSaga', action);
  const param = action.param;
  try {
    const access = yield call(LivesAPI.postAccess, param); // API 함수에 넣어주고 싶은 인자는 call 함수의 두번째 인자부터 순서대로 넣어주면 됩니다.
    yield put({
      type: POST_ACCESS_SUCCESS,
      payload: access
    });
  } catch (e) {
    yield put({
      type: POST_ACCESS_ERROR,
      error: true,
      payload: e
    });
  }
}

// 사가들을 합치기
export function* livesSaga() {
  yield takeEvery(GET_LIVE, getLiveSaga);
  yield takeEvery(GET_LIVEQNAS, getLiveQnasSaga)
  yield takeEvery(POST_LIVEQNA, postLiveQnaSaga)
  yield takeEvery(POST_LIKE, postLikeSaga);
  yield takeEvery(POST_ACCESS, postAccessSaga);
}

// initialState 쪽도 반복되는 코드를 initial() 함수를 사용해서 리팩토링 했습니다.
const initialState = {
  live: reducerUtils.initial(),
  liveqnas : reducerUtils.initial(),
  likes : reducerUtils.initial(),
  accessdone : reducerUtils.initial(),
};

export default function lives(state = initialState, action) {
  switch (action.type) {
    case GET_LIVE:
    case GET_LIVE_SUCCESS:
    case GET_LIVE_ERROR:
      return handleAsyncActions(GET_LIVE, 'live', true)(state, action);
    case GET_LIVEQNAS:
    case GET_LIVEQNAS_SUCCESS:
    case GET_LIVEQNAS_ERROR:
      return handleAsyncActions(GET_LIVEQNAS, 'liveqnas', true)(state, action);
    case POST_LIVEQNA:
    case POST_LIVEQNA_SUCCESS:
    case POST_LIVEQNA_ERROR:
      return handleAsyncActions(POST_LIVEQNA, 'liveqnas', true)(state, action);
    case POST_LIKE:
    case POST_LIKE_ERROR:
      // like 를 눌렀을때 에러가 발생했을때 어떻게 처리해야 할지...
      return state;
    case POST_LIKE_SUCCESS:
      // like 를 누르고 나면 숫자를 반환받아서 live 의 likecnt 를 업데이트해준다.
      return {...state, 'live':{...state.live, 'data':{...state.live.data, 'map':{...state.live.data.map, 'likecnt':action.payload.map.likecnt}}}}
    case POST_ACCESS:
    case POST_ACCESS_SUCCESS:
    case POST_ACCESS_ERROR:
      return handleAsyncActions(POST_ACCESS, 'accessdone', true)(state, action);
    case SAVE_ONAIRSTS:
      // onairsts 값을 업데이트해준다.
      return {...state, 'live':{...state.live, 'data':{...state.live.data, 
        'map':{...state.live.data.map, 'info':{...state.live.data.map.info, 'onairsts':action.payload.onairsts}}}}}
    default:
      return state;
  }
}