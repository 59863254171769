import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import BodyClassName from 'react-body-classname';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isNotNull } from '../lib/wbUtils';
import { putLoginLinksium, saveLogin } from '../modules/login';
;

/**
 * 폼서밋으로 linksium,m2m,mundipharma 등에서 자동가입과 함께 로그인 시킬때 사용함.
 * @param {*} param0 
 * @returns 
 */
function LoginLinksiumContainer({enckey, channelidx, linkinfoidx, webinaridx, liveservermapidx})  {

  const { data, loading, error } = useSelector(state => (state.modLogin.logininfo))
  const { usrtoken } = useSelector(state => (state.modLogin.loginusr))
  let history = useHistory();

  const dispatch = useDispatch();
  let cnt = 0;
  const defLogin = {'loginid':'','loginpwd':''};
  const [login, setLogin] = useState(defLogin);
  const [bodycss, setBodycss] = useState();
  const [titleText, setTitleText] = useState('');
  const [linkinfo, setLinkinfo] = useState();

  // 로그인처리
  const handleLoginJoinProc = () => {
    console.log('>>handleLoginJoinProc');
    if (cnt === 0) {
      cnt++;
      dispatch(putLoginLinksium({'enckey':enckey,'channelidx':channelidx,'linkinfoidx':linkinfoidx}));
    }
  }

  useEffect(() => {
    console.log('useEffect',data,usrtoken);

    if (error) {
      toast.error(`Login fail : ${error.message}`);
      return;
    }

    // ...
    //console.log('getDerivedStateFromProps',nextProps,prevState);
    //console.log('logininfo1',prevState.modLogin.logininfo);
    //console.log('logininfo2',nextProps.modLogin.logininfo);
    if (isNotNull(data)) {
      if (data.result_cd===1) {
        console.log('login success!!',data.map);
        const {ptcpntemail, ptcpntidx, ptcpntnm, ptcpnthp, company, dept, usrtoken, webinars, logos, linkinfo} = data.map;
        window.sessionStorage.setItem('ptcpntemail', ptcpntemail);
        window.sessionStorage.setItem('ptcpntidx', ptcpntidx);
        window.sessionStorage.setItem('ptcpntnm', ptcpntnm);
        window.sessionStorage.setItem('ptcpnthp', ptcpnthp);
        window.sessionStorage.setItem('usrtoken', usrtoken);
        window.sessionStorage.setItem('company', company);
        window.sessionStorage.setItem('dept', dept);
        setLogin(defLogin);
        setLinkinfo(linkinfo);
        //console.log('@@@webinaridxx',webinaridx,'liveservermapidx',liveservermapidx);
        if (!isNaN(liveservermapidx) && !isNaN(webinaridx)) {
          // VOD로 바로 이동
          setTitleText('VOD로 이동합니다. <br/><span>잠시만 기다려 주세요.</span>');
          dispatch(saveLogin(ptcpntemail,ptcpntidx,ptcpntnm,usrtoken))
          setTimeout(function(){window.location.replace('/webinars/vod/'+webinaridx+'/'+liveservermapidx)},500);
        } else if (!isNaN(webinaridx)) {
          // 웨비나로 바로 이동
          setTitleText('웨비나로 이동합니다. <br/><span>잠시만 기다려 주세요.</span>');
          dispatch(saveLogin(ptcpntemail,ptcpntidx,ptcpntnm,usrtoken))
          // history.push 로 이동할 경우 bodycss 가 적용이 안되서 location 이동으로 수정함
          setTimeout(function(){window.location.replace('/webinars/live/'+webinaridx)},500);
        } else if (webinars.length === 1) {
          // 어디론가 이동...
          console.log('move~~~!!');
          setTitleText('웨비나로 이동합니다. <br/><span>잠시만 기다려 주세요.</span>');
          dispatch(saveLogin(ptcpntemail,ptcpntidx,ptcpntnm,usrtoken))
          // history.push 로 이동할 경우 bodycss 가 적용이 안되서 location 이동으로 수정함
          setTimeout(function(){window.location.replace('/webinars/live/'+webinars[0].webinaridx)},500);
        } else if (webinars.length > 1) {
          // 화면에 선택할 수 있도록 보여줌.
          setTitleText('웨비나가 동시 진행되고 있습니다. <br/><span>아래에서 시청하실 웨비나를 선택해주세요.</span>');
          webinars.map((c, index) => {
            if (isNotNull(c.bodycss)) {
              // 여기의 bodycss 는 링크지움에 걸려있는 웨비나 목록을 보여주는 페이지에 적용되는 bodycss 임.
              setBodycss(c.bodycss);
            }
          });
        } else {
          // fail
          //history.push('/notfound/Webinar')
        }
      } else {
        toast.error(`Login fail : ${data.result_msg}`);
        console.log('login fail!!', data.result_msg);
      }
    } else {
      setTimeout(handleLoginJoinProc,1000);
    }
  },[data, error])

  /**
   * 웨비나를 클릭했을때 이동
   */
  const handleGoWebinar = (e) => {
    if (isNotNull(data)) {
      if (data.result_cd===1) {
        console.log('login success!!',data.map);
        const {ptcpntemail, ptcpntidx, ptcpntnm, ptcpnthp, usrtoken, webinars, logos} = data.map;
        // 로그인정보 저장 및 로그인폼정보 초기화
        dispatch(saveLogin(ptcpntemail,ptcpntidx,ptcpntnm,usrtoken))
      }
    }
    const widx = e.currentTarget.getAttribute('webinaridx');
    // history.push 로 이동할 경우 bodycss 가 적용이 안되서 location 이동으로 수정함
    setTimeout(function(){window.location.replace('/webinars/live/'+widx)},500);
    return false;
  }

  const useStyles = makeStyles({
    bodybg: {
      background: `${bodycss} !important`
    }
  })
  const classes = useStyles();

  return (
    <BodyClassName className={`gradient-body ${bodycss!==null&&bodycss!==undefined&&classes.bodybg}`}>
    <>
    <div className="logo-list-wrap">
    {
      isNotNull(data) && isNotNull(data.map) && isNotNull(data.map.logos) &&
        data.map.logos.map((c, index) => {
          return (            
            <img src={c.fileuri} key={index}/>
          )
      })}
    </div>
    <div className="webinar-list-wrap">
        <ul className="list-box-wrap">
          <li dangerouslySetInnerHTML={ {__html: titleText} }>
          </li>
            {
              isNotNull(data) && isNotNull(data.map) && isNotNull(data.map.webinars) && data.map.webinars.length > 1 &&
                data.map.webinars.map((c, index) => {
                  return (            
            <li key={index}>
                <a href="#" className="list-box" onClick={handleGoWebinar} webinaridx={c.webinaridx}>
                    <div className="content">
                        <h3 className={index%2===0?'blue':'red'}>{c.webinarnm}</h3>
                        <p>{c.webinardscr}</p>
                        <div className="img">
                            <span>Live</span>
                            <img src={c.banneruri} style={{'height':'210px'}} webinaridx={c.webinaridx}/>
                        </div>
                    </div>
                </a>
            </li>
                  )
           })}

        </ul>
    </div>
    <p className="list-footer">{isNotNull(linkinfo)?linkinfo.copyright:''}</p>
    </>
    </BodyClassName>
  );
}

export default withRouter(LoginLinksiumContainer);
