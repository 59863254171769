import { Clear } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearpostSendCode, postSendCode } from '../modules/ptcpnts';

function PasswordResetPopup({ togglePasswordResetPopup, webinaridx }){
  const dispatch = useDispatch();
  const [bean, setBean] = useState({
    webinaridx: webinaridx,
    location:window.location.origin
  });
  const [style, setStyle] = useState({
    display:'none'
  })

  const { data, loading, error } = useSelector(state => ({
    data:state.modPtcpnt.sendcodedone.data
    ,loading:state.modPtcpnt.sendcodedone.loading
    ,error:state.modPtcpnt.sendcodedone.error
  }))

  const handleCheckEmail = () => {
    dispatch(postSendCode(bean));
  }

  const handleChange = (e) => {
    setBean({...bean, [e.target.name]:e.target.value});
  }

  useEffect(() => {
    if (data !== null && data.result_cd !== null) {
      if (data.result_cd===1) {
        dispatch(clearpostSendCode(bean));
        // 이동~
        setTimeout(function(){togglePasswordResetPopup(true)},500);
      } else {
        setStyle({display:'inline'})
      }
    }
  },[dispatch, data]);

  return (
    <>
    <div className="modal" id="reset-modal">
      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable reset-modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <p className="reset-modal-title">Password Reset</p>
            <Clear className="modal-close material-icons cursorpointer" onClick={togglePasswordResetPopup}/>
          </div>
          <div className="reset-modal-body">
            <div className="reset-modal-wrap">
                <p className="reset-error-message" style={style}>
                  <i className="inline-icon material-icons">error</i>
                  Invalid user email
                </p>
                {/* <p className="reset-verified-message">
                  <i className="inline-icon material-icons">check</i>
                  Verified
                </p> */}
                <p className="reset-title">Email</p>
                <div className="reset-input-wrap">
                  <input type="text" className="type05" name="ptcpntemail" onBlur={handleChange}/></div>
                <div className="reset-btn-wrap">
                  <button href="#!" className="btn-login" onClick={togglePasswordResetPopup}>Cancel</button>
                  <button className="btn-register" href="#" onClick={handleCheckEmail}>Send Email</button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default PasswordResetPopup;