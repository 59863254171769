import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isNotNull } from '../lib/wbUtils';
import { getLoginShortcut } from '../modules/login';

/**
 * 회원가입이 이미 되어있다는 가정 하에, 특정 웨비나로 자동 이동할때 사용함(이브아르,갈더마등 부스에서 웨비나 이동시)
 * @param {*} param0 
 * @returns 
 */
function LoginShortcutContainer({shortcut})  {

  const { data, error } = useSelector(state => (state.modLogin.shortcut))
  let history = useHistory();

  const dispatch = useDispatch();
  let cnt = 0;

  // 로그인처리
  const handleShortcutProc = (val) => {
    console.log('@@shortcut',val);
    if (cnt === 0) {
      dispatch(getLoginShortcut(val));
    }
    cnt++;
  }

  useEffect(() => {
    console.log('useEffect',data, shortcut);

    if (error) {
      toast.error(`Login fail : ${error.message}`);
      return;
    }

    if (isNotNull(data)) {
      if (data.result_cd===1) {
        console.log('move~~~!!',data);
        setTimeout(function(){history.push('/login/'+data.map.info.webinaridx)},100);
      } else {
        toast.error(`No webinar found : ${data.result_msg}`);
        console.log('No webinar found', data.result_msg);
      }
    } else {
      handleShortcutProc(shortcut);
    }
  },[dispatch, data, error])

  return (
    <div>
    </div>
  );
}

export default withRouter(LoginShortcutContainer);
