import React, { useEffect, useState, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import LoginPage from './pages/LoginPage';
import LoginLinkPage from './pages/LoginLinkPage';
import LoginJoinPage from './pages/LoginJoinPage';
import LoginLinksiumPage from './pages/LoginLinksiumPage';
import LoginShortcutPage from './pages/LoginShortcutPage';
import ParticipatedWebinarsPage from './pages/ParticipatedWebinarsPage';
//import TestPage from './pages/TestPage';
import WebinarLivePage from './pages/WebinarLivePage';
import WebinarGatewayPage from './pages/WebinarGatewayPage';
import WebinarVodListPage from './pages/WebinarVodListPage';
import WebinarVodPage from './pages/WebinarVodPage';
import WebinarVodTestPage from './pages/WebinarVodTestPage';
import PrompterPage from './pages/PrompterPage';
import PreRegisterPage from './pages/PreRegisterPage';
import PasswordResetPage from './pages/PasswordResetPage';
import { ToastContainer } from 'react-toastify';
import Popup from 'react-popup';
import { saveLogin }from './modules/login';
import NotFound from './pages/NotFound';
import NotOnAir from './pages/NotOnAir';
import { getWebinar } from './modules/login';
import { useLocation } from "react-router-dom";
import { isNull, isNotNull } from './lib/wbUtils';
import i18n from "i18next";
import PollQRPage from './pages/PollQRPage';

function App() {

  const dispatch = useDispatch();
  const { loginusr, webinar } = useSelector(state => (
    {
      loginusr:state.modLogin.loginusr
      ,webinar:state.modLogin.webinar.data
    }
    ));
  const [usrtoken, setUsrtoken] = useState(); 
  const [bodycss, setBodycss] = useState(null);
  const [lbodycss, setLbodycss] = useState(null);
  const [lcopyright, setLcopyright] = useState(null);
  const [theme, setTheme] = useState(null);
  

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  };
  
  let query = useQuery();
  let webinaridx;
  const webinaridxpath = ['login','live'];

  // 컴포넌트 마운트 후 
  useEffect(() => {
    console.log('@@@@@');
    if (loginusr !== null && loginusr !== undefined) {
      setUsrtoken(loginusr.usrtoken);
    }
    if (usrtoken === null || usrtoken === undefined) {
      const tok = window.sessionStorage.getItem('usrtoken');
      if ( tok !== null) {
        const uid = window.sessionStorage.getItem('uid');
        const uidx = window.sessionStorage.getItem('uidx');
        const unm = window.sessionStorage.getItem('unm');
        // 로그인정보 저장 및 로그인폼정보 초기화
        //setTimeout(()=>{dispatch(saveLogin(uid,uidx,unm,tok))},1000)
        dispatch(saveLogin(uid,uidx,unm,tok))
      }
    }

    if (isNotNull(query.get("webinaridx"))) {
      webinaridx = query.get("webinaridx");
    } else {
      let pathes = window.location.pathname.toString().split("/");
      if (webinaridxpath.indexOf(pathes[pathes.length-2]) > -1) {
        // 이 조건을 추가한 이유는.. webinaridx 가 포함되지 않은 경로에서도 webinaridx 를 path 에서 잘라서 사용하기 때문.
        webinaridx = pathes[pathes.length-1];
      }
    }
  
    if (isNull(webinar) && isNotNull(webinaridx)) {
      // live 가 아니라 vod 일때의 이동을 위해 login 페이지에서 필요하므로 11 을 호출함.
      dispatch(getWebinar(webinaridx,0b00000011));
    } else if (isNotNull(webinar) && isNotNull(webinar.map)){
      if (isNull(webinar.map.info)) {
        // 정보를 가져왔는데 웨비나 정보가 존재하지 않을때.. (삭제되는 등..)
        //history.push('/notfound/Webinar');
        setTheme(process.env.REACT_APP_THEME_LIGHT)
      } else {
        setBodycss(webinar.map.info.bodycss);
        // 모바일 배경 따로 작업할경우... 
        // if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        //   setBodycss(webinar.map.info.lbodycss);
        // }else{
        //   setBodycss(webinar.map.info.bodycss);
        // }
        setLbodycss(webinar.map.info.lbodycss);
        setLcopyright(webinar.map.info.lcopyright);
        if (isNull(webinar.map.info.stylenm)) {
          setTheme(process.env.REACT_APP_THEME_LIGHT)
        } else {
          setTheme(webinar.map.info.stylenm);
        }
        i18n.changeLanguage(webinar.map.info.lang)
      }
    } else {
      // 웨비나 정보가 없는 경우에
      setTheme(process.env.REACT_APP_THEME_LIGHT)
    }
  }, [dispatch,webinar]);

  // 토스트 변경시 아래 URL 을 참고하세요
  //https://fkhadra.github.io/react-toastify/introduction

  // useEffect(()=>{
  //   if (theme===process.env.REACT_APP_THEME_DARK){
  //     import(`./html/css/total-style.css`);
  //     import(`./html/css/style-extends.css`);
  //   } else if (theme===process.env.REACT_APP_THEME_LIGHT){
  //     import(`./html/css/total-style_white.css`);
  //     import(`./html/css/style-extends_white.css`);
  //   }
  // },[theme])

  const LightTheme = React.lazy(() => import('./Themes/LightTheme'));
  const DarkTheme = React.lazy(() => import('./Themes/DarkTheme'));

  return (
    isNotNull(theme)?
    <>
      <Suspense fallback={<></>}>
        {isNotNull(theme)?(theme === process.env.REACT_APP_THEME_DARK?<DarkTheme/>:<LightTheme/>):null}
      </Suspense>
      <ToastContainer
        position="bottom-left"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
      <Popup/>
      <Switch>
        <Route path="/ch/:shortcut" render={(props)=><LoginShortcutPage {...props} bodycss={lbodycss} lcopyright={lcopyright}/>} exact={true} />
        <Route path="/login/:webinaridx" render={(props)=><LoginPage {...props} bodycss={lbodycss} lcopyright={lcopyright}/>} exact={true} />
        <Route path="/loginpoll/:webinaridx" render={(props)=><LoginPage {...props} bodycss={lbodycss} lcopyright={lcopyright} isQr={true} loginto={'true'}/>} exact={true} />
        {/* 회원가입이 이미 되어있다는 가정 하에, 특정 웨비나로 자동 이동할때 사용함(이브아르,갈더마등 부스에서 웨비나 이동시) */}

        <Route path="/pollqr/:webinaridx" render={(props)=><PollQRPage {...props} bodycss={lbodycss} lcopyright={lcopyright}/>} exact={true} />

        <Route path="/loginlink" component={LoginLinkPage} exact={true} />
        <Route path="/loginjoin" component={LoginJoinPage} exact={true} />
        {/* 폼서밋으로 linksium,m2m,mundipharma 등에서 자동가입과 함께 로그인 시킬때 사용함. */}
        <Route path="/gateway/common" component={LoginLinksiumPage} exact={true} />
        <Route path="/login/webinars" component={ParticipatedWebinarsPage} exact={true} />
        <Route path="/pre-registration" render={(props)=><PreRegisterPage {...props} bodycss={bodycss} lcopyright={lcopyright}/>} exact={true}/>
        <Route path="/prompter/:webinaridx" component={PrompterPage} />
        <Route authenticated={usrtoken !== null} path="/webinars/gateway/:webinaridx" 
          render={(props)=><WebinarGatewayPage {...props} bodycss={bodycss}/>} exact={true}/>
        <Route authenticated={usrtoken !== null} path="/webinars/live/:webinaridx" 
          render={(props)=><WebinarLivePage {...props} bodycss={bodycss}/>} exact={true}/>
        <Route authenticated={usrtoken !== null} path="/webinars/vodlist/:webinaridx" 
          render={(props)=><WebinarVodListPage {...props} bodycss={bodycss}/>} exact={true}/>
        <Route authenticated={usrtoken !== null} path="/webinars/vod/:webinaridx/:liveservermapidx" 
          render={(props)=><WebinarVodPage {...props} bodycss={bodycss}/>} exact={true}/>
        <Route authenticated={usrtoken !== null} path="/webinars/vodtest" component={WebinarVodTestPage} />
        <Route path="/notonair/:webinaridx" component={NotOnAir} />
        <Route path="/notfound/:what" component={NotFound} />
        {/* <Route path="/:webinaridx" render={(props)=><LoginPage {...props} bodycss={bodycss}/>} exact={true} /> */}
        {/** 비 밀 번 호 변 경 */}
        <Route path="/auth/verify_email" component={PasswordResetPage} exact={true}/>
        <Route component={NotFound} />

      </Switch>
    </>
    :null
  );
}

export default App;
