import React from "react";
import { printValidate } from '../lib/wbUtils'

function PtcpntAddedTerms({ ptcpntterms, handleCheckItem, register, errors, added, getValues }) {
  added = new Map(Object.entries(added));
  return (
    <>
      {ptcpntterms !== null &&
        ptcpntterms !== undefined &&
        ptcpntterms.length > 0 &&
        ptcpntterms.map((t, index) => {
          return (
            <>
              <p>{t.termssubject}</p>
              <div className="agree-box modify-scroll" dangerouslySetInnerHTML={{__html: t.termscont}}></div>
              <ul className="agree">
                <li>
                  <input type="checkbox" className="terms-chk"
                   {...register(`added.termsagr0${t.disporder}`,{ validate:{
                    required : value =>{
                      if(t.reqyn==='Y') return value;
                    }
                   } })}
                  name={`added.termsagr0${t.disporder}`}
                  onChange={handleCheckItem}/>
                  {t.termsagrment}
                  <br/>
                </li>
                <li>
                  {errors.added!==undefined 
                  &&printValidate(new Map(Object.entries(errors.added)).get(`termsagr0${t.disporder}`), 'To agree the terms and conditions')}
                </li>
              </ul>
            </>
          );
        })}
    </>
  );
}

export default PtcpntAddedTerms;
