import { call, put, takeEvery } from 'redux-saga/effects';
import * as surveyAPI from '../api/survey'; // api/ques 안의 함수 모두 불러오기
import {
  handleAsyncActions, reducerUtils
} from '../lib/asyncUtils';

/* 액션 타입 */

// 설문질문 조회하기
const GET_SURVEY = 'GET_SURVEY';                    // 요청 시작
const GET_SURVEY_SUCCESS = 'GET_SURVEY_SUCCESS';    // 요청 성공
const GET_SURVEY_ERROR = 'GET_SURVEY_ERROR';        // 요청 실패

const POST_SURVEY = 'POST_SURVEY';
const POST_SURVEY_SUCCESS = 'POST_SURVEY_SUCCESS';
const POST_SURVEY_ERROR = 'POST_SURVEY_ERROR';

const CLEAR_POST_SURVEY = 'CLEAR_POST_SURVEY';
const CLEAR_GET_SURVEY = 'CLEAR_GET_SURVEY';

export const getSurvey = (webinaridx, parenttp) => ({ type: GET_SURVEY, param: { webinaridx, parenttp } });
export const postSurvey = (data) => ({ type: POST_SURVEY, param: { data } })
export const clearPostSurvey = () => ({ type: CLEAR_POST_SURVEY });
export const clearGetSurvey = () => ({ type: CLEAR_GET_SURVEY });

function* getSurveySaga(action) {
  const param = action.param;
  try {
    const survey = yield call(surveyAPI.getSurvey, param); // call 을 사용하면 특정 함수를 호출하고, 결과물이 반환 될 때까지 기다려줄 수 있습니다.
    yield put({
      type: GET_SURVEY_SUCCESS,
      payload: survey
    }); // 성공 액션 디스패치
  } catch (e) {
    yield put({
      type: GET_SURVEY_ERROR,
      error: true,
      payload: e
    }); // 실패 액션 디스패치
  }
}

function* postSurveySaga(action) {
  const param = action.param;
  try {
    const survey = yield call(surveyAPI.postSurvey, param); // call 을 사용하면 특정 함수를 호출하고, 결과물이 반환 될 때까지 기다려줄 수 있습니다.
    yield put({
      type: POST_SURVEY_SUCCESS,
      payload: survey
    }); // 성공 액션 디스패치
  } catch (e) {
    yield put({
      type: POST_SURVEY_ERROR,
      error: true,
      payload: e
    }); // 실패 액션 디스패치
  }
}

// 사가들을 합치기
export function* surveySaga() {
  yield takeEvery(GET_SURVEY, getSurveySaga);
  yield takeEvery(POST_SURVEY, postSurveySaga);
}

// initialState 쪽도 반복되는 코드를 initial() 함수를 사용해서 리팩토링 했습니다.
const initialState = {
  survey: reducerUtils.initial(),
  surveyansdone: reducerUtils.initial()
};

export default function survey(state = initialState, action) {
  switch (action.type) {
    case GET_SURVEY:
    case GET_SURVEY_SUCCESS:
    case GET_SURVEY_ERROR:
      return handleAsyncActions(GET_SURVEY, 'survey', true)(state, action);
    case POST_SURVEY:
    case POST_SURVEY_SUCCESS:
    case POST_SURVEY_ERROR:
      return handleAsyncActions(POST_SURVEY, 'surveyansdone', true)(state, action);
    case CLEAR_POST_SURVEY:
      return { ...state, surveyansdone: reducerUtils.initial() };
    case CLEAR_GET_SURVEY:
      return { ...state, survey: reducerUtils.initial() };
    default:
      return state;
  }
}