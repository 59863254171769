import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNotNull } from '../lib/wbUtils';
import { getWebinar } from '../modules/login';

/**
 * 로그인 후 설정에 따라 게이트웨이 페이지를 사용할 경우 보여주는 화면
 * @param {*} param0 
 * @returns 
 */
function WebinarGatewayContainer({webinaridx, history, isMobile}) {

  const { webinar } = useSelector(state => ({
    webinar:state.modLogin.webinar.data
  }))

  const dispatch = useDispatch();

  const [gwpagehtml,setGwpagehtml] = useState();

  const goLive = () => {
    console.log('go live!!');
    setTimeout(function(){history.push('/webinars/live/'+webinaridx)},500);
  }
  const goVod = () => {
    console.log('go vod!!');
    setTimeout(function(){history.push('/webinars/vod/'+webinaridx)},500);
  }

  useEffect(() => {
    if (isNotNull(webinar)) {
      if (isMobile) 
        setGwpagehtml(webinar.map.info.gwpagehtmlm)
      else 
        setGwpagehtml(webinar.map.info.gwpagehtml)
    } else {
      setTimeout(function(){dispatch(getWebinar(webinaridx,0b00000011));},500);
    }
  },[dispatch, webinar]);
  useEffect(() => {
    // html 안에 live 로 이동할 링크에 id 값을 __golive 
    // html 안에 vod 로 이동할 링크에 id 값을 __govod
    // 라고 지정해 주어야 한다. 리액트이외의 다른 이동에 대해서는 a href target=_blank 사용할것.
    if (isNotNull(document.getElementById('__golive'))) {
      document.getElementById('__golive').addEventListener('click', goLive);
    }
    if (isNotNull(document.getElementById('__govod'))) {
      document.getElementById('__govod').addEventListener('click', goVod);
    }
  },[gwpagehtml]);

  const pcjsx = (
    <>
    <div dangerouslySetInnerHTML={ {__html: gwpagehtml} }>
    </div>
    </>
  );
  const mobilejsx = (
    <>
    <div id="m-vertical-mode" dangerouslySetInnerHTML={ {__html: gwpagehtml} }>
    </div>
    </>
  );
  if (isMobile) return mobilejsx;
  else return pcjsx;
}

export default WebinarGatewayContainer;