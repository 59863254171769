import React from 'react';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';


function NotFound({ match }) {
  const { what } = match.params; // URL 파라미터 조회하기
  return (
    <div>
      <div className="main-wrap">
        <div className="error-wrap">
          <SentimentVeryDissatisfiedIcon className="material-icons" />
          <h3>{what === null? '404':what} Not Found</h3>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
