import { makeStyles } from '@material-ui/styles';
import React from 'react';
import BodyClassName from 'react-body-classname';
import { isMobile } from "react-device-detect";
import WebinarVodContainer from '../containers/WebinarVodContainer';

function WebinarVodPage({ match, history, bodycss }) {

  const { webinaridx, liveservermapidx } = match.params; // URL 파라미터 조회하기
  const useStyles = makeStyles({
    bodybg: {
      background: `${bodycss} !important`
    }
  })

  const classes = useStyles();
  return (
    <BodyClassName className={`${bodycss!==null&&bodycss!==undefined?classes.bodybg:''}`}>
     <>
       <WebinarVodContainer 
       webinaridx={parseInt(webinaridx, 10)} 
       liveservermapidx={parseInt(liveservermapidx, 10)} 
       history={history} isMobile={isMobile}/>
     </>
     </BodyClassName>   
   )
}

export default WebinarVodPage;
