import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Script from 'react-script-tag';
import { checkWebinarPlugin, isNotNull, isNull, webinarPluginCode } from '../lib/wbUtils';
import { getPresurveys, putPresurvey } from '../modules/presurvey';

function LiveChatDiv({webinaridx, info, isMobile, execPollidx, chatHistory, pushedPresurvey, newChatMessage}) {

  const dispatch = useDispatch();

  const { t }  = useTranslation(['login']);

  const scrollRef = useRef();
  const textboxStyle = {
		resize : 'none'
  }

  const { data } = useSelector(state => ({
    data:state.modPresurvey.presurveys.data
  }));
  const [selectedDiv,setSelectedDiv] = useState('chat');
  const [useChat,setUseChat] = useState(true);
  const [useQA,setUseQA] = useState(true);
  const [presurveys,setPresurveys] = useState();
  const [newPresurvey, setNewPresurvey] = useState(false);
  const [newChat, setNewChat] = useState(false);

  const selectDiv = (divnm) => {
    console.log('>>selectDiv',divnm);
    setSelectedDiv(divnm)
    const bchat = document.getElementById('branchChat');
    const bqa = document.getElementById('branchQA');
    const bodychat = document.getElementById('chatbody');
    const bodyqa = document.getElementById('qabody');
    if (divnm === 'chat') {
      // chat
      if (isNotNull(bchat)) bchat.classList.add("selected");
      if (isNotNull(bqa)) bqa.classList.remove("selected");
      if (isNotNull(bodychat)) bodychat.style.display = 'block';
      if (isNotNull(bodyqa)) bodyqa.style.display = 'none';
      setNewChat(false);
      if (isNotNull(bodychat)) bodychat.scrollTop = bodychat.scrollHeight;
    } else {
      // qa
      if (isNotNull(bchat)) bchat.classList.remove("selected");
      if (isNotNull(bqa)) bqa.classList.add("selected");
      if (isNotNull(bodychat)) bodychat.style.display = 'none';
      if (isNotNull(bodyqa)) bodyqa.style.display = 'block';
      setNewPresurvey(false);
      getPresurveysList();
    }
  }
  // 채팅이나 QABoard 를 클릭했을때.
  const changeDiv = (e) => {
    const br = e.target.getAttribute('data-branch');
    selectDiv(br);
  }

  // QAboard 목록을 가져온다.
  const getPresurveysList = () => {
    console.log('getPresurveysList');
    dispatch(getPresurveys(webinaridx));
  }

  // QAboard 추천
  const putVote = (presurveyidx) => {
    console.log('presurveyidx::',presurveyidx);
    dispatch(putPresurvey(presurveyidx, webinaridx));
  }

  useEffect(() => {
    // 그룹채팅을 사용하는지의 여부
    let uc = false;
    let uq = false;
    if (isNotNull(info)) {
      if (checkWebinarPlugin(webinarPluginCode.LIVECHAT_GROUP,info.plugins) 
        || checkWebinarPlugin(webinarPluginCode.LIVECHAT_OPEN,info.plugins)) {
          uc = true;
      } else { 
        uc = false;
      }
      // 궁금해요 기능을 사용하는지 여부
      checkWebinarPlugin(webinarPluginCode.QABOARD,info.plugins) ? uq = true : uq = false;
      setUseChat(uc);
      setUseQA(uq);
      setTimeout(()=>{
        if (uq  && !uc) {
          selectDiv('qa');  
        } else {
          selectDiv('chat');
        }        
      }, 500)
      
    }
  },[info]);
  useEffect(()=>{

  })

  useEffect(()=>{
    // push 로 받은 궁금해요의 새로운 데이타.
    if (isNotNull(pushedPresurvey)) {
      setPresurveys(pushedPresurvey.presurveys);
      if (pushedPresurvey.commandId === 'presurveyadded' && selectedDiv !== 'qa') {
        // 관리자에서의 추가로 변경되었을 경우, New 아이콘을 띄워준다.=> 오팀장님 요청으로 해당 탭이 아닐때의 조건 추가
        setNewPresurvey(true);
      }
    }
  },[pushedPresurvey]);

  useEffect(()=>{
    console.log('newChatMessage',newChatMessage,'selectedDiv',selectedDiv)
    if (newChatMessage === true && selectedDiv !== 'chat') {
      //=> 오팀장님 요청으로 해당 탭이 아닐때의 조건 추가
      setNewChat(true);
    }
  },[newChatMessage])

  useEffect(() => {
    if (isNull(data) && useQA) {
      getPresurveysList();
    } else if (isNotNull(data) && isNotNull(data.map) && isNotNull(data.map.presurveys)) {
      setPresurveys(data.map.presurveys);
    }
  },[dispatch, data])

  useEffect(() => {
    setTimeout(()=>{
      if (typeof window.write === 'function') {
        if (useChat && chatHistory.length > 0) {
          chatHistory.forEach(function(h) {
            window.write(h.message, 'noticehistory');
          //window.channel.onNotifyMessage(h);
          });
        }
      }
    },1000);
    return () => {
      //console.log('컴포넌트가 화면에서 사라짐');
    };
  }, [chatHistory]);
  const pcjsx = (
    <div className="chat-wrap" style={{display:execPollidx === -1?'block':'none'}}>
      <div className="chat-title">
        {(isNotNull(info) && isNotNull(info.webinarchattitle))?
        <img src={info.webinarchattitle}/>
        :
        <>
        <p>{!isNull(info) ? info.webinarnm:null}</p>
        <p>{!isNull(info) ? info.dscr:null}</p>
        </>
        }
      </div>
      <ul className="chat-body notice modify-scroll">
        <div className="chat-box admin notice">
          <span>{(isNotNull(info) && isNotNull(info.chatnotice))?info.chatnotice:t('chat:pre-notice')}</span>
        </div>
      </ul> 
      <ul className="chat-branch">
        {useChat === true?
        <li className="ch selected" id="branchChat" data-branch="chat" onClick={changeDiv}>Chatting
          {newChat?<img src="/images/new.png" className="new" alt="new" />:null}
        </li>
        :null}
        {useQA === true?
        <li className="qa" id="branchQA" data-branch="qa" onClick={changeDiv}>Q&amp;A Board
          {newPresurvey?<img src="/images/new.png" className="new" alt="new" />:null}
        </li>
        :null}
	    </ul>
      {useChat === true?
      <ul className="chat-body modify-scroll" id="chatbody" ref={scrollRef} style={{'display':'none'}}>
        
      </ul>
      :null}
      {useQA === true?
      <ul className="chat-body qa modify-scroll" id="qabody" ref={scrollRef} style={{'display':'none'}}>
        {isNotNull(presurveys) &&
        presurveys.map((c, index) => {
          return (
          <li key={index}>
            <div className="chat-box qa">
              <div className="name qa">Question</div>
              <div className="like qa"><span onClick={()=>putVote(c.presurveyidx)}><ThumbUpIcon className="material-icons"/> {c.totvote}</span></div>
              <span>{c.subject}</span>
            </div>
          </li>     
          )   
        })}
      </ul>
      :null}
      {useChat === true?
      <div className="chat-footer">
        <textarea id="writebox" placeholder={t('chat:chatbox-holder')} style={textboxStyle}/>
        <a href="#!" id="writebutton" className="btn btn-send ml-10" >Send</a>
      </div>
      :null}
      <Script type="text/javascript" src="/js/chat.js" />
    </div>
  );
  const mobilejsx = (
    <div className="m-chat-wrap" style={{display:execPollidx === -1?'block':'none'}}>
      <ul className="m-chat-body notice modify-scroll">
        <div className="chat-box admin notice">
          <span>{(isNotNull(info) && isNotNull(info.chatnotice))?info.chatnotice:t('chat:pre-notice')}</span>
        </div>
      </ul>      
      {useChat === true?
      <div className="m-chat-footer">
        <textarea id="writebox" placeholder={t('chat:chatbox-holder')} style={textboxStyle}/>
        <a href="#!" id="writebutton" className="btn m-btn-send" >Send</a>
      </div>
      :null}
      <ul className="chat-branch">
        {useChat === true?
        <li className="ch selected" id="branchChat" data-branch="chat" onClick={changeDiv}>Chatting
          {newChat?<img src="/images/new.png" className="new" alt="new" />:null}
        </li>
        :null}
        {useQA === true?
        <li className="qa" id="branchQA" data-branch="qa" onClick={changeDiv}>Q&amp;A Board
          {newPresurvey?<img src="/images/new.png" className="new" alt="new" />:null}
        </li>
        :null}
	    </ul>
      {useChat === true?
      <ul className="m-chat-body modify-scroll" id="chatbody" ref={scrollRef} style={{'display':'none'}}>
        
      </ul>
      :null}
      {useQA === true?
      <ul className="m-chat-body qa modify-scroll" id="qabody" ref={scrollRef} style={{'display':'none'}}>
        {isNotNull(presurveys) &&
        presurveys.map((c, index) => {
          return (
          <li key={index}>
            <div className="chat-box qa">
              <div className="name qa">Question</div>
              <div className="like qa"><span onClick={()=>putVote(c.presurveyidx)}><ThumbUpIcon className="material-icons"/> {c.totvote}</span></div>
              <span>{c.subject}</span>
            </div>
          </li>     
          )   
        })}
      </ul>
      :null}
      <Script type="text/javascript" src="/js/chat.js" />
    </div>
  );  
  return isMobile ? mobilejsx : pcjsx;
}

export default LiveChatDiv;