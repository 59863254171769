import { call, put, takeEvery } from 'redux-saga/effects';
import * as firstCallAPI from '../api/firstcall';
import {
  handleAsyncActions, reducerUtils
} from '../lib/asyncUtils';

/* 액션 타입 */

// 투표하기
const POST_FIRSTCALL = 'POST_FIRSTCALL';
const POST_FIRSTCALL_SUCCESS = 'POST_FIRSTCALL_SUCCESS';
const POST_FIRSTCALL_ERROR = 'POST_FIRSTCALL_ERROR';


export const postFirstCall = (data) => ({ type: POST_FIRSTCALL, param:{data} })

// 투표질문등록 사가~
function* postFirstCallSaga(action) {
  const param = action.param;
  try {
    const Poll = yield call(firstCallAPI.postFirstCall, param); // API 함수에 넣어주고 싶은 인자는 call 함수의 두번째 인자부터 순서대로 넣어주면 됩니다.
    yield put({
      type: POST_FIRSTCALL_SUCCESS,
      payload: Poll
    });
  } catch (e) {
    yield put({
      type: POST_FIRSTCALL_ERROR,
      error: true,
      payload: e
    });
  }
}

// 사가들을 합치기
export function* firstCallSaga() {
  yield takeEvery(POST_FIRSTCALL, postFirstCallSaga);
}

// initialState 쪽도 반복되는 코드를 initial() 함수를 사용해서 리팩토링 했습니다.
const initialState = {
  firstcall: reducerUtils.initial()
};

export default function firstcall(state = initialState, action) {
  switch (action.type) {
    case POST_FIRSTCALL:
    case POST_FIRSTCALL_SUCCESS:
    case POST_FIRSTCALL_ERROR:
      return handleAsyncActions(POST_FIRSTCALL, 'firstcall', true)(state, action);
    default:
      return state;
  }
}