import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { isNotNull, isNull, printValidate } from '../lib/wbUtils';
import { postPoll } from '../modules/poll';

function LivePollDiv({info, webinaridx, pollidx, pushedpoll, handleExecuteLivePoll, isMobile}) {

  const dispatch = useDispatch();
  const { t }  = useTranslation([]);

  const { data } = useSelector(state => ({
    data:state.modPoll.polls.data
  }));

  const [poll, setPoll] = useState({pollidx:-1, ques:'', totvote:0, timelimit:0, polltp:'010', procsts:'010', pollitems:[]});
  const [clearTimer,setClearTimer] = useState(false);
  const [limitTime, setLimitTime] = useState(0);
  const [limitTimeStr, setLimitTimeStr] = useState();

  // input validation 참고 https://react-hook-form.com/kr/
  const { register, handleSubmit, getValues, formState: { errors } } = useForm({
    mode: "onChange",
    defaultValues: {
      pollitemidx: []
    }
  });

  const handleVote = (data) => {
    console.log('data',data);
    data.pollidx = pollidx;
    data.webinaridx = webinaridx;
    data.polltp = poll.polltp;
    dispatch(postPoll(data));
    clearCountdown();
  }

  const closePoll = () => {
    clearCountdown();
    handleExecuteLivePoll();
  }

  const clearCountdown = () => {
    setClearTimer(true);
    clearInterval(countdown);
    setTimeout(()=>{setLimitTimeStr('')},1000);
  }

  let countdown;
  /**
   * 자동종료 타이머 동작 필요시.
   */
  useEffect(() => {
    if (clearTimer === true) return;
    if (poll.timelimit === 0) return;
    // 타이머... 이거 필요할까...
    countdown = setInterval(() => {
      if (limitTime <= 0) {
        clearInterval(countdown);
        setClearTimer(true);
        // 투표창 닫음
        handleExecuteLivePoll();
      }
      if (clearTimer === false) {
        //console.log('@@@@@@@@@@',(limitTime - 1));
        setLimitTime(limitTime => limitTime - 1);
        setLimitTimeStr(t("poll:remaining-time", {"limitTime": limitTime - 1}));
        //setLimitTimeStr(`투표종료까지 남은시간 : ${limitTime - 1} 초`);
      }
    }, 1000);
    return () => clearInterval(countdown);
  }, [limitTime]);

  useEffect(() => {
    console.log('@@@LivePollDiv.useEffect1',pushedpoll,isMobile);
    if (!isNull(data)) {
      console.log('data',data);
    }
    if (pollidx > -1) {
      //dispatch(getPolls(webinaridx));
      setPoll(pushedpoll);
      setLimitTime(pushedpoll.timelimit);
    }
  },[dispatch])

  useEffect(() => {
    // 투표를 하고 나면 호출되는 부분이다.
    if (!isNull(data)) {
      if (data.result_cd !== 1) {
        // 에러이거나 이미 투표한 경우
        if (data.result_cd === 222) {
          toast.info(`${data.result_msg}`);
        } else {
          toast.error(`Error : ${data.result_msg}`);
        }
        // 이게 되네? 안되는줄 알았는데... store 의 값까지 변경된다..!
        data.result_cd = 1;
      }
      if (!isNull(data.map) && !isNull(data.map.polls)) {
        for (const p of data.map.polls) {
          if (p.pollidx === parseInt(pollidx)) {
            setPoll(p);
          }
        }
        // poll 이 하나 이상일 경우 data를 비워주는 것이 필요하다.
        // poll 을 디비에서 가져오는 것이 아니라 push 로 받아오기 때문에 미리 투표여부를 알 수가 없다.
        data.map = null;
      }
      if (poll.resulttp === '010') {
        // 결과 비공개이면 투표후 투표창 바로 닫음
        handleExecuteLivePoll()
      }
    }
  },[data])

  const atLeastOne = () => {
    //console.log(getValues("pollitems").length);
    //return getValues("pollitems").length ? true : "Please tell me if this is too hard.";
  }

  const pcjsx = (
    <div className="chat-wrap">
    <form onSubmit={handleSubmit(handleVote)}>
      <div className="chat-title">
        {(isNotNull(info) && isNotNull(info.webinarchattitle))?
        <img src={info.webinarchattitle}/>
        :
        <>
        <p>{!isNull(info) ? info.webinarnm:null}</p>
        <p>{!isNull(info) ? info.dscr:null}</p>
        </>
        }
      </div>
      <div className="poll-wrap">
        <h3>{poll.ques}</h3>
        {poll.voteyn !== 'Y' ?
        <ul className="poll-list">
        {
        !isNull(poll.pollitems) &&
        poll.pollitems.map((c, index) => {
          if (poll.polltp === '020') {
            return (
              <li key={index}>
                <input type="checkbox" name="pollitemidx" value={c.pollitemidx} {...register('pollitemidx',{
                  validate: atLeastOne
                })}/>
                  {c.item}
              </li>
            )
          } else {
            return (
              <li key={index}>
                <input type="radio" name="pollitemidx" value={c.pollitemidx}  {...register('pollitemidx',{ required: true})}/>
                  {c.item}
              </li>
            )
          }
        })}
          <li>{printValidate(errors.pollitemidx, 'Item')}</li>
        </ul>
        :
        <ul className="poll-result">
          {
          !isNull(poll.pollitems) ?
          poll.pollitems.map((c, index) => {
            const percentage = c.votecnt!==0?Math.ceil((c.votecnt/poll.totvote) * 100 * 0.9 ):1;
            return (
              <li key={index}>
                <p>{c.item}</p>
                <div className="poll-bar">
                  <p className="pink" style={{width:`${percentage}%`}}><span>{c.votecnt}명</span></p>
                </div>
              </li>
            )
          }):null}
          
        </ul>
        }
        <div className="timer-text">
          {limitTimeStr}
        </div>
        <div className="btn-wrap" style={{marginBottom:'20px'}}>
          {poll.voteyn !== 'Y' ?
          <button type="submit" className="btn btn-vote">Vote</button>
          :null}
          <button className="btn btn-close" onClick={closePoll}>Close</button>
        </div>
      </div>
      </form>
    </div>
  );
  const mobilejsx = (
    <div className="m-chat-wrap">
    <form id="livePollForm" name="livePollForm" onSubmit={handleSubmit(handleVote)}>
      <div className="m-poll-wrap">
        <h3>{poll.ques}</h3>
        {poll.voteyn !== 'Y' ?
        <ul className="m-poll-list">
        {!isNull(poll.pollitems) &&
        poll.pollitems.map((c, index) => {
          if (poll.polltp === '020') {
            return (
              <li key={index}>
                <input type="checkbox" name="pollitemidx" value={c.pollitemidx} {...register('pollitemidx',{
                  validate: atLeastOne
                })}/>
                  {c.item}
              </li>
            )
          } else {
            return (
              <li key={index}>
                <input type="radio" name="pollitemidx" value={c.pollitemidx}  {...register('pollitemidx',{ required: true})}/>
                  {c.item}
              </li>
            )
          }          
        })}
          <li>{printValidate(errors.pollitemidx, 'Item')}</li>
        </ul>
        :
        <ul className="m-poll-result">
          {!isNull(poll.pollitems) &&
          poll.pollitems.map((c, index) => {
            const percentage = c.votecnt!==0?Math.ceil((c.votecnt/poll.totvote) * 100 * 0.9 ):1;
            return (
              <li key={index}>
                <p>{c.item}</p>
                <div className="poll-bar">
                  <p className="pink" style={{width:`${percentage}%`}}><span>{c.votecnt}명</span></p>
                </div>
              </li>
            )
          })}
          
        </ul>
        }
        <div className="timer-text">
          {limitTimeStr}
        </div>
        <div className="m-btn-wrap">
          {poll.voteyn !== 'Y' ?
          <button type="submit" className="btn m-btn-vote">Vote</button>
          :null}
          <button className="btn m-btn-close" onClick={closePoll}>Close</button>
        </div>
      </div>
      </form>
    </div>
  );
  if (isMobile) return mobilejsx;
  else return pcjsx;
}

export default LivePollDiv;