import { call, put, takeEvery } from 'redux-saga/effects';
import * as fileAPI from '../api/file'; // api/ptcpnts 안의 함수 모두 불러오기
import {
  handleAsyncActions, reducerUtils
} from '../lib/asyncUtils';

// 다운로드
const DOWNLOAD_FILE = 'DOWNLOAD_PTCPNTS_FILE';                    // 요청 시작
const DOWNLOAD_FILE_SUCCESS = 'DOWNLOAD_FILE_SUCCESS';    // 요청 성공
const DOWNLOAD_FILE_ERROR = 'DOWNLOAD_FILE_ERROR';        // 요청 실패

// 다운로드 초기화 (도 해야하나..)
const CLEAR_DOWNLOAD_FILE = 'CLEAR_DOWNLOAD_FILE';

export const downloadFile = (fileidx) => ({ type: DOWNLOAD_FILE, param: { fileidx } });
export const clearDownlaodFile = () => ({ type: CLEAR_DOWNLOAD_FILE });

// 파일 다운로드
function* downloadFileSaga(action) {
  console.log('@@downloadFileSaga');
  const param = action.param;
  try {
    const file = yield call(fileAPI.downloadFile, param); // API 함수에 넣어주고 싶은 인자는 call 함수의 두번째 인자부터 순서대로 넣어주면 됩니다.
    yield put({
      type: DOWNLOAD_FILE_SUCCESS,
      payload: file
    });
  } catch (e) {
    yield put({
      type: DOWNLOAD_FILE_ERROR,
      error: true,
      payload: e
    });
  }
}

// 사가들을 합치기
export function* fileSaga() {
  yield takeEvery(DOWNLOAD_FILE, downloadFileSaga);
}

// initialState 쪽도 반복되는 코드를 initial() 함수를 사용해서 리팩토링 했습니다.
const initialState = {
  filedownloaddone: reducerUtils.initial()
};

export default function file(state = initialState, action) {
  switch (action.type) {
    case DOWNLOAD_FILE:
    case DOWNLOAD_FILE_SUCCESS:
    case DOWNLOAD_FILE_ERROR:
      return handleAsyncActions(DOWNLOAD_FILE, 'filedownloaddone', true)(state, action);
    case CLEAR_DOWNLOAD_FILE:
      return { ...state, ptcpnts: { ...state.filedownloaddone }, filedownloaddone: reducerUtils.initial() };
    default:
      return state;
  }
}