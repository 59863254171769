import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import PasswordResetPopup from '../components/PasswordResetPopup';
import { isNotNull, isNull, printValidate } from '../lib/wbUtils';
import { getWebinar, putLogin, saveLogin } from '../modules/login';

function LoginContainer({webinaridx, history, isMobile, location, isPoll, isQr})  {

  const { t }  = useTranslation(['login']);

  const params = new URLSearchParams(location.search);
  const istest = params.get('istest'); //

  const { data, loading, error, webinar } = useSelector(state => ({
    data:state.modLogin.logininfo.data
    ,loading:state.modLogin.logininfo.loading
    ,error:state.modLogin.logininfo.error
    ,webinar:state.modLogin.webinar.data
  }))

  const dispatch = useDispatch();
  const defLogin = {'loginid':'','loginpwd':''};
  const [login, setLogin] = useState(defLogin);
  const [saveid, setSaveid] = useState(false);
  const [info, setInfo] = useState({'webinarnm':'','webinarlogo':'/images/img_logo01.png','boothyn':'N','loginuri':null,'logintp':'010','procsts':'010','regyn':'Y'});
  const [procmsg, setProcmsg] = useState();
  const [resetDisplay, setResetDisplay] = useState({
    'display':'block'
  })
  const [resetPopup, setResetPopup] = useState(false);
  const [btncss, setBtncss] = useState({'loginLoginText':null,'loginLoginStyle':null,'loginRegisterText':null,'loginRegisterStyle':null,'loginRefreshText':null,'loginRefreshStyle':null});

  // input validation 참고 https://react-hook-form.com/kr/
  const { register, handleSubmit, formState: { errors } } = useForm();

  const handleUsrChange = (event) => {
    setLogin({...login,'loginid':event.target.value})
  }

  const handlePwdChange = (event) => {
    setLogin({...login,'loginpwd':event.target.value})
  }

  // 로그인처리
  const handleLoginProc = (fdata) => {
    console.log('handleLoginProc',fdata);
    handleSaveId(saveid);
    fdata.webinaridx = webinaridx;
    fdata.logintp = info.logintp;
    dispatch(putLogin(fdata));
  }

  const handleCheckSave = () => {
    if (saveid === false) {
      // 저장
      handleSaveId(true);
    } else {
      // 저장된 내용 삭제
      handleSaveId(false);
    }
    setSaveid(!saveid);
  }

  // 아이디 저장.
  const handleSaveId = (b) => {
    if (b === true) {
      // 저장
      window.localStorage.setItem('savedcmsid', login.loginid)
    } else {
      // 저장된 내용 삭제
      window.localStorage.setItem('savedcmsid', '')
    }
  }

  const handleClickSubmit = () => {
    document.forms["regform"].submit(); return false;
  }

  const handlePreregister = (e) => {
    e.preventDefault();
    let today = new Date();
    let entm = new Date(info.entm*1000);
    if(today>entm){
      toast.error("Webinar has been closed.")
    }else{
      history.push('/pre-registration?webinaridx='+webinaridx);
    }
    // 
  }

  useEffect(() => {
    console.log('webinaridx:',webinaridx, webinar);
    if (isNull(webinar)) {
      // 기본정보와 함께 라이브서버 정보를 불러오는 것으로 변경함. 라이브서버 정보는 vod 일 경우 vod목록 정보이며, 
      // 한개이면 vod 페이지로, 여러개이면 vod 목록 페이지로 이동하도록 함.
      setTimeout(function(){dispatch(getWebinar(webinaridx,0b00000011));},500);
    } else if (isNotNull(webinar) && isNotNull(webinar.map)){
      if (isNull(webinar.map.info)) {
        // 정보를 가져왔는데 웨비나 정보가 존재하지 않을때.. (삭제되는 등..)
        history.push('/notfound/Webinar');
      } else {
        setInfo(webinar.map.info);
        if (webinar.map.info.btncss !== null) {
          try {
            setBtncss(JSON.parse(webinar.map.info.btncss));
          } catch (e) {
            console.warn(e.message);
          }
        }
        const now = Math.floor(new Date().getTime() /1000);
        //console.log('@@@',now,webinar.map.info.sttm,webinar.map.info.entm)
        if (istest !== 'Y') {
          if (webinar.map.info.procsts === '020' || now < webinar.map.info.sttm) {
            setProcmsg('The webinar is not open yet.')
          } else if (webinar.map.info.procsts === '030' || now > webinar.map.info.entm) {
            setProcmsg('The webinar is closed.')
            setResetDisplay({
              'display':'none'
            })
          }
        }
        if (webinar.map.info.boothyn === 'Y' && isNotNull(webinar.map.info.loginuri)
          && webinar.map.info.loginuri !== '') {
          // 부스에 연결되어 있고 로그인uri 가 있으면 이동시킨다.
          window.document.location.href=webinar.map.info.loginuri;
        }
      }
    }
  },[dispatch,webinar])

  useEffect(() => {
    console.log('useEffect',data);

    //TODO
    //window.document.location.href="https://lgaesthetics.com/yvoire/intro/login";

    const savedid = window.localStorage.getItem('savedcmsid');
    if (savedid !== null && savedid !== '') {
      setSaveid(true)
      setLogin({...login,'loginid':savedid})
    } else {
      setSaveid(false)
      setLogin(defLogin)
    }
  
    if (error) {
      toast.error(`Login fail : ${error.message}`);
    }

    // ...
    //console.log('getDerivedStateFromProps',nextProps,prevState);
    //console.log('logininfo1',prevState.modLogin.logininfo);
    //console.log('logininfo2',nextProps.modLogin.logininfo);
    if (data !== null && data !== undefined ) {
      if (data.result_cd===1) {
        console.log('login success!!',data.map);
        const {ptcpntemail, ptcpntidx, ptcpntnm, ptcpnthp, company, dept, usrtoken} = data.map;
        window.sessionStorage.setItem('ptcpntemail', ptcpntemail);
        window.sessionStorage.setItem('ptcpntidx', ptcpntidx);
        window.sessionStorage.setItem('ptcpntnm', ptcpntnm);
        window.sessionStorage.setItem('ptcpnthp', ptcpnthp);
        window.sessionStorage.setItem('usrtoken', usrtoken);
        window.sessionStorage.setItem('company', company);
        window.sessionStorage.setItem('dept', dept);
        // 로그인정보 저장 및 로그인폼정보 초기화
        dispatch(saveLogin(ptcpntemail,ptcpntidx,ptcpntnm,usrtoken))
        setLogin(defLogin)
        // 어디론가 이동...
        console.log('move~~~!!', webinar.map.info.webinartp);
        console.log('move~~~!!', webinar.map.info);

        const refferer = window.location.href;
        console.log("URL", refferer);

        // if(refferer.includes("loginpoll")) {
        //   setTimeout(function(){history.push('/pollqr/' + webinaridx)},500);
        //   return
        // };
        
        if(isQr) {
          setTimeout(function(){history.push('/pollqr/' + webinaridx)},500);
          return
        }


        // 로그인 후 이동경로.
        if (webinar.map.info.webinartp === '020') {
          goLinkVod(webinaridx);
        } else if (webinar.map.info.webinartp === '030') {
          // Ebooth (TODO)
        } else {
          if (webinar.map.info.procsts === '020') {
            toast.error('The webinar is not open yet.');
            window.location.href='/notonair/'+webinaridx;
          } else if (webinar.map.info.procsts === '030') {
            toast.error('The webinar is closed.');
            window.location.href='/notonair/'+webinaridx;
          } else if (webinar.map.info.procsts === '040' && isNotNull(webinar.map.info.vodidx)) {
            // 상태가 웨비나종료후 vod 연결링크일때.
            setTimeout(function(){history.push('/webinars/vodlist/'+webinar.map.info.vodidx)},500);
          } else {
            // Webinar
            if (webinar.map.info.gwpageyn === 'Y') {
              // gateway page yn
              setTimeout(function(){history.push('/webinars/gateway/'+webinaridx)},500);
            } else {
              setTimeout(function(){history.push('/webinars/live/'+webinaridx)},500);
            }
          }
        }
      } else {
        toast.error(`Login fail : ${data.result_msg}`);
        console.log('login fail!!', data.result_msg);
      }
    }
  },[dispatch, data, error])

  // 연결된 vod 로 이동.
  const goLinkVod = (widx) => {
    // VOD
    const lmaps = webinar.map.liveservermaps;
    if (isNotNull(lmaps) && lmaps.length > 1) {
      // vod 가 2개이상이면
      setTimeout(function(){history.push('/webinars/vodlist/'+widx)},500);
      // 1개일경우의 이동은 WebinarVodList 에서 하는것으로 함. 
      // 왜냐하면.. webinar 가 호출되었다가 연결된 vod list 로 넘어오는 경우 vod 목록을 모르기 때문.
    } else if (isNotNull(lmaps) && lmaps.length === 1) {
      // vod 가 1개이면
      setTimeout(function(){history.push('/webinars/vod/'+widx+'/'+lmaps[0].liveservermapidx)},800);
    } else {
      // 정보가 없으면
      toast.error(`Vod info is not exist`);
    }
  }

  const handleRefresh = () => {
    window.location.reload(true);
  }

  const togglePasswordResetPopup = (res) => {
    if(res===true) toast.success("You'll receive an email with instructions on how to reset your password")
    setResetPopup(!resetPopup);
  }

  // const LoginButton = styled.button`
  //     padding: unset;
  //     width: 130px;
  //     height: 30px;
  //     font-size: 12px;
  //     color: #fff;
  //     background: #5E5E5E;      
  //     color:${webinar.map.info.webinartp==='010'?'red':'green'};
  //     background: ${webinar.map.info.webinartp};
  // `;

  const loginBtnStyleTxt = '{"color":"red"}';
  const loginBtnStyle = JSON.parse(loginBtnStyleTxt);
if(!isPoll) {
  return (
    <>
    <div className="login-wrap">
      <div className="login-box">
      <form onSubmit={handleSubmit(handleLoginProc)}>
        <div className="content">
          <div className="login-header">
            <img src={isNull(info.webinarlogo)?'/images/img_logo01.png':info.webinarlogo} alt="logo"/>
            {info.loginthtml===null||info.loginthtml===undefined?<><p>{info.webinarnm}</p></>:''}
            <div className="login-dscr" dangerouslySetInnerHTML={{__html:info.loginthtml}}></div>
          </div>
          {info.logintp === '020' && isNull(procmsg)?
          <ul className="input-area">
            <li>
              <p>{t('login:name')}</p>
              <input type="text" name="loginid" placeholder={t('login:name')} className="type05"
                {...register('loginid',{ required: true,  maxLength: 255 })}/> 
              {printValidate(errors.loginid, t('login:name'))}
            </li>
            <li>
              <p>{t('login:pwd')}</p>
              <input type="password" name="loginpwd" placeholder={t('login:pwd-pholder')} className="type05"
                {...register('loginpwd',{ required: true,  maxLength: 20})}/>
              {printValidate(errors.loginpwd, t('login:pwd-pholder'))}
            </li>
          </ul>
          :null}
          {info.logintp === '010' && isNull(procmsg)?
          <ul className="input-area">
            <li>
              <p>{t('login:id')}</p>
              <input type="text" name="loginid" placeholder={t('login:id-pholder')} className="type05"
                {...register('loginid',{ required: true,  maxLength: 255
                /*, pattern: /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i*/})}/> 
              {printValidate(errors.loginid, t('login:id'))}
            </li>
            <li>
              <p>{t('login:pwd')}</p>
              <input type="password" name="loginpwd" placeholder={t('login:pwd')} className="type05"
                {...register('loginpwd',{ required: true,  maxLength: 20})}/>
              {printValidate(errors.loginpwd, t('login:pwd'))}
            </li>
          </ul>
          :null}
          { isNotNull(procmsg)?<div className="login-header">{procmsg}</div>:null}
          <div className="btn-wrap">
              {info.regyn === 'Y'?
              <button type="button" className="btn-register" href="#" style={btncss.loginRegisterStyle} onClick={handlePreregister}>{btncss.loginRegisterText!=null?btncss.loginRegisterText:'Register'}</button>
              :null}
              {info.procsts !== '010' && istest !== 'Y'?
              <button type="button" href="#!" className="btn-login" style={btncss.loginRefreshStyle} onClick={handleRefresh}>{btncss.loginRefreshText!=null?btncss.loginRefreshText:'Refresh'}</button>
              :
              <button type="submit" href="#!" className="btn-login" style={btncss.loginLoginStyle}>{btncss.loginLoginText!=null?btncss.loginLoginText:'Log In'}</button>
               }
          </div>
          {info.logintp === '010'?
            <div className="password-reset" style={resetDisplay}> <span onClick={togglePasswordResetPopup}>Forgot your password?</span></div>
            :null}
        </div>
        </form>
      </div>
      {/** beneath */}
      <div className="login-box-under" dangerouslySetInnerHTML={{__html:info.lbeneath}}>
      </div>
    </div>
    {resetPopup?
      <>
      <PasswordResetPopup
        togglePasswordResetPopup={togglePasswordResetPopup}
        webinaridx={webinaridx}
      />
      </>
    :null}
    </>
    );
  } else {
    return <div>ddd</div>
  }
}

export default withRouter(LoginContainer);
