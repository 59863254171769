import { Clear, ErrorOutline } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PollList from '../components/PollList';
import { isNotNull } from '../lib/wbUtils';
import { getPolls } from '../modules/poll';

function PollListPopContainer({webinaridx, toggleShowPollPopup, isMobile}) {

  const dispatch = useDispatch();
  const { data, loading, error } = useSelector(state => state.modPoll.polls);

  useEffect(() => {
    dispatch(getPolls(webinaridx));
  }, [dispatch]);

  console.log("test", data);

  let listArea;
  let totalItems = 0;
  // 로딩중
  if (loading && !data) listArea = <div>loading...</div>
  // 에러
  if (error) listArea = <div>errors!</div>
  // 데이타 불러왔지만 에러일때
  if (isNotNull(data) && data.result_cd === 0) listArea = <div>errors : {data.result_msg}</div>
  // 정상적으로 데이타 호출시
  if (isNotNull(data) && data.result_cd === 1) {
    totalItems = 0;
    if (isNotNull(data.map)) totalItems = data.map.polls.length;
    if (totalItems === 0) {
      listArea = <div className="noData"><ErrorOutline className="material-icons"/> No Polls.</div>
    } else {
      listArea = <PollList polls={data.map.polls}/>
    }
  }


  const pcjsx = (
    <div className="modal" id="poll-modal">
      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <p className="modal-title">Live Poll</p>
            <Clear  className="modal-close material-icons cursorpointer" onClick={toggleShowPollPopup}/>
          </div>
          <div className="modal-body">
            {listArea}
          </div>
        </div>
      </div>
    </div>
  );
  const mobilejsx = (
    <div className="modal" id="m-poll-modal">
      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <p className="modal-title">Live Poll</p>
            <Clear  className="modal-close material-icons cursorpointer" onClick={toggleShowPollPopup}/>
          </div>
          <div className="modal-body">
            {listArea}
          </div>
        </div>
      </div>
    </div>
  );
  if (isMobile) return mobilejsx;
  else return pcjsx;
}

export default PollListPopContainer